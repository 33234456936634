// @ts-ignore
import React, { useCallback, useState, useMemo } from 'react';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { Icon } from '@grafana/ui';
import { RadarChartOptions } from 'types';
import {
  Radar, 
  RadarChart, 
  PolarGrid, 
  PolarAngleAxis, 
  PolarRadiusAxis, 
  ResponsiveContainer,
  Tooltip,
  Legend,
  Label,
  LabelList
} from 'recharts';
import { config } from '@grafana/runtime';
import styled from 'styled-components';
import './css/RadarChartPanel.css';

interface Props extends PanelProps<RadarChartOptions> {}

export const RadarChartPanel: React.FC<Props> = React.memo(({ options, data, width, height, replaceVariables, id }) => {
  const isDark = config.theme.isDark;
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const absoluteThreshold = Number(replaceVariables(options.absoluteThreshold));
  const threshold = Number(replaceVariables(options.metricThreshold)) / 100;

  const auxButtonIcon1 = replaceVariables(options.toolbarAuxButtonIcon1);
  const auxButtonVariable1 = replaceVariables(options.toolbarAuxButtonVariable1);
  const auxButtonValue1 = replaceVariables(options.toolbarAuxButtonValue1);
  const auxButtonIcon2 = replaceVariables(options.toolbarAuxButtonIcon2);
  const auxButtonVariable2 = replaceVariables(options.toolbarAuxButtonVariable2);
  const auxButtonValue2 = replaceVariables(options.toolbarAuxButtonValue2);
  const axisLineWidth = options.axisLineWidth;
  const chartTitle = options.showTitle ? replaceVariables(options.radarTitle) : '';
  const chartSubTitle = options.showTitle ? replaceVariables(options.chartSubTitle) : '';
  const chartIcon = options.showTitle ? replaceVariables(options.chartIcon) : '';
  const chartIconSize = options.titleFontSize * 2 > 64 ? 64 : options.titleFontSize * 2;
  const center_link = replaceVariables(options.drillDownLink);

  const memoizedOptions = useMemo(() => ({
	center_link: replaceVariables(options.drillDownLink),
	auxButtonIcon1: replaceVariables(options.toolbarAuxButtonIcon1),
	auxButtonVariable1: replaceVariables(options.toolbarAuxButtonVariable1),
	auxButtonValue1: replaceVariables(options.toolbarAuxButtonValue1),
	auxButtonIcon2: replaceVariables(options.toolbarAuxButtonIcon2),
	auxButtonVariable2: replaceVariables(options.toolbarAuxButtonVariable2),
	auxButtonValue2: replaceVariables(options.toolbarAuxButtonValue2),
	chartTitle: options.showTitle ? replaceVariables(options.barTitle) : '',
	chartSubTitle: options.showTitle ? replaceVariables(options.chartSubTitle) : '',
	chartIcon: options.showTitle ? replaceVariables(options.chartIcon) : '',
  }), [options, replaceVariables]);

  if (height < 150 || width < 150) {
    return (
	  <div className="radarChartErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="radarChartErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.series[0].length < 1) {
    return (
	  <div className="radarChartErrorContainer" title={error2}>
	    <Icon name={'image-slash'} size="xxl" />
	  </div>
	);
  }

  var legendPosition = options.displayLegends && options.legendPosition === 'right' ? 'right' : 'bottom';
  var legendVisible = options.displayLegends;
  if (width > 325 && height < 215) {
    legendPosition = 'right';
  } else if (width < 325 && height > 215) {
    legendPosition = 'bottom';
  }

  if (
    (legendPosition === 'right' && width < 325) ||
	(legendPosition === 'bottom' && height < 215) ||
	(width < 325 || height < 215)
  ) {
    legendVisible = false;
  }

  var toolBoxWidth = 0;
  if (options.showAuxToolbar && !options.showTitle) {
	toolBoxWidth = 32;
  } else if (options.showTitle && chartTitle !== '' && width >= 250) {
	toolBoxWidth = (width * 0.5 < 125) ? 125 : width * 0.5;
	legendVisible = false;
  }
  if (options.showTitle && chartTitle !== '' && toolBoxWidth > 300) {
    toolBoxWidth = 300;
  }

  const tickFontColor = isDark ? '#D8DFD9' : '#23282E';
  const textColor = isDark ? '#E6E9ED' : '#23282E';
  const iconColor = isDark ? 'white' : 'black';
  const buttonColor = isDark ? 'black' : 'white';
  const buttonBorder = isDark ? '#23282E' : '#E6E9ED';
  const boxBorder = isDark ? '#1B2733' : '#EFF4FA';

  const RadarChartBox = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #333333;
    width: fit-content;
	max-width: 300px;
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 4px 2px 4px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
    z-index: 9999;
  `;
  const RadarChartWarn = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    width: fit-content;
	max-width: 300px;
    padding: 5px;
    background-color: #e45858;
    border: 1px solid red;
    border-radius: 4px;
    box-shadow: 4px 2px 4px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
    z-index: 9999;
  `;
  const RadarChartTooltipCenter = styled.p`
    text-align: center;
  `;

  const RadarChartTooltipText = styled.p`
    margin-top: 0px;
    margin-bottom: 0px;
  `;

  const RadarChartTooltipTitle = styled.b`
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
  `;

  const RadarChartToolboxContainer = styled.div`
    display: inline-flex;
    width: 100%;
  `;

  const ButtonContainer = styled.div`
    position: absolute;
    top: 5px;
    width: 32px;
	border-left: 1px solid ${boxBorder};
  `;

  const ChartContainer = styled.div`
    display: inline-flex;
	position: absolute;
	width: -webkit-fill-available;
    height: -webkit-fill-available;
  `;
	
  const ButtonNormal = styled.button`
    display: inline-flex;
    height: 32px;
    width: 28px;
	margin-left: 2px;
    background-color: transparent;
    opacity: 0.75;
    justify-content: center;
	align-items: center;
    color: ${iconColor};
    border: 1px solid transparent;
    border-radius: 10px;

    &:hover {
      opacity: 1;
	  background-image: linear-gradient(to bottom right, #e5e5e529, #b7b7b76e);
	  border: 1px solid ${buttonBorder};
    }
  `;

  const ButtonText = styled.div`
    width: 28px;
  `;

  var clicEnable = false;
  if (center_link !== null && center_link.length > 0) {
    clicEnable = true;
  }

  var chartRadars: [] = [];

  data.series.forEach((series) => {
    const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
    for (let i = 0; i < timeVals.length; i++) {
	  var chartRadar = {
		id: i,
		name: series.fields.find((field) => field.name === options.categoryField)?.values.get(i),
		value: 0,
		value2: 0,
		value3: 0,
		fullMark: 0,
		label: series.fields.find((field) => field.name === options.labelField)?.values.get(i) || '',
		info: '',
		metric: 0,
		threshold: 0,
		onfire: false,
	  };
	  if (options.valueField !== undefined && options.valueField !== '') {
		const value = series.fields.find((field) => field.name === options.valueField)?.values.get(i);
		chartRadar.value = isNaN(value) ? 0 : Number(value);
	  }
	  if (options.value2Field !== undefined && options.value2Field !== '') {
		const value2 = series.fields.find((field) => field.name === options.value2Field)?.values.get(i);
		chartRadar.value2 = isNaN(value2) ? 0 : Number(value2);
	  }
	  if (options.value3Field !== undefined && options.value3Field !== '') {
		const value3 = series.fields.find((field) => field.name === options.value3Field)?.values.get(i);
		chartRadar.value3 = isNaN(value3) ? 0 : Number(value3);
	  }
	  const segmentLabel = series.fields.find((field) => field.name === options.labelField)?.values.get(i)
	  if (segmentLabel !== undefined && segmentLabel !== '') {
	    chartRadar.label = segmentLabel;
	  }
	  if (options.useMetricField) {
		if (options.metricField !== undefined && options.metricField !== '') {
		  const metric = series.fields.find((field) => field.name === options.metricField)?.values.get(i);
		  chartRadar.metric = isNaN(metric) ? 0 : Number(metric);
		}
		if (options.metricThresholdField !== undefined && options.metricThresholdField !== '') {
		  const relativeThreshold = series.fields.find((field) => field.name === options.metricThresholdField)?.values.get(i);
		  chartRadar.threshold = isNaN(relativeThreshold) ? 1 : Number(relativeThreshold) / 100;
		}
	  }
	  if (chartRadar.metric > 0 && chartRadar.threshold > 0) {
		if (
		  chartRadar.value > chartRadar.metric * (1 + chartRadar.threshold) ||
		  chartRadar.value2 > chartRadar.metric * (1 + chartRadar.threshold) ||
		  chartRadar.value3 > chartRadar.metric * (1 + chartRadar.threshold)
		) {
		  chartRadar.onfire = true;
		}
	  }
	  if (
		absoluteThreshold > 0 &&
		(chartRadar.value > absoluteThreshold ||
		  chartRadar.value2 > absoluteThreshold ||
		  chartRadar.value3 > absoluteThreshold)
	  ) {
	    chartRadar.onfire = true;
	  }
	  if (options.invertThreshold) {
		chartRadar.onfire = !chartRadar.onfire;
	  }
	  if (options.useInfoField && options.infoField !== undefined && options.infoField !== '') {
		chartRadar.info = series.fields.find((field) => field.name === options.infoField)?.values.get(i);
	  }
	  chartRadars.push(chartRadar);
	}
  });

  var minValue = null;
  var maxValue = 0;
  var warnIndex = null;
  chartRadars.forEach(chartRadar => {
	minValue = Math.min(chartRadar.value, chartRadar.value2, chartRadar.value3, minValue);
	maxValue = Math.max(chartRadar.value, chartRadar.value2, chartRadar.value3, maxValue);
	if (chartRadar.onfire && warnIndex === null) {
	  warnIndex = chartRadar.id;
	}
  });
  chartRadars.forEach(chartRadar => {
	chartRadar.fullMark = maxValue;
  });
  minValue = options.useMinMetricValue ? minValue : 0;

  const DataFormater = (value: any) => {
	if (isNaN(value)) {
	  return String(value) + ' ';
	} else if (value >= 1000000000) {
	  return String(Math.round(value / 1000000000)) + ' G';
	} else if (value >= 1000000) {
	  return String(Math.round(value / 1000000)) + ' M';
	} else if (value >= 1000) {
	  return String(Math.round(value / 1000)) + ' K';
	} else {
	  return String(Math.round(value * 100) / 100) + ' ';
	}
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
	if (active && payload && payload.length > 0) {
	  if (payload[0].payload.onfire) {
		return (
		  <RadarChartWarn>
			<span>
			  <RadarChartTooltipCenter>
				<i className="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>
				  <RadarChartTooltipTitle>{'Alarma'}</RadarChartTooltipTitle>
			  </RadarChartTooltipCenter>
			</span>
			<>
			  <RadarChartTooltipText>
			    <b>
				  {options.nameField + options.resultSeparator + ' '}
				  {DataFormater(payload[0].payload.value) + options.metricUnit}
				</b>
			  </RadarChartTooltipText>
			  {options.value2Field !== undefined && options.value2Field !== '' && (
			    <RadarChartTooltipText>
				  <b>
				    {options.name2Field + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.value2) + options.metricUnit}
				  </b>
				</RadarChartTooltipText>
			  )}
			  {options.value3Field !== undefined && options.value3Field !== '' && (
			    <RadarChartTooltipText>
				  <b>
				    {options.name3Field + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.value3) + options.metricUnit}
				  </b>
				</RadarChartTooltipText>
			  )}
			  {options.showMetricOnTooltip && (			
			    <RadarChartTooltipText>
				  <b>
				    {options.metricTitle + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.metric) + options.metricUnit}
				  </b>
			    </RadarChartTooltipText>
			  )}
			</>
			{options.useInfoField && (
			  <span>
				{options.infoTitle + options.resultSeparator + ' '}
				<b>{payload[0].payload.info}</b>
			  </span>
			)}
			{clicEnable && options.clicTitle !== '' && (
			  <RadarChartTooltipText>
			    {options.clicTitle}
			  </RadarChartTooltipText>
			)}
		  </RadarChartWarn>
		);
	  } else {
		return (
		  <RadarChartBox>
			<>
			  <RadarChartTooltipText>
				<b>
				  {options.nameField + options.resultSeparator + ' '}
				  {DataFormater(payload[0].payload.value) + options.metricUnit}
				</b>
			  </RadarChartTooltipText>
			  {options.value2Field !== undefined && options.value2Field !== '' && (
				<RadarChartTooltipText>
				  <b>
				    {options.name2Field + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.value2) + options.metricUnit}
				  </b>
				</RadarChartTooltipText>
			  )}
			  {options.value3Field !== undefined && options.value3Field !== '' && (
				<RadarChartTooltipText>
				  <b>
				    {options.name3Field + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.value3) + options.metricUnit}
				  </b>
				</RadarChartTooltipText>
			  )}
			  {options.showMetricOnTooltip && (
				<RadarChartTooltipText>
				  <b>
				    {options.metricTitle + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.metric) + options.metricUnit}
				  </b>
			    </RadarChartTooltipText>
			  )}
			</>
			{options.useInfoField && (
			  <span>
				{options.infoTitle + options.resultSeparator + ' '}
				<b>{payload[0].payload.info}</b>
			  </span>
			)}
			{clicEnable && (
			  <RadarChartTooltipText>
			    {options.clicTitle}
			  </RadarChartTooltipText>
			)}
		  </RadarChartBox>
		);
	  }
	} else {
	  return null;
	}
  };

  var chartMargin = { top: 5, right: 5, left: 5, bottom: 5 };

  const auxButton1Enable = (auxButtonIcon1 !== '' && auxButtonVariable1 !== '' && auxButtonValue1 !== '') ? true : false;
  const auxButton2Enable = (auxButtonIcon2 !== '' && auxButtonVariable2 !== '' && auxButtonValue2 !== '') ? true : false;

  const [activeIndex, setActiveIndex] = useState(warnIndex);
  const onRadarEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const renderColorfulLegendText = (value: string, entry: any) => {
	return <span style={{ color: textColor, cursor: 'pointer' }}>{value}</span>;
  }

  let radarHeight = height;
  let radarWidth = width - toolBoxWidth; 
  if (!options.showTitle && legendVisible) {
	radarWidth = Math.min(height, width);
	radarHeight = radarWidth;
  }

  const outerRadius = radarWidth / 2 * (options.outerRadius / 100);
  const innerRadius = outerRadius * (options.innerRadius / 100);

  return (
	<RadarChartToolboxContainer id={id}>
	  {options.showAuxToolbar && (auxButton1Enable || auxButton2Enable) && !options.showTitle && (
		<ButtonContainer>
		  {auxButton1Enable && (
			<ButtonNormal
			  title={options.toolbarAuxButtonTitle1}
			   onClick={() => {
				handleAuxClick(auxButtonVariable1, auxButtonValue1);
			  }}
			>
			  <ButtonText><Icon name={auxButtonIcon1} size="lg" /></ButtonText>
			</ButtonNormal>
		  )}
		  {auxButton2Enable && (
			<ButtonNormal
			  title={options.toolbarAuxButtonTitle2}
			  onClick={() => {
				handleAuxClick(auxButtonVariable2, auxButtonValue2);
			  }}
			>
			  <ButtonText><Icon name={auxButtonIcon2} size="lg" /></ButtonText>
			</ButtonNormal>
		  )}
		</ButtonContainer>
	  )}
	  {options.showTitle && chartTitle !== '' && width >= 250 && (
		<button
          style={{
            fontSize: options.titleFontSize,
			width: toolBoxWidth,
			height: height - 10,
          }}
          className={'radarChart_button'}
		  onClick={() => {
			handleClick(url_link, '_self');
		  }}
          title={chartTitle}
        >
		  <div 
		    className={'radarChartIndicator'}
			style={ width > 480 ? 
			  {
			    width: toolBoxWidth,
			    height: height - 10,
			  } : {
			    height: height - 10,
			  }
			}
		  >
			{chartIcon !== '' && (
			  <div 
				className={'icon_radarChart'}
			    style={{
				  color: options.iconColor,
				  width: chartIconSize + 20,
			    }}
			  >
				<Icon name={chartIcon} size={chartIconSize + 10} />
			  </div>
			)}
			<div className={'radarChart_label'}>
			  <b>{chartTitle}</b>
			  <div
			    className={'radarChart_sublabel'}
			    style={{
				  fontColor: iconColor,
				  fontSize: options.fontSize,
			    }}
			  >
			    {chartSubTitle}
			</div>
		  </div>
		</div>
      </button>
	  )}
	  <ChartContainer style={{ left: toolBoxWidth, bottom: 0 }}>
		<ResponsiveContainer width={width - toolBoxWidth + 15} height={height}>
		  <RadarChart
			width={radarWidth}
			height={radarHeight}
			margin={chartMargin}
			innerRadius={innerRadius}
			outerRadius={outerRadius}
		    data={chartRadars}
			onClick={(e) => {
			  if (clicEnable) {
				handleClick(center_link + e.activeLabel, '_self');
			  }
			}}
		  >
			<PolarGrid />
            <PolarAngleAxis dataKey="label" tick={{ fontSize: options.fontSize, fill: tickFontColor }} />
            <PolarRadiusAxis 
			  domain={options.scaleType !== 'band' ? [minValue, maxValue] :['dataMin', 'dataMax']} 
			  tick={{ fontSize: options.fontSize }}
			  scale={options.scaleType !== 'band' ? options.scaleType : 'auto'}
			/>
			<Radar
			  name={options.nameField}
			  dataKey="value"
			  stroke={getColor(0, isDark)}
			  fill={getColor(0, isDark)}
			  fillOpacity={options.radarOpacity}
			  isAnimationActive={options.isAnimationActive}
			/>
			{options.value2Field !== undefined && options.value2Field !== '' && (
			  <Radar
				name={options.name2Field}
				dataKey="value2"
				stroke={getColor(1, isDark)}
				fill={getColor(1, isDark)}
				fillOpacity={options.radarOpacity}
				isAnimationActive={options.isAnimationActive}
			  />
			)}
			{options.value3Field !== undefined && options.value3Field !== '' && (
			  <Radar
			    name={options.name3Field}
				dataKey="value3"
				stroke={getColor(2, isDark)}
				fill={getColor(2, isDark)}
				fillOpacity={options.radarOpacity}
				isAnimationActive={options.isAnimationActive}
			  />
			)}
			{options.showTooltip && (
			  <Tooltip 
			    content={CustomTooltip}
				cursor={false}
				isAnimationActive={false}
			  />
			)}
			{!options.showTitle && legendVisible && legendPosition === 'right' && (
			  <Legend
				wrapperStyle={{
				  display: 'flex',
				  alignItems: 'center',
				  fontSize: options.legendFontSize,
				  lineHeight: 2,
				  top: '10%',
				  right: 5,
				}}
			    formatter={renderColorfulLegendText}
			    iconSize={options.legendFontSize + 5}
			    iconType={'circle'}
			    onClick={onRadarEnter}
				align={'right'}
				width={150}
				height={'80%'}
				layout={'vertical'}
			  />
		    )}
			{!options.showTitle && legendVisible && legendPosition === 'bottom' && (
			  <Legend
				wrapperStyle={{ fontSize: options.legendFontSize, lineHeight: 2, left: '5%', bottom: 0 }}
			    formatter={renderColorfulLegendText}
			    iconSize={options.legendFontSize + 5}
			    iconType={'circle'}
			    onClick={onRadarEnter}
				width={'90%'}
				verticalAlign={'bottom'}
			  />
		    )}
		  </RadarChart>
		</ResponsiveContainer>
	  </ChartContainer>
	</RadarChartToolboxContainer>
  );
});

function handleClick(url: string, target: string) {
  if (url !== null && url !== '') {
    window.open(url, target);
  }
}

function getColor(color: number, isDark: boolean) {
  const colors_dark = [
    "#71E200",
    "#7100FF",
    "#FFA071"
  ];
  const colors_light = [
    "#45B0E5",
    "#E57A45",
	"#7DB545"
  ];
  let colorNumber = color < 0 ? 0 : color;
  if (colorNumber > colors_dark.length) {
    colorNumber = ((color - 1) % colors_dark.length);
  }
  if (isDark) {
    return colors_dark[colorNumber];
  } else {
	return colors_light[colorNumber];
  }
}