import { css } from '@emotion/css';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PanelProps, NetMonitorTheme2 } from '@grafana/data';
import { Alert, LoadingPlaceholder, useStyles2, withErrorBoundary } from '@grafana/ui';

import { Receiver } from 'app/plugins/datasource/alertmanager/types';
import { contextSrv } from 'app/core/services/context_srv';
import { useCleanup } from 'app/core/hooks/useCleanup';

import { amRouteToFormAmRoute, formAmRouteToAmRoute, stringsToSelectableValues } from 'app/features/alerting/unified/utils/amroutes';
import { useUnifiedAlertingSelector } from 'app/features/alerting/unified/hooks/useUnifiedAlertingSelector';
import { fetchAlertManagerConfigAction, updateAlertManagerConfigAction } from 'app/features/alerting/unified/state/actions';
import { initialAsyncRequestState } from 'app/features/alerting/unified/utils/redux';
import { isVanillaPrometheusAlertManagerDataSource } from 'app/features/alerting/unified/utils/datasource';
import { AmRouteReceiver, FormAmRoute } from './amroutes';
import { AlertGroupPanelOptions } from './types';
import { AlertGroup } from './AlertGroup';

export const AlertGroupsPanel: React.FC<Props> = ({ options, replaceVariables, id, width }) => {
  const styles = useStyles2(getStyles);
  const isAdmin = options.editorCanAdmin && contextSrv.isEditor ? true : contextSrv.isNetMonitorAdmin;
  const pluginTitle = replaceVariables(options.pluginTitle);
  const showTitle = options.showTitle;
  const okMessage = replaceVariables(options.successMessage);

  const alertManager = replaceVariables(options.alertmanager) || 'netmonitor';
  const dispatch = useDispatch();

  const [isRootRouteEditMode, setIsRootRouteEditMode] = useState(false);
  const [alertManagerSourceName, setAlertManagerSourceName] = useState(alertManager);

  const amConfigs = useUnifiedAlertingSelector((state) => state.amConfigs);

  const fetchConfig = useCallback(() => {
    if (alertManagerSourceName) {
      dispatch(fetchAlertManagerConfigAction(alertManagerSourceName));
    }
  }, [alertManagerSourceName, dispatch]);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  const {
    result,
    loading: resultLoading,
    error: resultError,
  } = (alertManagerSourceName && amConfigs[alertManagerSourceName]) || initialAsyncRequestState;

  const config = result?.alertmanager_config;
  const [rootRoute, id2ExistingRoute] = useMemo(() => amRouteToFormAmRoute(config?.route), [config?.route]);

  const receivers = stringsToSelectableValues(
    (config?.receivers ?? []).map((receiver: Receiver) => receiver.name)
  ) as AmRouteReceiver[];

  const isProvisioned = useMemo(() => Boolean(config?.route?.provenance), [config?.route]);

  const enterRootRouteEditMode = () => {
    setIsRootRouteEditMode(true);
  };

  const exitRootRouteEditMode = () => {
    setIsRootRouteEditMode(false);
  };

  useCleanup((state) => state.unifiedAlerting.saveAMConfig);
  const handleSave = (data: Partial<FormAmRoute>) => {
    if (!result) {
      return;
    }

    const newData = formAmRouteToAmRoute(
      alertManagerSourceName,
      {
        ...rootRoute,
        ...data,
      },
      id2ExistingRoute
    );

    if (isRootRouteEditMode) {
      exitRootRouteEditMode();
    }
    dispatch(
      updateAlertManagerConfigAction({
        newConfig: {
          ...result,
          alertmanager_config: {
            ...result.alertmanager_config,
            route: newData,
          },
        },
        oldConfig: result,
        alertManagerSourceName: alertManagerSourceName!,
        successMessage: okMessage,
        refetch: true,
      })
    );
  };

  const readOnly = alertManagerSourceName
    ? isVanillaPrometheusAlertManagerDataSource(alertManagerSourceName) || isProvisioned
    : true;

  if (!alertManagerSourceName) {
    return <div>Error al cargar complemento</div>;
  }

  return (
    <div className={styles.section} key={String(id)}>
      {resultError && !resultLoading && (
        <Alert severity="error" title="Error al cargar datos desde el gestor de alertas">
          {resultError.message || 'Error desconocido'}
        </Alert>
      )}
      {resultLoading && !config && <LoadingPlaceholder text="Cargando..." />}
      {!resultLoading && config && (
        <AlertGroup
          id={String(id)}
          showTitle={showTitle}
          pluginTitle={pluginTitle}
          isAdmin={isAdmin}
          alertManagerSourceName={alertManagerSourceName}
          onChange={handleSave}
          readOnly={readOnly}
          onRootRouteEdit={enterRootRouteEditMode}
          receivers={receivers}
          routes={rootRoute}
		  width={width}
        />
      )}
    </div>
  );
};

const getStyles = (theme: NetMonitorTheme2) => ({
  section: css`
    margin: ${theme.spacing(2)};
  `,
});
