import React, { PureComponent, useEffect } from 'react';
import { PanelController } from '../PanelController';
import { Icon } from '@grafana/ui';
import axios from 'axios';

import CanvasDrawer from '../canvas/graph_canvas';
import cytoscape, { EdgeCollection, EdgeSingular, NodeSingular } from 'cytoscape';
import L from 'leaflet';
import leaflet from 'cytoscape-leaf';

import cyCanvas from 'cytoscape-canvas';
import dagre from 'cytoscape-dagre';
import avsdf from 'cytoscape-avsdf';
import cise from 'cytoscape-cise';
import coseBilkent from 'cytoscape-cose-bilkent';
import fcose from 'cytoscape-fcose';
import cola from 'cytoscape-cola';
import breadthfirst from 'cytoscape-breadthfirst';
import qtip from 'cytoscape-qtip';

import expandCollapse from 'cytoscape-expand-collapse';
import contextMenus from 'cytoscape-context-menus';

import infinite from '../infinite';
import layoutOptions from '../layout_options';
import layoutOptions_dagre from '../layout_options_dagre';
import layoutOptions_avsdf from '../layout_options_avsdf';
import layoutOptions_cola from '../layout_options_cola';
import layoutOptions_cise from '../layout_options_cise';
import layoutOptions_fcose from '../layout_options_fcose';
import { Statistics } from '../statistics/Statistics';
import _ from 'lodash';

import {
  TableContent,
  TableHeader,
  Table2Content,
  Table2Header,
  Table3Content,
  Table3Header,
  IntGraphMetrics,
  IntGraph,
  GraphDataType,
  PanelSettings,
  IntSelectionStatistics,
} from '../../types';
import { TemplateSrv, locationService, getTemplateSrv, config, SystemJS } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';

import 'cytoscape-context-menus/cytoscape-context-menus.css';
import './ServiceDependencyGraph.css';
import '../../css/netmonitor-topology-map-qtip.css';
import '../../css/netmonitor-topology-map-cytoscape.css';
import '../../css/leaflet.css';
import '../../css/cytoscape-leaf.css';

interface PanelState {
  controller: PanelController;
  cy?: cytoscape.Core | undefined;
  leaf?: cytoscape.Leaflet | undefined;
  graphCanvas?: CanvasDrawer | undefined;
  showStatistics: boolean;
  showEdgeStatistics: boolean;
  data: IntGraph;
  options: PanelSettings;
  isLock: boolean;
  isCollapsed: boolean;
  initResize: boolean;
  isGeoMap: boolean;
  //renderCount: number;
}

cyCanvas(cytoscape);
expandCollapse(cytoscape);
contextMenus(cytoscape);
cytoscape.use(leaflet);
cytoscape.use(dagre);
cytoscape.use(cola);
cytoscape.use(avsdf);
cytoscape.use(cise);
cytoscape.use(coseBilkent);
cytoscape.use(fcose);
cytoscape.use(qtip);
cytoscape.warnings(false);

export class ServiceDependencyGraph extends PureComponent<PanelState, PanelState> {
  ref: any;
  mapRef: any;
  selectionId: string;
  sys_header: Table3Header[];
  currentDescription: Table3Content[];
  currentLabel: string;
  currentSite: string;
  currentType: string;
  selectionStatistics: IntSelectionStatistics;
  receiving: TableContent[];
  sending: TableContent[];
  table_header: TableHeader[];
  node_title: string;
  edge_title: string;
  links: Table2Content[];
  linksHeader: Table2Header[];
  resolvedDrillDownLink_asset: string;
  resolvedDrillDownLink_site: string;
  templateSrv: TemplateSrv;
  layout: string;
  toolbar: boolean;
  showStatTables: boolean;

  constructor(props: PanelState) {
    super(props);
    const { initBlock, allCollapsed } = this.getSettings(false);
	const { initialCyZoom, initialX, initialY } = this.getSettings(false);
	const { initialZoom, initialLon, initialLat } = this.getSettings(false);
	const { useZoomVariable, zoomVariable, latVariable, lonVariable } = this.getSettings(false);
	const { zoomCyVariable, xVariable, yVariable } = this.getSettings(false);
	const geoZoomValue = useZoomVariable ? Number(getTemplateSrv().replace(`$${zoomVariable}`)) : initialZoom;
	const cyZoomValue = useZoomVariable ? Number(getTemplateSrv().replace(`$${zoomCyVariable}`)) : initialCyZoom;
	const latValue = this.props.isGeoMap && useZoomVariable ? Number(getTemplateSrv().replace(`$${latVariable}`)) : initialLat;
    const lonValue = this.props.isGeoMap && useZoomVariable ? Number(getTemplateSrv().replace(`$${lonVariable}`)) : initialLon;
	const xValue = !this.props.isGeoMap && useZoomVariable ? Number(getTemplateSrv().replace(`$${xVariable}`)) : initialX;
    const yValue = !this.props.isGeoMap && useZoomVariable ? Number(getTemplateSrv().replace(`$${yVariable}`)) : initialY;

    this.state = {
      ...props,
	  showStatistics: false,
      showEdgeStatistics: false,
      //isLock: this.props.isGeoMap ? true : initBlock,
	  isLock: initBlock,
      isCollapsed: allCollapsed,
	  initResize: this.props.initResize,
	  isGeoMap: this.props.isGeoMap,
	  zoomValue: geoZoomValue,
	  lonValue: lonValue,
	  latValue: latValue,
	  zValue: cyZoomValue,
	  xValue: xValue,
	  yValue: yValue,
    };

    this.selectionId = null;
	this.ref = React.createRef();
	this.mapRef = React.createRef();
    this.templateSrv = getTemplateSrv();
  }

  componentDidMount() {
    const isDark = config.theme.isDark;
	const { layoutFormat } = this.getSettings(false);
	this.layout = layoutFormat === 'preset' ? 'cola' : layoutFormat;
	const { useZoomVariable, zoomVariable, latVariable, lonVariable } = this.getSettings(false);
	const { zoomCyVariable, xVariable, yVariable } = this.getSettings(false);

	const { mapSource } = this.getSettings(false);
	const base = 'https://services.arcgisonline.com/ArcGIS/rest/services/';
    const arcgisUrl = `${base}${mapSource}/MapServer/tile/{z}/{y}/{x}`;
	const attribution = `Tiles © <a href="${base}${mapSource}/MapServer">ArcGIS</a>`;

	const { showToolbar, showStatTables, useTrafficMetric, useMetricOne, useMetricTwo } = this.getSettings(false);
    this.toolbar = showToolbar;
    this.showStatTables = showStatTables;
	var tooltip = document.getElementById('tooltip-edge');
	var tooltipNode = document.getElementById('tooltip-node');

	var cy: any = cytoscape({
      container: this.ref,
      zoom: this.state.cyZoomValue,
      minZoom: 0.25,
      maxZoom: 3,
      wheelSensitivity: 0.025,
      userZoomingEnabled: true,
      elements: this.props.data,
      style: [
        {
		  selector: 'core',
		  style: {
			'active-bg-opacity': 0
		  }
		},
		{
          selector: 'node',
          style: {
            'background-opacity': 0,
            visibility: 'visible',
          },
        },
        {
          selector: 'node.cy-expand-collapse-collapsed-node',
          css: {
            width: '60px',
            height: '60px',
            'border-width': '1px',
            'border-color': '#E6E9ED',
            'background-color': 'white',
          },
        },
        {
          selector: '$node > node',
          css: {
            'border-width': '1px',
            'border-color': '#E6E9ED',
            'background-color': 'white',
            color: '#161F29',
            shape: 'rectangle',
            'padding-top': '14px',
            'padding-left': '14px',
            'padding-bottom': '14px',
            'padding-right': '14px',
          },
        },
        {
          selector: ':parent',
          css: {
            'text-valign': 'top',
            'text-halign': 'center',
            'border-width': '1px',
            'border-color': '#E6E9ED',
            'background-color': 'white',
          },
        },
        {
          selector: 'edge',
          style: {
            width: 1,
          },
        },
        {
          selector: '.hidden',
          css: {
            display: 'none',
          },
        },
		{
		  selector: '.leaflet-viewport',
		  style: {
			'opacity': 0.333,
			'transition-duration': '0ms',
		  }
		}
      ],
    });
    if (isDark) {
      cy = cytoscape({
        container: this.ref,
        zoom: this.state.cyZoomValue,
        minZoom: 0.25,
        maxZoom: 3,
        wheelSensitivity: 0.025,
        userZoomingEnabled: true,
        elements: this.props.data,
        style: [
          {
		    selector: 'core',
		    style: {
			  'active-bg-opacity': 0
		    }
		  },
          {
            selector: 'node',
            style: {
              'background-opacity': 0,
              visibility: 'visible',
            },
          },
          {
            selector: 'node.cy-expand-collapse-collapsed-node',
            css: {
              width: '60px',
              height: '60px',
              'border-width': '1px',
              'border-color': '#1B2733',
              'background-color': 'black',
            },
          },
          {
            selector: '$node > node',
            css: {
              'border-width': '1px',
              'border-color': '#1B2733',
              'background-color': 'black',
              color: '#F4F9FF',
              shape: 'rectangle',
              'padding-top': '10px',
              'padding-left': '10px',
              'padding-bottom': '10px',
              'padding-right': '10px',
            },
          },
          {
            selector: ':parent',
            css: {
              'text-valign': 'top',
              'text-halign': 'center',
              'border-width': '1px',
              'border-color': '#1B2733',
              'background-color': 'black',
            },
          },
          {
            selector: 'edge',
            style: {
              width: 1,
            },
          },
          {
            selector: '.hidden',
            css: {
              display: 'none',
            },
          },
		  {
		    selector: '.leaflet-viewport',
		    style: {
			  'opacity': 0.333,
			  'transition-duration': '0ms',
		    }
		  }
        ],
      });
    }

    const { sysHeader, showSysInfo, showConnectionStats, nodeHeader } = this.getSettings(false);

	let hasPosition = layoutFormat === 'preset' ? true : false;

    if (this.state.isGeoMap) {
	  cy.nodes().forEach(function(node: any) {
        if (node.data('lat') !== undefined && node.data('lng') !== undefined && node.data('lat') !== null && node.data('lng') !== null) {
		  if (node.data('lat') === null && node.data('lng') === null) {
            node.position().x = this.state.lonValue;
            node.position().y = this.state.latValue;
		  } else {
	        let lat = node.data('lat');
            let lng = node.data('lng');
            node.position().x = lng;
            node.position().y = lat;
		  }
		}
      });
	} else {
      cy.nodes().forEach(function(node: any) {
	    if (hasPosition) {
          if (node.data('x') !== undefined && node.data('y') !== undefined && node.data('x') !== null && node.data('y') !== null) {
		    if (node.data('x') === null && node.data('y') === null) {
              hasPosition = false;
		    } else {
	          let x = node.data('x');
              let y = node.data('y');
              node.position().x = x;
              node.position().y = y;
		    }
		  } else {
		    hasPosition = false;
		  }
	    }
      });
	}

	this.layout = !hasPosition && layoutFormat === 'preset' ? 'cola' : layoutFormat;

    var leaf = null;
    
	if (layoutFormat === 'preset' && this.state.isGeoMap) {
	  this.layout = 'preset';
	  leaf = cy.leaflet({
	    container: this.mapRef
	  });
	}

    const colapseOptions: any = {
      layoutBy: {
        name: this.layout,
        animate: 'end',
        randomize: false,
        fit: false,
      },
      fisheye: false,
      animate: 'end',
      animationDuration: 500,
      ready: function() {},
      undoable: false,
      cueEnabled: true,
      expandCollapseCuePosition: 'top-left',
      expandCollapseCueSize: 12,
      expandCollapseCueSensitivity: 1,
      edgeTypeInfo: 'edgeType',
      groupEdgesOfSameTypeOnCollapse: false,
      allowNestedEdgeCollapse: true,
      zIndex: 900,
    };

	if (this.state.isGeoMap && this.layout === 'preset' && leaf !== null) {
	  leaf.map.removeLayer(leaf.defaultTileLayer);

	  window.map = leaf.map;
	  window.L = L;

	  L.tileLayer(arcgisUrl, {
		attribution: attribution,
		minZoom: 4,
		maxZoom: 20,
		zoomDelta: 0.25,
	  }).addTo(leaf.map);
	  
	  leaf.map.options.zoomDelta = 0.25;

	  leaf.map.setView([this.state.latValue, this.state.lonValue], this.state.zoomValue);

	  leaf.map.on('click', (event: any) => {
		const latlng = event.latlng;
	    const clickLat = latlng.lat;
	    const clickLng = latlng.lng;

	    const nodesNearClick = cy.nodes().filter(node => {
		  const nodeLat = node.data('lat');
		  const nodeLng = node.data('lng');
		  const distance = Math.sqrt((nodeLat - clickLat) ** 2 + (nodeLng - clickLng) ** 2);
		  return distance < 0.01; // 0.01 grados de latitud/longitud del clic
	    });

		if (this.state.isGeoMap && !this.state.initResize) {
		  if (nodesNearClick.length > 0) {
		    this.onSelectionChange(nodesNearClick[0]);
		  } else {
		    this.onSelectionChange(null);
			this.render();
		  }
		}
	  });

	  leaf.map.on('zoomend', () => {
		if (this.state.isGeoMap && useZoomVariable && zoomVariable !== undefined && !this.state.initResize) {
	      const zoom = this.state.leaf.map.getZoom();
	      const center = this.state.leaf.map.getCenter();
		  if (this.state.leaf && this.state.zoomValue !== zoom) {
		    const queryMap = {
              [`var-${zoomVariable}`]: zoom.toFixed(1),
              [`var-${lonVariable}`]: center.lng.toFixed(7),
              [`var-${latVariable}`]: center.lat.toFixed(7),
            };
            try {
			  locationService.partial(queryMap, true);
			} catch (error) {
			  console.log(error);
			}
		    this.setState({
		      zoomValue: zoom,
		      latValue: center.lat,
		      lonValue: center.lng,
		    });
		  }
		  this.state.leaf.map.invalidateSize({pan: true, animate: false, debounceMoveend: true});
	    }
      });

	  leaf.map.on('moveend', () => {
		if (this.state.isGeoMap && !this.state.initResize) {
		  if (useZoomVariable && lonVariable !== undefined && latVariable !== undefined) {
		    const center = this.state.leaf.map.getCenter();
            if (this.state.leaf && (this.state.lonValue !== center.lng || this.state.latValue !== center.lat)) {
		      const queryMap = {
                [`var-${lonVariable}`]: center.lng.toFixed(7),
                [`var-${latVariable}`]: center.lat.toFixed(7),
              };
              try {
			    locationService.partial(queryMap, true);
			  } catch (error) {
			    console.log(error);
			  }
		      this.setState({
		        latValue: center.lat,
		        lonValue: center.lng,
		      });
		    }
          }
		}
      });
	}

    if (useTrafficMetric || useMetricOne || useMetricTwo) {
      cy.on('select', 'edge', () => {
	    tooltipNode.classList.add('graph-tooltip-hidden');
		tooltip.classList.add('graph-tooltip-hidden');
		this.onEdgeSelectionChange();
	  });
      cy.on('unselect', 'edge', () => {
		this.onEdgeSelectionChange();
	  });
    }
    cy.on('select', 'node', (event, node) => {
	  tooltipNode.classList.add('graph-tooltip-hidden');
	  tooltip.classList.add('graph-tooltip-hidden');
	  this.onSelectionChange(null);
	});
    cy.on('unselect', 'node', () => {
	  this.onSelectionChange(null);
	});

    const { drillDownLink_asset, drillDownLink_site } = this.getSettings(true);
	const { healthyColor, warningColor, dangerColor, noDataColor } = this.getSettings(true).style;
	const { healthyThreshold, warningThreshold } = this.getSettings(true).style;
    const asset_link = drillDownLink_asset;
    const site_link = drillDownLink_site;
    cy.on('mouseover', 'node', function(event: any) {
      let node = event.target;
	  var backgroundColor = isDark ? '#141618' : '#F4F9FF';
	  var pos = cy.container().getBoundingClientRect();
	  var x = event.originalEvent.clientX - pos.left;
	  var y = event.originalEvent.clientY - pos.top;

      var item_link = asset_link + node.data('id');
      const isVisible = node.hidden();
      var text_color = 'qtip-bootstrap qtip-normal';
      if (isDark) {
        text_color = 'qtip-bootstrap qtip-dark';
      }
      var isGroup = false;
      if (node.data('type') === 'GROUP_EXP' || node.data('type') === 'GROUP_COL') {
        isGroup = true;
      }
      if (!isGroup && !isVisible) {
		const slaMetric = _.defaultTo(node.data('node_sla'), -1);
		if (slaMetric > 100 || slaMetric < 0) {
		  backgroundColor = isDark ? '#23282E' : '#E6E8ED';
		} else {
		  if (slaMetric >= healthyThreshold) {
			backgroundColor = isDark ? '#E5CD6B' : '#FFC530';
		  } else {
			if (slaMetric >= warningThreshold) {
			  backgroundColor = warningColor;
			} else {
			  backgroundColor = dangerColor;
			}
		  }
		}
        var description_header = '-';
        var time_header = '-';
        var location_header = '-';

        if (sysHeader !== undefined) {
          const header3: any[] = sysHeader.split(',');
          if (header3[0] !== undefined) {
            description_header = header3[0];
          }
          if (header3[1] !== undefined) {
            time_header = header3[1];
          }
          if (header3[2] !== undefined) {
            location_header = header3[2];
          }
        }

        var tooltip = '<b>Activo: </b>' + node.data('id') + '<br/><b>Emplazamiento: </b>' + node.data('site');
        if (showSysInfo) {
		  const nodeDescription = node.data('node_description');
          const sysInfo: any[] = nodeDescription.split(',');
          tooltip =
            '<b>Activo: <a href="' +
            item_link +
            '" target="_blank">' +
            node.data('id') +
            '</a></b> - <b>' +
            time_header +
            ': </b>';
          if (sysInfo[0] !== null && sysInfo[1] !== null && sysInfo[2] !== null) {
            tooltip =
              tooltip +
              sysInfo[1] +
              '<br/><b>' +
              description_header +
              ': </b>' +
              sysInfo[0] +
              ' - <b>' +
              location_header +
              ': </b>' +
              sysInfo[2];
          }
        }
        tooltipNode.style.left = x + 'px';
		tooltipNode.style.top = y + 'px';
		tooltipNode.innerHTML = tooltip;
		tooltipNode.classList.remove('graph-tooltip-hidden');
      }
    });
    cy.on('move grab cxttap click mouseout', 'node', function(event: any) {
	  tooltipNode.classList.add('graph-tooltip-hidden');
    });

    if (showConnectionStats && (useTrafficMetric || useMetricOne || useMetricTwo)) {
      cy.on('mouseover', 'edge', function(event: any) {
        const edge = event.target;
        const isVisible = edge.hidden();

		var pos = cy.container().getBoundingClientRect();
		var x = event.originalEvent.clientX - pos.left;
		var y = event.originalEvent.clientY - pos.top;

        var hasTrafficMetric = true;
        var Traffic_g = 0;
        var Traffic_m = 0;
        const edgeMetrics: IntGraphMetrics = edge.data('metrics');
        var {
          traffic_out,
          traffic_in,
          metricTwo_max,
          metricTwo_actual,
          metricTwo_threshold,
          metricOne_max,
          metricOne_actual,
          metricOne_threshold,
        } = edgeMetrics;
        if (nodeHeader !== undefined) {
          var traffic_header = '-';
          var metricOne_header = '-';
          var metricTwo_header = '-';
          const header: any[] = nodeHeader.split(',');
          if (header[3] !== undefined) {
            traffic_header = header[3];
          }
          if (header[4] !== undefined) {
            metricOne_header = header[4];
          }
          if (header[5] !== undefined) {
            metricTwo_header = header[5];
          }
        }
        var edgeLabel = '-';
        var edgeLabel2 = '-';
        var edgeLabel3 = '-';
        var text_color = 'qtip-bootstrap qtip-normal';
        if (isDark) {
          text_color = 'qtip-bootstrap qtip-blue';
        }

        if (!useTrafficMetric || isNaN(traffic_out) || traffic_out === undefined || traffic_out < 0) {
          hasTrafficMetric = false;
          traffic_out = 0;
        }
        if (hasTrafficMetric) {
          Traffic_g = _.defaultTo(traffic_out / 1000000, 0);
          Traffic_m = _.defaultTo(traffic_out / 1000, 0);
          if (traffic_out >= 1000000) {
            edgeLabel = Traffic_g.toFixed(2) + ' Gbps';
          } else {
            if (traffic_out >= 1000) {
              edgeLabel = Traffic_m.toFixed(2) + ' Mbps';
            } else {
              edgeLabel = traffic_out.toFixed(2) + ' Kbps';
            }
          }
        }

        if (!useTrafficMetric || isNaN(traffic_in) || traffic_in === undefined || traffic_in < 0) {
          hasTrafficMetric = false;
          traffic_in = 0;
        }
        if (hasTrafficMetric) {
          Traffic_g = _.defaultTo(traffic_in / 1000000, 0);
          Traffic_m = _.defaultTo(traffic_in / 1000, 0);
          if (traffic_in >= 1000000) {
            edgeLabel = edgeLabel + ' / ' + Traffic_g.toFixed(2) + ' Gbps';
          } else {
            if (traffic_in >= 1000) {
              edgeLabel = edgeLabel + ' / ' + Traffic_m.toFixed(2) + ' Mbps';
            } else {
              edgeLabel = edgeLabel + ' / ' + traffic_in.toFixed(2) + ' Kbps';
            }
          }
        }

        if (useMetricOne && !isNaN(metricOne_actual) && !isNaN(metricOne_threshold) && !isNaN(metricOne_max)) {
          if (metricOne_actual > 0 && metricOne_threshold > 0) {
            if (metricOne_actual > metricOne_threshold) {
              text_color = 'qtip-bootstrap qtip-danger';
            } else {
              if (metricOne_actual === metricOne_threshold) {
                text_color = 'qtip-bootstrap qtip-warning';
              }
            }
          }
          if (metricOne_actual >= 0 && metricOne_max >= 0) {
            edgeLabel2 = metricOne_actual.toString() + ' / ' + metricOne_max.toString();
          }
        } else if (useMetricTwo && !isNaN(metricTwo_actual) && !isNaN(metricTwo_threshold) && !isNaN(metricTwo_max)) {
          if (metricTwo_actual > 0 && metricTwo_threshold > 0) {
            if (metricTwo_actual > metricTwo_threshold) {
              text_color = 'qtip-bootstrap qtip-danger';
            } else {
              if (metricTwo_actual === metricTwo_threshold) {
                text_color = 'qtip-bootstrap qtip-warning';
              }
            }
          }
          if (metricTwo_actual >= 0 && metricTwo_max >= 0) {
            edgeLabel3 = metricTwo_actual.toString() + ' / ' + metricTwo_max.toString();
          }
        }

        var content_label = '';
        if (useTrafficMetric && traffic_header !== '-' && hasTrafficMetric) {
          content_label = '<b>' + traffic_header + ':</b> ' + edgeLabel + '<br />';
        }
        if (useMetricOne && metricOne_header !== '-') {
          content_label = content_label + '<b>' + metricOne_header + ':</b> ' + edgeLabel2;
          if (metricTwo_header !== '-') {
            content_label = content_label + '<br /><b>' + metricTwo_header + ':</b> ' + edgeLabel3;
          }
        } else {
          if (useMetricTwo && metricTwo_header !== '-') {
            content_label = content_label + '<br /><b>' + metricTwo_header + ':</b> ' + edgeLabel3;
          }
        }
        if (!isVisible) {
          tooltip.style.left = x + 'px';
		  tooltip.style.top = y + 'px';
		  tooltip.innerHTML = content_label;
		  tooltip.classList.remove('graph-tooltip-hidden');
        }
      });
	  cy.on('mouseout', 'edge', function(event: any) {
	    tooltip.classList.add('graph-tooltip-hidden');
	  });
	  cy.on('click', 'edge', function(event: any) {
	    tooltip.classList.add('graph-tooltip-hidden');
	  });
      cy.on('cxttap', 'edge', function(event: any) {
        tooltip.classList.add('graph-tooltip-hidden');
      });
    }

    cy.on('render', () => {
      graphCanvas.repaint(true);
    });

    cy.on('resize', () => {
	  if (this.state.cy) {
	    const selection = this.state.cy.$(':selected');
		if (this.selectionId !== null) {
		  if (selection.length === 1) {
		    this.state.cy.center(selection);
		  }
	    } else if (this.state.isGeoMap && !this.state.initResize && this.state.leaf) {
	      if (this.state.latValue !== null && this.state.lonValue !== null && this.state.zoomValue !== null) {
			this.state.leaf.map.setView([this.state.latValue, this.state.lonValue], this.state.zoomValue );
		  } else {
			this.state.cy.center();
		  }
	    } else if (!this.state.isGeoMap && !this.state.initResize && this.state.cy) {
		  if (this.state.xValue !== null && this.state.yValue !== null && this.state.zValue !== null) {
	        this.state.cy.pan({ x: this.state.xValue, y: this.state.yValue });
			this.state.cy.zoom(this.state.zValue);
	      } else {
		    this.state.cy.center();
		  }
	    } else {
		  this.state.cy.center();
		}
	  }
    });

    cy.on('zoom', () => {
      tooltip.classList.add('graph-tooltip-hidden');
	  tooltipNode.classList.add('graph-tooltip-hidden');
	  if (!this.state.isGeoMap && !this.state.initResize && useZoomVariable && zoomVariable !== undefined) {
	    const zoom = this.state.cy.zoom();
		const center = this.state.cy.pan();
	    if (this.state.zValue !== zoom) {
		  const queryMap = {
            [`var-${zoomCyVariable}`]: zoom.toFixed(1),
            [`var-${xVariable}`]: center.x.toFixed(1),
            [`var-${yVariable}`]: center.y.toFixed(1),
          };
          try {
		    locationService.partial(queryMap, true);
		  } catch (error) {
		    console.log(error);
		  }
		  this.setState({
		    zValue: zoom,
		    xValue: center.x,
		    yValue: center.y,
		  });
		}
	  }
    });

    cy.on('pan', () => {
      tooltip.classList.add('graph-tooltip-hidden');
	  tooltipNode.classList.add('graph-tooltip-hidden');
	  if (this.selectionId === null && !this.state.isGeoMap && !this.state.initResize && 
		useZoomVariable && lonVariable !== undefined && latVariable !== undefined) {
	    const center = this.state.cy.pan();
        if (this.state.xValue !== center.x || this.state.yValue !== center.y) {
		  const queryMap = {
            [`var-${xVariable}`]: center.x.toFixed(1),
            [`var-${yVariable}`]: center.y.toFixed(1),
          };
          try {
		    locationService.partial(queryMap, true);
		  } catch (error) {
		    console.log(error);
		  }
		  this.setState({
		    xValue: center.x,
		    yValue: center.y,
		  });
		}
      }
    });

    cy.expandCollapse(colapseOptions);
    var api = cy.expandCollapse('get');

    cy.on('expandcollapse.afterexpand', 'node', () => {
	  if (this.state.cy && !this.state.isGeoMap) {
	    this.onTapNode();
	  }
	});

	cy.on('expandcollapse.aftercollapse', 'node', () => {
	  if (this.state.cy && !this.state.isGeoMap) {
	    const selection = this.state.cy.$(':selected');
	    this.onTapNode();
	    if (!this.state.initResize) {
	      this.runLayout();
	    }
	    if (selection.length === 1) {
		  this.state.cy.center(selection);
	    }
	  }
	});

    cy.on('expandcollapse.beforeexpand', 'node', function(event: any) {
      let node = event.target;
      node.data('type', 'GROUP_EXP');
    });

    cy.on('expandcollapse.beforecollapse', 'node', function(event: any) {
      let node = event.target;
      node.data('type', 'GROUP_COL');
    });

    cy.on('nodesCollapsed', () => {
      if (this.state.isCollapsed) {
        api.collapseAll();
      } else {
        api.expandAll();
      }
    });

    cy.one('render', function(event: any) {
      cy.nodes().forEach(function(node: any) {
        if (node.data('node_visible') === true) {
          cy.elements(node.union(node.siblings()).addClass('hidden'));
          let menuItem3 = {
            id: node.data('id'),
            content: node.data('id'),
            show: true,
            onClickFunction: function(event: any) {
              node = cy.getElementById(node.data('id'));
              cy.elements(node.union(node.siblings()).removeClass('hidden'));
              let group = node.data('parent');
              cy.getElementById(group).removeClass('hidden');
              contextMenu.removeMenuItem(node.data('id'));
              const eles = cy.$('.hidden');
              if (eles.empty()) {
                contextMenu.hideMenuItem('add-node');
              }
            },
          };
          contextMenu.appendMenuItem(menuItem3, 'add-node');
          contextMenu.showMenuItem('add-node');
        }
      });
      api.collapse(cy.collection('[type = "GROUP_COL"]'));
    });

    cy.on('dbltap', 'node', function(event: any) {
	  tooltipNode.classList.add('graph-tooltip-hidden');
	  let node = event.target;
      if (node.data('type') === 'GROUP_EXP') {
        if (api.isCollapsible(node)) {
          api.collapse(node);
        }
      } else if (node.data('type') === 'GROUP_COL') {
        if (api.isExpandable(node)) {
          api.expand(node);
        }
      }
	  this.runLayout();
    });

    var contextMenu = cy.contextMenus({
      evtType: 'cxttap',
      menuItems: [
        {
          id: 'add-node',
          content: 'Agregar activo o grupo',
          tooltipText: 'Agregar un activo o grupo que fue removido',
          show: false,
          coreAsWell: true,
          submenu: [],
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
          },
        },
        {
          id: 'remove',
          content: 'Eliminar activo o grupo',
          selector: 'node',
          show: true,
          onClickFunction: function(event: any) {
            let node = event.target;
            cy.elements(node.union(node.siblings()).addClass('hidden'));
            let group = node.data('parent');
            cy.getElementById(group).addClass('hidden');
            let menuItem3 = {
              id: node.data('id'),
              content: node.data('id'),
              show: true,
              onClickFunction: function(event: any) {
                node = cy.getElementById(node.data('id'));
                cy.elements(node.union(node.siblings()).removeClass('hidden'));
                let group = node.data('parent');
                cy.getElementById(group).removeClass('hidden');
                contextMenu.removeMenuItem(node.data('id'));
                const eles = cy.$('.hidden');
                if (eles.empty()) {
                  contextMenu.hideMenuItem('add-node');
                }
              },
            };
            contextMenu.appendMenuItem(menuItem3, 'add-node');
            contextMenu.showMenuItem('add-node');
          },
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
          },
        },
        {
          id: 'open',
          content: 'Más información...',
          tooltipText: 'Acceder a informacion detallada',
          selector: 'node',
          show: true,
          coreAsWell: true,
          onClickFunction: function(event: any) {
            let node = event.target;
            let linkAsset = site_link + node.data('id');
            if (node.data('type') === 'GROUP_EXP' || node.data('type') === 'GROUP_COL') {
              window.open(linkAsset, '_blank');
            } else if (node.data('type') === GraphDataType.INTERNAL) {
              linkAsset = asset_link + node.data('id');
              window.open(linkAsset, '_blank');
            }
          },
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
          },
        },
        {
          id: 'save-map',
          content: 'Guardar mapa',
          tooltipText: 'Guardar mapa de Topología',
          show: true,
          coreAsWell: true,
          onClickFunction: () => this.saveLayout(),
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
          },
        },
        {
          id: 'exp-map',
          content: 'Expandir/Contraer grupos',
          tooltipText: 'Expande o Contrae el grupo',
          show: true,
          coreAsWell: true,
          onClickFunction: () => this.muxCollapse(),
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
          },
        },
      ],
      menuItemClasses: ['custom-menu-item'],
      contextMenuClasses: ['custom-context-menu'],
      submenuIndicator: { src: 'public/img/icons/unicons/subject.svg', width: 14, height: 14 },
    });

    if (this.state.isGeoMap) {
	  if (this.state.isLock) {
	    this.lockNodes();
		leaf.enablePanMode();
      } else {
	    this.unlockNodes();
	    leaf.enableEditMode();
	  }
	} else {
	  if (this.state.isLock) {
	    this.lockNodes();
	    cy.panningEnabled(true);
        cy.zoomingEnabled(true);
      } else {
	    this.unlockNodes();
	    cy.panningEnabled(false);
        cy.zoomingEnabled(false);
	  }
	}

    var graphCanvas = new CanvasDrawer(
      this,
      cy,
      cy.cyCanvas({
        zIndex: 1,
      })
    );

    this.setState({
      cy: cy,
      graphCanvas: graphCanvas,
    });

	if (layoutFormat === 'preset' && this.state.isGeoMap && leaf !== null) {
	  this.setState({
	    leaf: leaf,
      });
	}

  }

  componentDidUpdate(prevProps: Props) {
    const actualEdges = prevProps.data.edges;
    const newEdges = this.props.data.edges;
    const actualNodes = prevProps.data.nodes;
    const newNodes = this.props.data.nodes;

    const equalEdges = actualEdges.length === newEdges.length &&
      actualEdges.every((edge, index) => {
        const newEdge = newEdges[index];
        return edge.data.id === newEdge.data.id && edge.data.sla === newEdge.data.sla;
      });

    const equalNodes = actualNodes.length === newNodes.length &&
      actualNodes.every((node, index) => {
        const newNode = newNodes[index];
        return node.data.id === newNode.data.id && node.data.sla === newNode.data.sla;
      });

    if (!equalNodes || !equalEdges) {
      this._updateGraph(this.props.data, true);
    }
  }


  getSettings(resolveVariables: boolean): PanelSettings {
    if (resolveVariables) {
      return this.resolveVariables(this.props.options);
    }
    return this.props.options;
  }

  resolveVariables(element: any) {
    if (element instanceof Object) {
      const newObject: any = {};
      for (const key of Object.keys(element)) {
        newObject[key] = this.resolveVariables(element[key]);
      }
      return newObject;
    }

    if (element instanceof String || typeof element === 'string') {
      return getTemplateSrv().replace(element.toString());
    }
    return element;
  }

  _updateGraph(graph: IntGraph, reload: boolean) {
    const cy = this.state.cy;
    const newElements = cytoscape({ elements: graph, headless: true }).elements();
    if (this.state.initResize) {
      if (this.state.isCollapsed) {
	    cy.emit('nodesCollapsed');
	  }
      cy.resize();
      this.runLayout();
    } else {
	  if (reload) {
        cy.elements().remove();
        cy.add(newElements);
      } else {
        const existingElements = cy.elements();
        const updatedNodes = newElements.filter(newNode => {
          const existingNode = existingElements.getElementById(newNode.id());
          if (existingNode.nonempty()) {
            existingNode.data(newNode.data()); // Update data for existing node
            return false; // Exclude already existing nodes from the updatedNodes
          }
          return true; // Include new nodes in updatedNodes
        });
        cy.add(updatedNodes);
      }

      // Restore positions after data update
      cy.nodes().positions((node: any) => {
        const id = node.id();
        const correspondingNode = newElements.getElementById(id);
        if (correspondingNode.nonempty()) {
          return correspondingNode.position();
        }
        return node.position(); // Maintain position for nodes not found in the new data
      });
	  if (this.state.isCollapsed) {
	    cy.emit('nodesCollapsed');
	  }
      this.runLayout();
    }
  }


  runLayout() {
	const { infiniteSimulation } = this.getSettings(false);
	const unlockNodes = !this.state.isLock;
	const that = this;
    var options = {
      ...layoutOptions,
        stop: function() {
          if (that.state.isLock && !that.state.isGeoMap) {
		    that.lockNodes();
		  } else if (!that.state.isGeoMap) {
            that.unlockNodes();
          }
        },
    };
	if (infiniteSimulation && this.layout !== 'preset') {
	  options = {
		...infinite,
	  };
	} else {
	  let hasPosition = this.layout === 'preset' ? true : false;
      if (this.state.isGeoMap) {
	    this.state.cy.nodes().forEach(function(node: any) {
          if (node.data('lat') !== undefined && node.data('lng') !== undefined && node.data('lat') !== null && node.data('lng') !== null) {
		    if (node.data('lat') === null || node.data('lng') === null) {
              node.position().x = this.state.lonValue;
              node.position().y = this.state.latValue;
		    } else {
	          let lat = node.data('lat');
              let lng = node.data('lng');
              node.position().x = lng;
              node.position().y = lat;
		    }
		  }
        });
	  } else {
	    this.state.cy.nodes().forEach(function(node: any) {
	      if (hasPosition) {
            if (node.data('x') !== undefined && node.data('y') !== undefined && node.data('x') !== null && node.data('y') !== null) {
		      if (node.data('x') === null || node.data('y') === null) {
                hasPosition = false;
		      } else {
	            let x = node.data('x');
                let y = node.data('y');
                node.position().x = x;
                node.position().y = y;
		      }
		    } else {
		      hasPosition = false;
		    }
	      }
        });
	  }
	  var Layout = (!hasPosition && this.layout === 'preset') ? 'cola' : this.layout;
      if (this.layout === 'preset' && this.state.isGeoMap) {
	    Layout = 'preset';
	  }

      switch (Layout) {
        case 'cola':
          options = {
            ...layoutOptions_cola,
            stop: function() {
			  if (that.state.isLock && !that.state.isGeoMap) {
				that.lockNodes();
			  } else if (!that.state.isGeoMap) {
				that.unlockNodes();
			  }
            },
          };
          break;
        case 'cise':
          options = {
            ...layoutOptions_cise,
            stop: function() {
			  if (that.state.isLock && !that.state.isGeoMap) {
				that.lockNodes();
			  } else if (!that.state.isGeoMap) {
				that.unlockNodes();
			  }

            },
          };
          break;
        case 'avsdf':
          options = {
            ...layoutOptions_avsdf,
            stop: function() {
			  if (that.state.isLock && !that.state.isGeoMap) {
				that.lockNodes();
			  } else if (!that.state.isGeoMap) {
				that.unlockNodes();
			  }

            },
          };
          break;
        case 'dagre':
          options = {
            ...layoutOptions_dagre,
            stop: function() {
			  if (that.state.isLock && !that.state.isGeoMap) {
				that.lockNodes();
			  } else if (!that.state.isGeoMap) {
				that.unlockNodes();
			  }

            },
          };
          break;
        case 'fcose':
          options = {
            ...layoutOptions_fcose,
            stop: function() {
			  if (that.state.isLock && !that.state.isGeoMap) {
				that.lockNodes();
			  } else if (!that.state.isGeoMap) {
				that.unlockNodes();
			  }

            },
          };
          break;
      }
	}

	if (this.state.initResize && this.state.isGeoMap && this.state.leaf) {
	  if (Layout === 'preset') {
	    this.state.leaf.map.setView([this.state.latValue, this.state.lonValue], this.state.zoomValue );
	  }
	  if (this.state.isLock) {
	    this.state.leaf.enablePanMode();
	  } else {
	    this.state.leaf.enableEditMode();
	  }
	}

    this.state.cy.layout(options).run();

	this.state.graphCanvas.repaint(true);
	if (this.state.initResize && !this.state.isGeoMap && this.state.cy) {
	  if (Layout === 'preset') {
		this.state.cy.pan({ x: this.state.xValue, y: this.state.yValue });
	    if (this.state.zValue < 0.25 || this.state.zValue > 3) {
		  this.state.cy.fit();
	    } else {
	      this.state.cy.zoom(this.state.zValue);
	    }
	  }
	  if (this.state.isLock) {
	    this.state.cy.panningEnabled(true);
        this.state.cy.zoomingEnabled(true);
      } else {
	    this.state.cy.panningEnabled(false);
        this.state.cy.zoomingEnabled(false);
	  }
	}
  }

  onTapNode() {
    const selection = this.state.cy.$(':selected');
	const { nodeVariable, groupVariable, edgeVariable } = this.getSettings(true);
	if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
	  let queryMap = { 
		[`var-${groupVariable}`]: '',
		[`var-${nodeVariable}`]: '', 
		[`var-${edgeVariable}`]: ''
	  };
	  try {
		locationService.partial(queryMap, true);
	  } catch (error) {
		console.log(error);
	  }
	}
    if (selection.length === 1) {
	  this.state.cy.center(selection);
	}
  }

  onSelectionChange(selectedNode: NodeSingular) {
    const selection = selectedNode !== null ? selectedNode : this.state.cy.$(':selected');
	const { nodeVariable, groupVariable, edgeVariable } = this.getSettings(true);
    if (selection.length === 1) {
      const currentNode: NodeSingular = selection[0];
	  this.selectionId = currentNode.id().toString();
	  const nodeLabel = currentNode.data('label');
      if (selection.data('type') !== 'GROUP_EXP' && selection.data('type') !== 'GROUP_COL' && nodeLabel !== undefined) {
        if (this.showStatTables) {
		  this.updateStatisticTable();
          this.setState({
            showStatistics: true,
          });
		}
		if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
		  let queryMap = {
		    [`var-${groupVariable}`]: '',
			[`var-${nodeVariable}`]: currentNode.data('id'),
			[`var-${edgeVariable}`]: ''
		  };
          try {
		    locationService.partial(queryMap, true);
		  } catch (error) {
		    console.log(error);
		  }
		}
      } else if (selection.data('type') === 'GROUP_COL' && selection.data('type') !== 'GROUP_EXP' && nodeLabel !== undefined) {
		if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
		  let queryMap = { 
		    [`var-${groupVariable}`]: currentNode.data('id'),
			[`var-${nodeVariable}`]: '', 
			[`var-${edgeVariable}`]: ''
		  };
          try {
		    locationService.partial(queryMap, true);
		  } catch (error) {
		    console.log(error);
		  }
		}
	  } else {
        if (this.showStatTables) {
		  this.setState({
            showStatistics: false,
          });
		}
		if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
		  let queryMap = { 
		    [`var-${groupVariable}`]: '',
		    [`var-${nodeVariable}`]: '', 
		    [`var-${edgeVariable}`]: ''
		  };
          try {
		    locationService.partial(queryMap, true);
		  } catch (error) {
		    console.log(error);
		  }
		  this.selectionId = null;
		}
      }
	  if (this.state.isGeoMap) {
	    const lat = currentNode.data('lat');
        const lng = currentNode.data('lng');
	    this.setState({
	      latValue: lat,
	      lonValue: lng,
	    });
	    this.state.leaf.map.setView([lat, lng], this.state.zoomValue );
	  } else {
	    this.state.cy.center(currentNode);
	  }
    } else {
      if (this.showStatTables) {
        this.setState({
          showStatistics: false,
        });
	  }
	  if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
		let queryMap = { 
		  [`var-${groupVariable}`]: '',
		  [`var-${nodeVariable}`]: '', 
		  [`var-${edgeVariable}`]: ''
		};
        try {
		  locationService.partial(queryMap, true);
		} catch (error) {
		  console.log(error);
		}
	    this.selectionId = null;
	  }
	  if (this.layout === 'preset' && this.state.cy && this.state.xValue && this.state.yValue) {
	    this.state.cy.pan({ x: this.state.xValue, y: this.state.yValue });
	  } else {
	    this.state.cy.center();
	  }
    }
  }
  
  onEdgeSelectionChange() {
    const selection = this.state.cy.$(':selected');
	const { edgeVariable, nodeVariable, groupVariable } = this.getSettings(true);
    if (selection.length === 1) {
      const actualEdge: EdgeSingular = selection[0];
      const edgeLabel = actualEdge.data('label');
      this.selectionId = edgeLabel;
	  if (edgeLabel !== undefined && edgeLabel !== null) {
        if (this.showStatTables) {
		  this.updateEdgeStatisticTable();
          this.setState({
            showEdgeStatistics: true,
          });
		}
		if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
		  let queryMap = {
 		    [`var-${edgeVariable}`]: actualEdge.data('source')+ '-' + actualEdge.data('target'),
			[`var-${nodeVariable}`]: '',
			[`var-${groupVariable}`]: ''
		  };
          try {
		    locationService.partial(queryMap, true);
		  } catch (error) {
		    console.log(error);
		  }
		}
      } else {
        if (this.showStatTables) {
		  this.setState({
            showEdgeStatistics: false,
          });
		}
		if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
		  let queryMap = { 
		    [`var-${groupVariable}`]: '',
		    [`var-${nodeVariable}`]: '', 
		    [`var-${edgeVariable}`]: ''
		  };
          try {
		    locationService.partial(queryMap, true);
		  } catch (error) {
		    console.log(error);
		  }
		}
		this.selectionId = null;
      }
	  this.state.cy.center(actualEdge);
    } else {
      if (this.showStatTables) {
	    this.setState({
          showEdgeStatistics: false,
        });
	  }
	  if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
		let queryMap = { 
		  [`var-${groupVariable}`]: '',
		  [`var-${nodeVariable}`]: '', 
		  [`var-${edgeVariable}`]: ''
		};
        try {
		  locationService.partial(queryMap, true);
		} catch (error) {
		  console.log(error);
		}
	  }
	  this.selectionId = null;
    }
  }

  unlockNodes() {
    if (this.state.cy) {
	  this.state.cy.nodes().forEach((node: { unlock: () => void }) => {
        node.unlock();
      });
	}
  }

  lockNodes() {
    if (this.state.cy) {
	  this.state.cy.nodes().forEach((node: { lock: () => void }) => {
        node.lock();
      });
	}
  }

  saveLayout() {
    const { map, api } = this.getSettings(true);
    const resolverApi = this.templateSrv.replace(api);
	const resolverMap = this.templateSrv.replace(map);
	var position = '{"dest": "topology", "params":"';
    var elem = 0;
    this.state.cy.nodes().forEach(function(n) {
      elem = elem + 1;
      const asset = n.data('id');
      const type = n.data('type');
      const user = n.data('user');
      const visible = n.hasClass('hidden');
      const x = this.state.isGeoMap ? n.position().x : n.position().x.toFixed(2);
      const y = this.state.isGeoMap ? n.position().y : n.position().y.toFixed(2);
      if (elem < 2) {
        position =
          position +
          asset.toString() +
          ';' +
          x.toString() +
          ';' +
          y.toString() +
          ';' +
          type.toString() +
          ';' +
          user +
          ';' +
          resolverMap +
          ';' +
          visible.toString();
      } else {
        position =
          position +
          ',' +
          asset.toString() +
          ';' +
          x.toString() +
          ';' +
          y.toString() +
          ';' +
          type.toString() +
          ';' +
          user +
          ';' +
          resolverMap +
          ';' +
          visible.toString();
      }
    });
    position = position + '"}';

    axios.defaults.baseURL = resolverApi;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post(resolverApi, position).then(
      response => {
        if (response.statusText === 'OK') {
          SystemJS.load('app/core/app_events').then((appEvents: any) => {
            appEvents.emit(AppEvents.alertSuccess, ['Mapa guardado correctamente']);
          });
        } else {
          SystemJS.load('app/core/app_events').then((appEvents: any) => {
            appEvents.emit(AppEvents.alertSuccess, [response.statusText]);
          });
        }
      },
      error => {
        SystemJS.load('app/core/app_events').then((appEvents: any) => {
          appEvents.emit(AppEvents.alertError, ['Error al guardar el mapa' + error.response.status]);
        });
      }
    );
  }

  zoom(factor: number) {
	if (this.state.isGeoMap) {
	  const zoomStep = 0.25 * factor;
	  const actualZoom = this.state.leaf.map.getZoom();
      const zoomLevel: number = actualZoom + zoomStep;
	  this.state.leaf.map.setView([this.state.latValue, this.state.lonValue], zoomLevel );
	} else {
	  const zoomStep = 0.25 * factor;
	  const actualZoom: number = this.state.cy.zoom();
      const zoomLevel: number = actualZoom + zoomStep;
	  this.state.cy.zoom(zoomLevel);
	}
  }

  updateStatisticTable() {
    const selection = this.state.cy.$(':selected');
    const { useTrafficMetric, useMetricOne, useMetricTwo } = this.getSettings(false);

    if (selection.length === 1) {
      const currentNode: NodeSingular = selection[0];
      this.selectionId = currentNode.id().toString();
      this.currentLabel = currentNode.data('label');
      this.currentSite = currentNode.data('site');
      this.currentType = currentNode.data('type');
      const nodeDescription = currentNode.data('node_description');
      const receiving: TableContent[] = [];
      const sending: TableContent[] = [];
      const table_header: TableHeader[] = [];
      const systemDescription: Table3Content[] = [];
      const sys_header: Table3Header[] = [];
      const edges: EdgeCollection = selection.connectedEdges();
      let targetLabel = currentNode.data('target');
      let targetSite = currentNode.data('site');
      let metricOneMax = -1;
      let metricOneActual = -1;
      let metricOneThreshold = -1;
      let metricTwoActual = -1;
      let traffic_in = -1;
      let traffic_out = -1;
      if (useTrafficMetric || useMetricOne || useMetricTwo) {
        let metrics: IntGraphMetrics = selection.nodes()[0].data('metrics');
        targetLabel = metrics.target_label;
        targetSite = metrics.target_site;
        if (useMetricOne) {
          metricOneMax = metrics.metricOne_max;
          metricOneActual = metrics.metricOne_actual;
          metricOneThreshold = metrics.metricOne_threshold;
        }
        if (useMetricTwo) {
          metricTwoActual = metrics.metricTwo_actual;
        }
        if (useTrafficMetric) {
          traffic_in = metrics.traffic_in;
          traffic_out = metrics.traffic_out;
        }
      }
      this.selectionStatistics = {};

      this.selectionStatistics.target_label = targetLabel;
      this.selectionStatistics.target_site = targetSite;

      if (metricOneMax >= 0) {
        this.selectionStatistics.metricOne_max = Math.floor(metricOneMax);
      }
      if (metricOneThreshold >= 0) {
        this.selectionStatistics.metricOne_threshold = Math.floor(metricOneThreshold);
        this.selectionStatistics.thresholdViolation = metricOneActual > metricOneThreshold;
      }
      if (metricOneActual >= 0) {
        this.selectionStatistics.metricOne_actual = Math.floor(metricOneActual);
      } else {
        this.selectionStatistics.metricOne_actual = 0;
      }
      if (metricTwoActual >= 0) {
        this.selectionStatistics.metricTwo_actual = Math.floor(metricTwoActual);
      } else {
        this.selectionStatistics.metricTwo_actual = 0;
      }
      if (traffic_in >= 0) {
        this.selectionStatistics.traffic_in = Math.floor(traffic_in);
      }
      if (traffic_out >= 0) {
        this.selectionStatistics.traffic_out = Math.floor(traffic_out);
      }

      if (nodeDescription !== undefined && nodeDescription !== null && nodeDescription !== '') {
        const sendingsysDescription: Table3Content = {
          sysDescription: '-',
          sysTime: '-',
          sysLocation: '-',
        };
        const system: any[] = nodeDescription.split(',');
        if (system[0] !== undefined) {
          sendingsysDescription.sysDescription = system[0];
        } else {
          sendingsysDescription.sysDescription = '-';
        }
        if (system[1] !== undefined) {
          sendingsysDescription.sysTime = system[1];
        } else {
          sendingsysDescription.sysTime = '-';
        }
        if (system[2] !== undefined) {
          sendingsysDescription.sysLocation = system[2];
        } else {
          sendingsysDescription.sysLocation = '-';
        }
        systemDescription.push(sendingsysDescription);
      }

      const { sysHeader } = this.getSettings(false);
      if (sysHeader !== undefined) {
        const sendingSysHeader: Table3Header = {
          description_header: '-',
          time_header: '-',
          location_header: '-',
        };
        const header3: any[] = sysHeader.split(',');
        if (header3[0] !== undefined) {
          sendingSysHeader.description_header = header3[0];
        } else {
          sendingSysHeader.description_header = '-';
        }
        if (header3[1] !== undefined) {
          sendingSysHeader.time_header = header3[1];
        } else {
          sendingSysHeader.time_header = '-';
        }
        if (header3[2] !== undefined) {
          sendingSysHeader.location_header = header3[2];
        } else {
          sendingSysHeader.location_header = '-';
        }
        sys_header.push(sendingSysHeader);
      }

      for (let i = 0; i < edges.length; i++) {
        const actualEdge: EdgeSingular = edges[i];
        const sendingCheck: boolean = actualEdge.source().id() === this.selectionId;
        let node: NodeSingular;

        if (sendingCheck) {
          node = actualEdge.target();
        } else {
          node = actualEdge.source();
        }

        const sendingObject: TableContent = {
          name: node.id(),
          label: '-',
          site: '-',
          traffic_in: '-',
          traffic_out: '-',
          metricOneMax: '-',
          metricTwoMax: '-',
          metricTwoActual: '-',
          metricOneActual: '-',
        };

        const edgeMetrics: IntGraphMetrics = actualEdge.data('metrics');

        if (edgeMetrics !== undefined) {
          const {
            target_label,
            target_site,
            traffic_out,
            traffic_in,
            metricOne_max,
            metricTwo_max,
            metricTwo_actual,
            metricOne_actual,
          } = edgeMetrics;
          const source_label = actualEdge.data('label');
          const source_site = actualEdge.data('site');

          if (sendingCheck) {
            sendingObject.label = source_label;
            sendingObject.site = source_site;
          } else {
            sendingObject.label = target_label;
            sendingObject.site = target_site;
          }
          if (metricOne_max !== undefined) {
            sendingObject.metricOneMax = Math.floor(metricOne_max).toString();
          }
          if (metricTwo_max !== undefined) {
            sendingObject.metricTwoMax = Math.floor(metricTwo_max).toString();
          }
          if (traffic_in !== undefined) {
            const TrafficIn_g = traffic_in / 1000000;
            const TrafficIn_m = traffic_in / 1000;
            if (sendingCheck) {
              if (traffic_in >= 1000000) {
                sendingObject.traffic_in = Math.floor(TrafficIn_g) + ' Gbps';
              } else {
                if (traffic_in >= 1000) {
                  sendingObject.traffic_in = Math.floor(TrafficIn_m) + ' Mbps';
                } else {
                  sendingObject.traffic_in = Math.floor(traffic_in) + ' Kbps';
                }
              }
            } else {
              if (traffic_in >= 1000000) {
                sendingObject.traffic_out = Math.floor(TrafficIn_g) + ' Gbps';
              } else {
                if (traffic_in >= 1000) {
                  sendingObject.traffic_out = Math.floor(TrafficIn_m) + ' Mbps';
                } else {
                  sendingObject.traffic_out = Math.floor(traffic_in) + ' Kbps';
                }
              }
            }
          }
          if (traffic_out !== undefined) {
            const Traffic_g = traffic_out / 1000000;
            const Traffic_m = traffic_out / 1000;
            if (sendingCheck) {
              if (traffic_out >= 1000000) {
                sendingObject.traffic_out = Math.floor(Traffic_g) + ' Gbps';
              } else {
                if (traffic_out >= 1000) {
                  sendingObject.traffic_out = Math.floor(Traffic_m) + ' Mbps';
                } else {
                  sendingObject.traffic_out = Math.floor(traffic_out) + ' Kbps';
                }
              }
            } else {
              if (traffic_out >= 1000000) {
                sendingObject.traffic_in = Math.floor(Traffic_g) + ' Gbps';
              } else {
                if (traffic_out >= 1000) {
                  sendingObject.traffic_in = Math.floor(Traffic_m) + ' Mbps';
                } else {
                  sendingObject.traffic_in = Math.floor(traffic_out) + ' Kbps';
                }
              }
            }
          }
          if (metricTwo_actual !== undefined) {
            sendingObject.metricTwoActual = Math.floor(metricTwo_actual).toString();
          } else {
            sendingObject.metricTwoActual = '0';
          }
          if (metricOne_actual !== undefined) {
            sendingObject.metricOneActual = Math.floor(metricOne_actual).toString();
          } else {
            sendingObject.metricOneActual = '0';
          }
        }
        if (sendingCheck) {
          sending.push(sendingObject);
        } else {
          receiving.push(sendingObject);
        }
      }
      const { nodeHeader, nodeTitle } = this.getSettings(false);
      var node_header = nodeHeader;
      var node_title = nodeTitle;
      const iconMappings = this.getSettings(true).icons;
      const mapping = _.find(iconMappings, ({ pattern }) => {
        try {
          return new RegExp(pattern).test(selection.id().toString());
        } catch (error) {
          return false;
        }
      });

      if (mapping) {
        if (mapping.node_title !== '-') {
          node_title = mapping.node_title;
        }
        if (mapping.node_header !== '-') {
          node_header = mapping.node_header;
        }
      }
      const sendingHeader: TableHeader = {
        name_header: '-',
        label_header: '-',
        site_header: '-',
        traffic_header: '-',
        metricOne_header: '-',
        metricTwo_header: '-',
      };
      if (node_header !== undefined) {
        const header: any[] = node_header.split(',');
        if (header[0] !== undefined && header[0] !== '') {
          sendingHeader.name_header = header[0];
        } else {
          sendingHeader.name_header = '-';
        }
        if (header[1] !== undefined && header[1] !== '') {
          sendingHeader.label_header = header[1];
        } else {
          sendingHeader.label_header = '-';
        }
        if (header[2] !== undefined && header[2] !== '') {
          sendingHeader.site_header = header[2];
        } else {
          sendingHeader.site_header = '-';
        }
        if (header[3] !== undefined && header[3] !== '') {
          sendingHeader.traffic_header = header[3];
        } else {
          sendingHeader.traffic_header = '-';
        }
        if (header[4] !== undefined && header[4] !== '') {
          sendingHeader.metricOne_header = header[4];
        } else {
          sendingHeader.metricOne_header = '-';
        }
        if (header[5] !== undefined && header[5] !== '' && header[5] !== null) {
          sendingHeader.metricTwo_header = header[5];
        } else {
          sendingHeader.metricTwo_header = '-';
        }
      }
      table_header.push(sendingHeader);

      this.currentDescription = systemDescription;
      this.sys_header = sys_header;
      this.node_title = node_title;
      this.table_header = table_header;
      this.receiving = receiving;
      this.sending = sending;
      this.generateDrillDownLink('asset');
      this.generateDrillDownLink('site');
    }
  }

  updateEdgeStatisticTable() {
    const selection = this.state.cy.$(':selected');
	const { isStpTopology } = this.getSettings(false);

    if (selection.length === 1) {
      const actualEdge: EdgeSingular = selection[0];
      const links: Table2Content[] = [];
      const linksHeader: Table2Header[] = [];
      const edgeMetrics: IntGraphMetrics = actualEdge.data('metrics');

      if (edgeMetrics !== undefined) {
        const { target_label, description } = edgeMetrics;
        var temp: any[] = [];
		if (description !== undefined && description !== null) {
          temp = description.split(',');
        }
        const source_Label = actualEdge.data('label');
        const target_Label = target_label;
        let i = 0;
        const length = temp.length;

        for (; i < length; ) {
          const sendingObject: Table2Content = {
            name: '-',
            label: '-',
            source_port: '-',
            source_lag: '-',
            target_label: '-',
            target_port: '-',
            target_lag: '-',
            stat: '-',
          };
          const port: any[] = temp[i].split('|');
          sendingObject.name = actualEdge.source().id();
          sendingObject.label = source_Label;
          if (port[0] !== undefined) {
            sendingObject.source_port = port[0];
          } else {
            sendingObject.source_port = 'S/D';
          }
          if (port[1] !== undefined) {
            sendingObject.source_lag = port[1];
          } else {
            sendingObject.source_lag = 'S/D';
          }
          sendingObject.target_label = target_Label;
          if (port[2] !== undefined) {
            sendingObject.target_port = port[2];
          } else {
            sendingObject.target_port = 'S/D';
          }
          if (port[3] !== undefined) {
            sendingObject.target_lag = port[3];
          } else {
            sendingObject.target_lag = 'S/D';
          }
          if (isStpTopology) {
		    if (port[4] !== undefined) {
              switch (port[4]) {
                case '0':
                  sendingObject.stat = 'Ena';
                  break;
                case '1':
                  sendingObject.stat = 'Dis';
                  break;
                case '2':
                  sendingObject.stat = 'Blk';
                  break;
                case '3':
                  sendingObject.stat = 'Lis';
                  break;
                case '4':
                  sendingObject.stat = 'Lea';
                  break;
                case '5':
                  sendingObject.stat = 'Fwd';
                  break;
                default:
                  sendingObject.stat = 'Fail';
              }
            } else {
              sendingObject.stat = 'Err';
			}
		  } else {
		    if (port[4] !== undefined) {
              switch (port[4]) {
                case '0':
                  sendingObject.stat = 'S/D';
                  break;
                case '1':
                  sendingObject.stat = 'Up';
                  break;
                case '2':
                  sendingObject.stat = 'Down';
                  break;
                case '3':
                  sendingObject.stat = 'Alarm';
                  break;
                default:
                  sendingObject.stat = 'S/D';
              }
            } else {
              sendingObject.stat = 'Down';
			}
          }
          links.push(sendingObject);
          i++;
        }
        this.links = links;
        const { edgeHeader, edgeTitle } = this.getSettings(false);
        var edge_header = edgeHeader;
        var edge_title = edgeTitle;
        const iconMappings = this.getSettings(true).icons;
        const mapping = _.find(iconMappings, ({ pattern }) => {
          try {
            return new RegExp(pattern).test(selection.id().toString());
          } catch (error) {
            return false;
          }
        });

        if (mapping) {
          if (mapping.links_title !== '-') {
            edge_title = mapping.links_title;
          }
          if (mapping.links_header !== '-') {
            edge_header = mapping.links_header;
          }
        }
        const sendingHeader: Table2Header = {
          label_header: '-',
          source_port_header: '-',
          source_type_header: '-',
          target_label_header: '-',
          target_port_header: '-',
          target_type_header: '-',
          status_header: '-',
        };
        if (edge_header !== undefined) {
		  const header: any[] = edge_header.split(',');
          if (header[0] !== undefined || header[0] !== '') {
            sendingHeader.label_header = header[0];
          } else {
            sendingHeader.label_header = '-';
          }
          if (header[1] !== undefined || header[1] !== '') {
            sendingHeader.source_port_header = header[1];
          } else {
            sendingHeader.source_port_header = '-';
          }
          if (header[2] !== undefined || header[2] !== '') {
            sendingHeader.source_type_header = header[2];
          } else {
            sendingHeader.source_type_header = '-';
          }
          if (header[3] !== undefined || header[3] !== '') {
            sendingHeader.target_label_header = header[3];
          } else {
            sendingHeader.target_label_header = '-';
          }
          if (header[4] !== undefined || header[4] !== '') {
            sendingHeader.target_port_header = header[4];
          } else {
            sendingHeader.target_port_header = '-';
          }
          if (header[5] !== undefined || header[5] !== '') {
            sendingHeader.target_type_header = header[5];
          } else {
            sendingHeader.target_type_header = '-';
          }
          if (header[6] !== undefined || header[6] !== '') {
            sendingHeader.status_header = header[6];
          } else {
            sendingHeader.status_header = '-';
          }
        }
        linksHeader.push(sendingHeader);
        this.linksHeader = linksHeader;
        this.edge_title = edge_title;
      }
    }
  }

  panToPosition() {
	if (this.state.leaf.map) {
	  this.state.leaf.map.setView([this.state.latValue, this.state.lonValue], this.state.zoomValue );
	}
  }

  muxCollapse() {
    if (this.state.isCollapsed) {
      this.handleCollapse('GROUP_EXP');
    } else {
      this.handleCollapse('GROUP_COL');
    }
  }

  handleCollapse(action: string) {
    this.unlockNodes();
    if (action === 'GROUP_EXP') {
      this.setState({
        isCollapsed: false,
      });
    } else {
      this.setState({
        isCollapsed: true,
      });
    }
    this.state.cy.emit('nodesCollapsed');
    if (this.state.isLock) {
      this.lockNodes();
	  this.state.cy.panningEnabled(true);
      this.state.cy.zoomingEnabled(true);
    }
  }

  handleLock() {
    if (this.state.isLock) {
      this.unlockNodes();
	  this.state.cy.panningEnabled(false);
      this.state.cy.zoomingEnabled(false);
	  this.setState({
        isLock: false,
      });
    } else {
      this.lockNodes();
      this.state.cy.panningEnabled(true);
	  this.state.cy.zoomingEnabled(true);
	  this.setState({
        isLock: true,
      });
    }
  }

  handleEditMode() {
    if (this.state.isLock) {
      this.state.leaf.enableEditMode();
      this.setState({
        isLock: false,
      });
    } else {
      this.state.leaf.enablePanMode();
      this.setState({
        isLock: true,
      });
    }
  }

  handleMapMode() {
    const { addMapVariable, addMap } = this.getSettings(false);
	if (this.state.isGeoMap) {
	  if (addMapVariable !== '' && addMap) {
	    let queryMap = {
		  [`var-${addMapVariable}`]: '0',
		};
        try {
		  locationService.partial(queryMap, true);
		} catch (error) {
		  console.log(error);
		}
	  }
      this.setState({
        isGeoMap: false,
      });
    } else {
	  if (addMapVariable !== '' && addMap) {
	    let queryMap = {
		  [`var-${addMapVariable}`]: '1',
		};
        try {
		  locationService.partial(queryMap, true);
		} catch (error) {
		  console.log(error);
		}
	  }
      this.setState({
        isGeoMap: true,
      });
    }
  }

  generateMapname(type: string) {
    const { map } = this.getSettings(true);
    if (map !== undefined && map !== null && map !== '') {
      const resolverMap = this.templateSrv.replace(map);
	  const link = resolverMap.replace('{}', this.selectionId);
      this.resolvedDrillDownLink_asset = this.templateSrv.replace(link);
	  return this.templateSrv.replace(link);
    } else {
      return 'default';
    }
  }
  
  generateDrillDownLink(type: string) {
    if (type === 'asset') {
      const { drillDownLink_asset } = this.getSettings(true);
      if (drillDownLink_asset !== undefined) {
        const link = drillDownLink_asset.replace('{}', this.selectionId);
        this.resolvedDrillDownLink_asset = this.templateSrv.replace(link);
        return this.templateSrv.replace(link);
      } else {
        return null;
      }
    } else {
      const { drillDownLink_site } = this.getSettings(true);
      if (drillDownLink_site !== undefined) {
        const link = drillDownLink_site.replace('{}', this.selectionId);
        this.resolvedDrillDownLink_site = this.templateSrv.replace(link);
        return this.templateSrv.replace(link);
      } else {
        return null;
      }
    }
  }

  render() {
    const { addMap } = this.getSettings(false);
	const isDark = config.theme.isDark;
	var show_toolbar = 'zoom-button-container';
    if (!this.toolbar) {
      show_toolbar = 'zoom-button-container-hide';
    }

	if ((!this.state.isGeoMap && this.state.cy !== undefined && this.state.initResize) ||
	  (this.state.isGeoMap && this.state.leaf !== undefined && this.state.initResize)) {
	  this._updateGraph(this.props.data, false);
	  this.setState({
		initResize: false,
	  });
    }

    const lockButton = () => {
      if (this.state.isGeoMap) {
        return (
          <button 
			className={isDark ? 'btn button-map_dark' : 'btn button-map'} 
			title={this.state.isLock ? 'Habilitar modo edición' : 'Habilitar Zoom y Paneo'}
			onClick={() => this.handleEditMode()}
		  >
            <Icon name={this.state.isLock ? 'pencil-circle-fill' : 'hand-circle'} size="26" />
          </button>
        );
	  } else {
        return (
          <button 
			className={isDark ? 'btn button-map_dark' : 'btn button-map'} 
			title={this.state.isLock ? 'Habilitar modo edición' : 'Habilitar Zoom y Paneo'}
			onClick={() => this.handleLock()}
		  >
            <Icon name={this.state.isLock ? 'pencil-circle-fill' : 'hand-circle'} size="26" />
          </button>
        );
	  }
    };

    return (
      <>
	  <div 
	    id="tooltip-node"
		className={isDark ? 'graph-tooltip_dark graph-tooltip-hidden' : 'graph-tooltip graph-tooltip-hidden'}
	  ></div>
	  <div 
	    id="tooltip-edge"
		className={isDark ? 'graph-tooltip_dark graph-tooltip-hidden' : 'graph-tooltip graph-tooltip-hidden'}
	  ></div>
	  <div className={'graph-container'} id={this.state.isGeoMap ? 'geoMap' : 'topologyMap'}>
        <div className="netmonitor-topology-map">
		  {this.state.isGeoMap && (
            <div 
		      className="leaf-container" 
			  id={'geoMapInfo'}
			  ref={ref => (this.mapRef = ref)}
		    ></div>
		  )}
          <div 
		    className={this.state.isLock ? 'canvas-container cursor_pan' : 'canvas-container'}
			id={'topologyMapInfo'}
			ref={ref => (this.ref = ref)}
		  ></div>
          <div className={show_toolbar}>
            {addMap && this.selectionId === null && (
			  <button
                className={isDark ? 'btn button-map_dark' : 'btn button-map'}
                title={this.state.isGeoMap ? 'Ver como esquema de topología' : 'Ver esquema en Mapa'}
                onClick={() => this.handleMapMode()}
              >
                <Icon name={this.state.isGeoMap ? 'diagram-3-fill' : 'geo-fill'} size="26"/>
              </button>
			)}
            {!this.state.isLock && (
			  <button
                className={isDark ? 'btn button-map_dark' : 'btn button-map'}
                title="Guardar mapa de Topología"
                onClick={() => this.saveLayout()}
              >
                <Icon name={'save-fill'} size="26"/>
              </button>
			)}
			<button
              className={isDark ? 'btn button-map_dark' : 'btn button-map'}
               itle="Refrescar mapa de Topología"
               nClick={() => {
				this.runLayout();
			  }}
            >
              <Icon name={'redo-fill'} size="26" />
            </button>
            {lockButton()}
            {this.selectionId === null && this.state.isLock && (
			  <>
			    <button className={isDark ? 'btn button-map_dark' : 'btn button-map'} title="Zoom In" onClick={() => this.zoom(+1)}>
                  <Icon name={'plus-circle-fill'} size="26" />
                </button>
                <button className={isDark ? 'btn button-map_dark' : 'btn button-map'}title="Zoom Out" onClick={() => this.zoom(-1)}>
                  <Icon name={'minus-circle-fill'} size="26" />
                </button>
			  </>
			)}
          </div>
        </div>
        <Statistics
          show={this.state.showStatistics}
          showEdge={this.state.showEdgeStatistics}
          selectionId={this.selectionId}
          currentDescription={this.currentDescription}
          sysHeader={this.sys_header}
          resolvedDrillDownLink_asset={this.resolvedDrillDownLink_asset}
          resolvedDrillDownLink_site={this.resolvedDrillDownLink_site}
          selectionStatistics={this.selectionStatistics}
          currentType={this.currentType}
          showBaselines={this.getSettings(true).showBaselines}
          receiving={this.receiving}
          sending={this.sending}
          nodeTitle={this.node_title}
          edgeTitle={this.edge_title}
          nodeHeader={this.table_header}
          links={this.links}
          linksHeader={this.linksHeader}
          showEdgeStatus={this.getSettings(true).showEdgeStatus}
        />
      </div>
	  </>
    );
  }
}
