// @ts-nocheck
import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Chrono } from 'react-chrono';
import { Modal, View } from '@material-ui/core';
import Select, { components } from 'react-select';
import { Icon } from '@grafana/ui';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { TrapManagerOptions, trapData, traplineItems, optionsData } from 'types';
import { moment } from 'moment';
import { changeUrl, getNetMonitorVariableValue } from './utils';
import './css/TrapManagerPanel.css';
import { config, SystemJS } from '@grafana/runtime';
import { contextSrv } from 'app/core/services/context_srv';
import axios from 'axios';
import { AppEvents } from '@grafana/data';

interface Props extends PanelProps<TrapManagerOptions> {}

export const TrapManagerPanel: React.FC<Props> = React.memo(({ options, data, width, height, replaceVariables, id }) => {
  const [initialCalculationsDone, setInitialCalculationsDone] = useState(false);
  const moment = require('moment');
  const renderCount = useRef(0);

  const [error1, setError1 ] = useState('');
  const [error2, setError2 ] = useState('');
  const [error3, setError3 ] = useState('');
  const [error4, setError4 ] = useState('');
  const [pluginTitle, setPluginTitle] = useState('');
  const [agentFilterValue, setAgentFilterValue] = useState(options.filterDefaultValue);
  const [severityFilterValue, setSeverityFilterValue] = useState(options.filterDefaultValue);
  const [trapFilterValue, setTrapFilterValue] = useState(options.filterDefaultValue);
  const [readFilterValue, setReadFilterValue] = useState(options.filterDefaultValue);
  const [statusFilterValue, setStatusFilterValue] = useState(options.filterDefaultValue);
  const [agentOptionsEnable, setAgentOptionsEnable] = useState(false);
  const [severityOptionsEnable, setSeverityOptionsEnable] = useState(false);
  const [trapOptionsEnable, setTrapOptionsEnable] = useState(false);
  const [readOptionsEnable, setReadOptionsEnable] = useState(false);
  const [statusOptionsEnable, setStatusOptionsEnable] = useState(false);
  const [trap_link, setTrap_link] = useState('');
  const [trapTitle_link, setTrapTitle_link] = useState('');

  if (!initialCalculationsDone) {
    renderCount.current = renderCount.current + 1;
	setError1(replaceVariables(options.error1));
    setError2(replaceVariables(options.error2));
    setError3(replaceVariables(options.error3));
    setError4(replaceVariables(options.error4));
    setPluginTitle(replaceVariables(options.pluginTitle));

	if (options.agentVariable !== undefined && options.agentVariable !== null && options.agentVariable !== '') {
	  setAgentFilterValue(getNetMonitorVariableValue(options.agentVariable));
	  setAgentOptionsEnable(true);
	}
	if (options.severityVariable !== undefined && options.severityVariable !== null && options.severityVariable !== '') {
	  setSeverityFilterValue(getNetMonitorVariableValue(options.severityVariable));
	  setSeverityOptionsEnable(true);
	}
	if (options.trapVariable !== undefined && options.trapVariable !== null && options.trapVariable !== '') {
	  setTrapFilterValue(getNetMonitorVariableValue(options.trapVariable));
	  setTrapOptionsEnable(true);
	}
	if (options.trapReadVariable !== undefined && options.trapReadVariable !== null && options.trapReadVariable !== '') {
	  let unreadValue = getNetMonitorVariableValue(options.trapReadVariable);
	  if (unreadValue === 'false' || unreadValue === 'true' || unreadValue === 'saved') {
	    setReadFilterValue(unreadValue);
	  }
	  setReadOptionsEnable(true);
	}
	setTrap_link(replaceVariables(options.drillDownLink));
	setTrapTitle_link(replaceVariables(options.drillDownLinkTitle));
	setInitialCalculationsDone(true);
  }

  if (width < 150 || height < 75) {
    return (
	  <div className="trapErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="trapErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }

  var legendPosition = String(options.legendPosition);
  if (legendPosition === 'VERTICAL_ALTERNATING' && width < options.trapMinWidth * 2.5) {
    legendPosition = 'VERTICAL';
  }

  const isDark = config.theme.isDark; 
  const backgroundColor = isDark ? 'black' : 'white';
  const titleColor = isDark ? 'white' : 'black';
  const textColor = isDark ? '#EFF4FA' : '#141618';
  const primary = isDark ? '#D9AF27' : '#FF7F27';
  const secondary = isDark ? '#E5CD6B' : '#FFC530';
  const trapBgColor = isDark ? '#141618' : '#F4F9FF';
  const traptitle = isDark ? 'traptitle_dark' : 'traptitle';

  const severityOptions = [
    { icon: '', value: 'Todos', label: 'Todos' },
    { icon: 'emergency', value: 'emergency', label: 'Emergencia' },
    { icon: 'alert', value: 'alert', label: 'Alerta' },
    { icon: 'critical', value: 'critical', label: 'Critico' },
    { icon: 'error', value: 'error', label: 'Error' },
    { icon: 'warning', value: 'warning', label: 'Advertencia' },
    { icon: 'notice', value: 'notice', label: 'Aviso' },
    { icon: 'informational', value: 'informational', label: 'Informativo' },
    { icon: 'debug', value: 'debug', label: 'Depurativo' }
  ]

  const readOptions = [
    { icon: '', value: 'Todos', label: 'Todos' },
    { icon: 'eye', value: 'true', label: 'Leidos' },
    { icon: 'eye-slash', value: 'false', label: 'No Leidos' },
    { icon: 'save', value: 'saved', label: 'Guardados' }
  ]
  const allOption: optionsData = {
    value: 'Todos',
    label: 'Todos',
    icon: '',
  };

  const readValueToSet = readOptions.find(options => {
    return options.value === readFilterValue;
  });
  const severityValueToSet = severityOptions.find(options => {
    return options.value === severityFilterValue;
  });

  var defaultReadOption: optionsData = readValueToSet !== null ? readValueToSet : allOption;
  var defaultSeverityOption: optionsData = severityValueToSet !== null ? severityValueToSet : allOption;
  const [selectedReadValue, setSelectedReadValue] = useState([]);
  const [selectedSeverityValue, setSelectedSeverityValue] = useState([]);
  const [agentFilter, setAgentFilter] = useState(agentFilterValue);
  const [trapFilter, setTrapFilter] = useState(trapFilterValue);
  const [trapFilterChange, setTrapFilterChange] = useState(false);
  const [trapAgentChange, setTrapAgentChange] = useState(false);

  if (renderCount.current === 1 && initialCalculationsDone) {
	if (selectedReadValue !== defaultReadOption) {
	  setSelectedReadValue(defaultReadOption);
	}
	if (selectedSeverityValue !== defaultSeverityOption) {  
	  setSelectedSeverityValue(defaultSeverityOption);
	}
	if (agentFilterValue !== agentFilter) {
	  setAgentFilter(agentFilterValue);
	  setTrapAgentChange(true);
	} else if (agentFilter === options.filterDefaultValue) {
	  setAgentFilter('');
	  setTrapAgentChange(false);
	}
	if (trapFilterValue !== trapFilter) {
	  setTrapFilter(trapFilterValue);
	  setTrapFilterChange(true);
	} else if (trapFilter === options.filterDefaultValue) {
	  setTrapFilter('');
	  setTrapFilterChange(false);
	}
	renderCount.current = renderCount.current + 1;
  }

  const onAgentChange = (event) => {
    setAgentFilter(event.target.value);
  };

  const onTrapChange = (event) => {
    setTrapFilter(event.target.value);
  };

  const changeAgent = (event) => {
    if (event.key === 'Enter' && agentOptionsEnable) {
	  const queryMap = {
		[`var-${options.agentVariable}`]: agentFilter,
	  };
	  changeUrl(queryMap);
	  if (agentFilter !== options.filterDefaultValue && agentFilter !== '') { 
	    setTrapAgentChange(true);
	  } else {
	    setTrapAgentChange(false);
	  }
    }
  };

  const changeTrap = (event) => {
    if (event.key === 'Enter' && trapOptionsEnable) {
	  const queryMap = {
		[`var-${options.trapVariable}`]: trapFilter,
	  };
	  changeUrl(queryMap);
	  if (trapFilter !== options.filterDefaultValue && trapFilter !== '') { 
	    setTrapFilterChange(true);
	  } else {
	    setTrapFilterChange(false);
	  }
    }
  };

  // ----------------------- BASE DATA ACQUISITION -----------------------
  const generateChart = useMemo(() => {
    const timeEvents: trapData[] = [];
    const timeItems: traplineItems[] = [];
    if (initialCalculationsDone) {
      data.series.forEach((series) => {
	    const frame: GraphSeriesValue[] = series.fields[0].values.toArray();
	    const dataLen = frame.length;
	    for (let i = 0; i < dataLen; i++) {
	      let trapEvent: trapData = {
		    id: i,
		    idx: series.fields.find(field => field.name === options.idxField)?.values.get(i),
		    counter: series.fields.find(field => field.name === options.counterField)?.values.get(i),
		    timest: series.fields.find(field => field.name === options.dateField)?.values.get(i),
		    title: '',
		    trapTitle: series.fields.find((field) => field.name === options.trapTitleField)?.values.get(i),
		    trapSubtitle: series.fields.find((field) => field.name === options.trapSubtitleField)?.values.get(i),
		    trapDetailedText: '',
		    severity: series.fields.find((field) => field.name === options.severityField)?.values.get(i),
		    agent: series.fields.find((field) => field.name === options.agentField)?.values.get(i),
		    firstTimest: series.fields.find((field) => field.name === options.firstTrapField)?.values.get(i),
		    firstTrap: '',
		    isRead: false,
		    readUser: '-',
		    readDate: '-',
		    isSaved: false,
		    url: trap_link,
		    agentUrl: '',
		    trapTitleUrl: trapTitle_link,
		    color: 'blue',
	      };
	      trapEvent.trapSubtitle = trapEvent.trapSubtitle.replace(/;/g, '\n');
	      trapEvent.title = moment.unix(trapEvent.timest).format('DD-MM-YYYY HH:mm:ss');
	      trapEvent.firstTrap = moment.unix(trapEvent.firstTimest).format('DD-MM-YYYY HH:mm:ss');
	      if (options.agentUrlField && options.agentUrlField !== undefined && options.agentUrlField !== '') {
		    trapEvent.agentUrl = series.fields.find((field) => field.name === options.agentUrlField).values.get(i);
	      }
	      if (options.usetrapDetailedText && options.trapDetailedTextField !== undefined && options.trapDetailedTextField !== '') {
		    trapEvent.trapDetailedText = series.fields
		      .find((field) => field.name === options.trapDetailedTextField).values.get(i);
	      }
	      if (options.isReadField !== undefined && options.isReadField !== '') {
		    let read = series.fields.find((field) => field.name === options.isReadField)?.values.get(i);
		    if (read === 'true' || read === true) {
		      trapEvent.isRead = true;
		    }
		    if (read) {
		      const readUser = series.fields.find((field) => field.name === options.userReadField)?.values.get(i);
		      const readDate = series.fields.find((field) => field.name === options.dateReadField)?.values.get(i);
		      trapEvent.readUser = readUser ? readUser : '-';
		      trapEvent.readDate = readDate ? moment.unix(readDate).format('DD-MM-YYYY HH:mm:ss') : '-';
		    }
	      }
	      if (options.isSavedField !== undefined && options.isSavedField !== '') {
		    let saved = series.fields.find((field) => field.name === options.isSavedField)?.values.get(i);
		    if (saved === 'true' || saved === true) {
		      trapEvent.isSaved = true;
		    }
	      }
	      if (options.useUrl && options.urlField !== undefined && options.urlField !== '') {
		    if (trap_link !== '/') {
		      trapEvent.url =
			    trap_link + series.fields.find((field) => field.name === options.urlField).values.get(i);
		    } else {
		      trapEvent.url = series.fields.find((field) => field.name === options.urlField).values.get(i);
		    }
	      }
	      if (trapEvent.severity === 'Emergency' || trapEvent.severity === 'Alert' || trapEvent.severity === 'Critical') {
		    trapEvent.color = 'red';
	      } else if (trapEvent.severity === 'Error' || trapEvent.severity === 'Warning') {
		    trapEvent.color = 'orange';
	      }
	      if (typeof trapEvent.idx === undefined || trapEvent.idx === null) {
		    trapEvent.idx = i;
	      }
	      if (typeof trapEvent.severity === undefined || trapEvent.severity === null) {
		    trapEvent.severity = 'normal';
	      }
	      if (typeof trapEvent.agent === undefined || trapEvent.agent === null) {
		    trapEvent.agent = 'NetMonitor';
	      }
	      let timeItem: traplineItems = {
	 	    id: i,
		    idx: trapEvent.idx,
		    timest: trapEvent.timest,
		    title: trapEvent.title,
		    trapTitle: trapEvent.trapTitle,
		    agent: trapEvent.agent,
	      };
	      timeEvents.push(trapEvent);
	      timeItems.push(timeItem);
	    }
      });
	}

    if (timeEvents.length < 1) {
	  const trapDate = new Date().getTime();
	  let timeItem: timelineItems = {
	    id: 1,
	    idx: 0,
	    timest: '',
	    title: moment.unix(trapDate).format('DD-MM-YYYY HH:mm:ss'),
	    trapTitle: 'No existen registros para mostrar',
	    agent: agentFilter,
	  };
	  let timelineEvent: timelineData = {
	    id: 1,
	    idx: 0,
	    counter: 1,
	    timest: '',
	    title: moment.unix(trapDate).format('DD-MM-YYYY HH:mm:ss'),
	    trapTitle: 'Error - No existen registros para mostrar',
	    trapSubtitle: 'No existen registros para mostrar',
	    trapDetailedText: '',
	    severity: 'Error',
	    agent: agentFilter,
	    firstTimest: trapDate,
	    firstTrap: '',
	    isRead: false,
	    readUser: '-',
	    readDate: '-',
	    isSaved: false,
	    url: '',
	    agentUrl: '',
	    trapTitleUrl: '',
	    color: 'blue',
	  };
	  timeItems.push(timeItem);
	  timeEvents.push(timelineEvent);
	  let queryMap = {
		[`var-${options.selectTrapVariable}`]: '',
		[`var-${options.selectAgentVariable}`]: '',
	  };
	  changeUrl(queryMap);
    }
	return { timeEvents, timeItems };
  }, [data, options, agentFilter, initialCalculationsDone]);

  // ----------------------- STYLES -----------------------
  var dimensions = {
    width: width,
    height: height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 10,
    marginLeft: 0,
    cardWidth: width - 150,
  };
  if (options.showToolbar && !options.showTitle) {
    dimensions.height = height - 30;
  } else if (options.showToolbar && options.showTitle) {
    dimensions.height = height - 70;
  } else if (!options.showToolbar && options.showTitle) {
    dimensions.height = height - 40;
  }
  const fontSizes = width > 750 ? {
    trapSubtitle: String(options.trapFontSize - 1) + 'px',
    trapText: String(options.trapFontSize - 2) + 'px',
    trapTitle: String(options.trapFontSize) + 'px',
    title: String(options.trapFontSize - 1) + 'px',
  } : {
    trapSubtitle: String(options.trapFontSize - 3) + 'px',
    trapText: String(options.trapFontSize) + 'px',
    trapTitle: String(options.trapFontSize - 2) + 'px',
    title: String(options.trapFontSize - 3) + 'px',
  };
  const filterBox = isDark ? 'filterBox_dark' : 'filterBox';
  const toolbarBox = isDark ? 'trapToolbar text_dark' : 'trapToolbar text_light';
  const showMore = options.useReadMore;
  const showCardInfo = options.showCardInfo;
  const trapSubtitle = 'trapSubtitle';
  const trapContainer = isDark ? 'trapManagerContainer_dark' : 'trapManagerContainer';
  const moreInfoContainerId = 'trapline-moreInfoContainer-' + String(id);
  const confirmId = 'trapline-confirm-' + String(id);
  const moreInfoId = 'trapline-moreInfo-' + String(id);
  const confirmInfoId = 'trapline-confirmInfo-' + String(id);
  const moreTitleId = 'trapline-moreInfoTitle-' + String(id);
  const confirmTitleId = 'trapline-confirmTitle-' + String(id);
  const moreCardTitleId = 'trapline-moreInfoCardTitle-' + String(id);
  const moreSubTitleId = 'trapline-moreInfoSubTitle-' + String(id);
  const moreInfoTextId = 'trapline-moreInfoText-' + String(id);
  const moreInfoUrlId = 'trapline-moreInfoUrl-' + String(id);
  const moreAgentId = 'trapline-moreInfoAgent-' + String(id);
  const moreInfoFirstTrap = 'trapline-moreInfoFirstTrap-' + String(id);
  const moreInfoQty = 'trapline-moreInfoQty-' + String(id);
  const moreInfoSeverityId = 'trapline-moreInfoSeverity-' + String(id);
  const moreInfoTypeId = 'trapline-moreInfoType-' + String(id);
  const moreReadStatus = 'trapline-moreReadStatus-' + String(id);
  const moreUserStatus = 'trapline-moreUserStatus-' + String(id);
  const moreDateStatus = 'trapline-moreDateStatus-' + String(id);
  if (legendPosition === 'VERTICAL_ALTERNATING') {
    dimensions.cardWidth = (width / 2) - 75;
  }

  let classSelect = 'trap-select-box';
  var customStyles = {
	menu: provided => ({
	  ...provided,
	  zIndex: 9999,
	  color: '#23282E',
	  background: '#EFF4FA',
	  border: 'none',
	  borderRadius: '10px',
	  marginTop: '5px',
	  marginBottom: '0px',
	}),
	option: (base, state) => ({
	  ...base,
	  height: '30px',
	  marginTop: '0px',
	  marginBottom: '0px',
	  fontSize: '12px',
	  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
	  background: state.isSelected ? '#FFC530' : '#EFF4FA',
	  '&:hover': {
		color: 'black',
		background: '#FF7F27',
	  },
	}),
	singleValue: base => ({
	  ...base,
	  color: "#23282E"
	}),
	control: base => ({
	  ...base,
	  border: 0,
	  boxShadow: 'none',
	}),
	indicatorContainer: provided => ({
	  ...provided,
	  paddingLeft: '10px',
	}),
	menuPortal: provided => ({
	  ...provided,
	  zIndex: 9999,
	}),
  };

  if (isDark) {
	classSelect = 'trap-select-box_dark';
	customStyles = {
	  menu: provided => ({
		...provided,
		zIndex: 9999,
		color: '#F4F9FF',
		background: '#141618',
		border: 'none',
		borderRadius: '10px',
		marginTop: '5px',
		marginBottom: '0px',
		paddingTop: '0px',
		paddingBottom: '0px',
	  }),
	  option: (base, state) => ({
		...base,
		height: '30px',
		marginTop: '0px',
		marginBottom: '0px',
		fontSize: '12px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		borderRadius: '10px',
		background: state.isSelected ? '#E5CD6B' : '#161F29',
		'&:hover': {
		  color: 'white',
		  background: '#D9AF27',
		},
	  }),
	  singleValue: base => ({
		...base,
		color: "#EFF4FA"
	  }),
	  control: base => ({
		...base,
		border: 0,
		boxShadow: 'none',
	  }),
	  indicatorContainer: provided => ({
		...provided,
		paddingLeft: '10px',
	  }),
	  menuPortal: provided => ({
		...provided,
		zIndex: 9999,
	  }),
	};
  }

  const DropdownIndicator = (props: ElementConfig<typeof components.DropdownIndicator>) => {
	return (
	  <components.DropdownIndicator {...props}>
		<Icon name="angle-down" size="xl" />
	  </components.DropdownIndicator>
	);
  };

  const NoOptionsMessage = props => {
	return (
	  <components.NoOptionsMessage {...props}>
		<span className="custom-css-class">{error2}</span> 
	  </components.NoOptionsMessage>
	);
  };

  var selectWidth = (width - 15) * 0.2 > 225 ? (width - 15) * 0.2 : 225;
  var inputWidth = (width - 15 - (2 * selectWidth)) / 2;
  if (width < 480) {
    selectWidth = 150;
	inputWidth = 150;
  }
  if (selectWidth < 150) {
    selectWidth = 150;
  }
  if (inputWidth < 150) {
    inputWidth = 150;
  }

  return (
	<div className="trapManager" id="trapManager">
	  {options.showTitle && (
		<div className={traptitle} id="trap-title">
		  <div className="traptitleText">{pluginTitle}</div>
		</div>
	  )}
	  {options.showToolbar && (
		<div className={toolbarBox}>
		  {options.showReadAllOptions && (
			<div id="trap-readAll" className="filterContainer" style={{ width: '55px' }}>
			  <Icon
				className={isDark ? 'trap-readIcon_dark' : 'trap-readIcon'}
				name="read-fill"
				size="md"
				title="Marcar todos los mensajes como leidos"
				onClick={(e) => {
				  showConfirm('Realmente desea marcar todos los mensajes como leídos?', id);
				}}
			  />
			</div>
		  )}
		  {readOptionsEnable && (
			<div className="filterContainer" style={{ width: `${selectWidth * 0.75}px` }}>
			  <span className="selectBoxText">{'Ver: '}</span>
              <Select
                instanceId={id + '1'}
                className={classSelect}
                classNamePrefix={classSelect}
                components={{ DropdownIndicator, NoOptionsMessage }}
                options={readOptions}
                value={selectedReadValue}
                isSearchable={false}
                placeholder={
                  <div className="trapPicker-icon">
                    {defaultReadOption.icon !== '' && <Icon name={defaultReadOption.icon} size="lg" />}
                    <div className="trapPicker-label">{defaultReadOption.label}</div>
                  </div>
                }
                onChange={e => {
				  if (readOptionsEnable) {
				    setSelectedReadValue(e);
					const queryMap = {
					  [`var-${options.trapReadVariable}`]: e.value,
				    };
				    changeUrl(queryMap);
				  }
                }}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                styles={customStyles}
                getOptionLabel={e => (
                  <div className="trapPicker-icon">
                    {e.icon !== ''&& <Icon name={e.icon} size="md" />}
                    <div className="trapPicker-label">{e.label}</div>
                  </div>
                )}
              />
			</div>
		  )}
		  {severityOptionsEnable && (
			<div className="filterContainer" style={{ width: `${selectWidth}px` }}>
			  <span className="selectBoxText">{options.severityTitle + ': '}</span>
              <Select
                instanceId={id + '2'}
                className={classSelect}
                classNamePrefix={classSelect}
                components={{ DropdownIndicator, NoOptionsMessage }}
                options={severityOptions}
                value={selectedSeverityValue}
                isSearchable={false}
                placeholder={
                  <div className="trapPicker-icon">
                    {defaultSeverityOption.icon !== '' && <Icon name={defaultSeverityOption.icon} size="lg" />}
                    <div className="trapPicker-label">{defaultSeverityOption.label}</div>
                  </div>
                }
                onChange={e => {
				  if (severityOptionsEnable) {
				    setSelectedSeverityValue(e);
					const queryMap = {
					  [`var-${options.severityVariable}`]: e.value,
				    };
				    changeUrl(queryMap);
				  }
                }}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                styles={customStyles}
                getOptionLabel={e => (
                  <div className="trapPicker-icon">
                    {e.icon !== ''&& <Icon name={e.icon} size="md" />}
                    <div className="trapPicker-label">{e.label}</div>
                  </div>
                )}
              />
			</div>
		  )}
		  {agentOptionsEnable && options.agentVariable  !== '' && options.agentTitle !== '' && (
			<div className="filterContainer" style={{ width: `${inputWidth}px` }}>
			  <span className="selectBoxText">{options.agentTitle + ': '}</span>
			  <input 
				id="trap-searchAgent"
				className={filterBox}
				onKeyPress={changeAgent}
				onChange={onAgentChange}
				value={agentFilter}
				placeholder={options.filterDefaultValue}
			  ></input>
			  {trapAgentChange && (
			    <Icon
				  className={'trap-filterIcon'}
				  name="trash-circle-fill"
				  size="md"
				  title="Quitar filtro"
				  onClick={(e) => {
				    if (agentOptionsEnable) {
				  	  const queryMap = {
					    [`var-${options.agentVariable}`]: options.filterDefaultValue,
					  };
					  changeUrl(queryMap);
					  setAgentFilter('');
					  setTrapAgentChange(false);
				    }
				  }}
			    />
			  )}
			</div>
		  )}
		  {trapOptionsEnable && (
			<div className="filterContainer" style={{ width: `${inputWidth}px` }}>
			  <span className="selectBoxText">{options.subtitleTitle + ': '}</span>
			  <input 
				id="trap-searchTrap"
				className={filterBox}
				onKeyPress={changeTrap}
				onChange={onTrapChange}
				value={trapFilter}
				placeholder={options.filterDefaultValue}
			  ></input>
			  {trapFilterChange && (
			    <Icon
				  className={'trap-filterIcon'}
				  name="trash-circle-fill"
				  size="md"
				  title="Quitar filtro"
				  onClick={(e) => {
				    if (trapOptionsEnable) {
					  const queryMap = {
					    [`var-${options.trapVariable}`]: options.filterDefaultValue,
					  };
					  changeUrl(queryMap);
					  setTrapFilter('');
					  setTrapFilterChange(false);
				    }
				  }}
			    />
			  )}
			</div>
		  )}
		</div>
	  )}
	  <div className={options.transparent ? 'trapContainerBasic' : trapContainer} style={{ width: '100%', height: dimensions.height }}>
		<Chrono
		  items={generateChart.timeItems}
		  mode={legendPosition}
		  cardHeight={String(options.trapMinHeight)}
		  cardWidth={String(dimensions.cardWidth)}
		  allowDynamicUpdate={true}
		  lineWidth={options.lineSize}
		  hideControls={options.hideControls}
		  scrollable={{ scrollbar: true }}
		  flipLayout={options.flipLayout}
		  timelineCircleDimension={String(options.circleDimension)}
		  onItemSelected={(item) => {
			if (data.series[0].length > 0) {
			  if (item !== undefined && item !== null) {
				if (item.idx > 0 && options.selectTrapVariable && options.selectAgentVariable) {
				  let queryMap = {
					[`var-${options.selectTrapVariable}`]: item.trapTitle,
					[`var-${options.selectAgentVariable}`]: item.agent,
				  };
				  changeUrl(queryMap);
				}
			  }
			}
		  }}
		  theme={{
		    iconBackgroundColor: backgroundColor,
			primary: primary,
			secondary: secondary,
			cardBgColor: trapBgColor,
			cardForeColor: '#9DA5B8',
			cardTitleColor: 'white',
			detailsColor: textColor,
			titleColor: titleColor,
			titleColorActive: backgroundColor,
		  }}
		  buttonTexts={{
			first: 'Primero',
			last: 'Ultimo',
			next: 'Siguiente',
			previous: 'Anterior',
		  }}
		  fontSizes={fontSizes}
		  enableOutline={false}
		  useReadMore={options.displayReadMore}
		>
		  {trapFactory(
		    generateChart.timeEvents,
			showMore,
			showCardInfo,
			id,
			options,
			agentOptionsEnable,
			severityOptionsEnable,
			trapOptionsEnable,
			setTrapFilter,
		    setTrapFilterChange,
			setAgentFilter,
			setTrapAgentChange
		  )}
		  {options.useIcon && ( 
			<div className="chrono-icons">
			  {iconFactory(
			    generateChart.timeEvents,
				options.circleDimension,
				severityOptionsEnable,
				options,
				setSelectedSeverityValue,
				severityOptions
			  )}
			</div>
		  )}
		</Chrono>
	  </div>
	  <div id={moreInfoContainerId} className="trapline-moreInfoContainer trapline-hide">
		<div id={moreInfoId} className={isDark ? 'trapline-moreInfo_dark' : 'trapline-moreInfo'}>
		  <div className="trapTitleContainer">
			<span id={moreTitleId} className={isDark ? 'trapline-moreInfoTitles_dark' : 'trapline-moreInfoTitles'}></span>
			<span id={moreCardTitleId} className={isDark ? 'trapline-moreInfoTitles_dark' : 'trapline-moreInfoTitles'}></span>
		  </div>
		  <div className="alarmline-moreDivs">
			<span className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>{'TRAP leido: '}</span>
			<span id={moreReadStatus} className={isDark ? 'alarmline-moreInfoText_dark' : 'alarmline-moreInfoText'}></span>
			<span className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>{'Por: '}</span>
			<span id={moreUserStatus} className={isDark ? 'alarmline-moreInfoText_dark' : 'alarmline-moreInfoText'}></span>
			<span className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>{'Fecha: '}</span>
			<span id={moreDateStatus} className={isDark ? 'alarmline-moreInfoText_dark' : 'alarmline-moreInfoText'}></span>
		  </div>
		  <div className="trapline-moreDivs">
			<span className={isDark ? 'trapline-moreTitles_dark' : 'trapline-moreTitles'}>{options.severityTitle + ': '}</span>
			<span id={moreInfoSeverityId} className={isDark ? 'trapline-moreInfoText_dark' : 'trapline-moreInfoText'}></span>
		  </div>
		  <div className="trapline-moreDivs">
			<span className={isDark ? 'trapline-moreTitles_dark' : 'trapline-moreTitles'}>{options.subtitleTitle + ': '}</span>
			<span id={moreSubTitleId} className={isDark ? 'trapSubtitle_dark' : 'trapSubtitle'}></span>
		  </div>
		  <div className="trapline-moreDivs">
			<span className={isDark ? 'trapline-moreTitles-small_dark' : 'trapline-moreTitles-small'}>{options.agentTitle + ': '}</span>
			<span id={moreAgentId} className={isDark ? 'trapline-moreInfoText-small_dark' : 'trapline-moreInfoText-small'}></span>
		  </div>
		  <div className="trapline-moreDivs">
			<span className={isDark ? 'trapline-moreTitles-small_dark' : 'trapline-moreTitles-small'}>{'Fecha primer Trap: '}</span>
			<span id={moreInfoFirstTrap} className={isDark ? 'trapline-moreInfoText-small_dark' : 'trapline-moreInfoText-small'}></span>
		  </div>
		  <div className="trapline-moreDivs">
			<span className={isDark ? 'trapline-moreTitles-small_dark' : 'trapline-moreTitles-small'}>{'Cantidad de Registro/s: '}</span>
			<span id={moreInfoQty} className={isDark ? 'trapline-moreInfoText-small_dark' : 'trapline-moreInfoText-small'}></span>
		  </div>
		  <div className="trapline-moreDivs-line" />
		  <div className="trapline-moreDivs">
			<div className={isDark ? 'trapline-moreTitles_dark' : 'trapline-moreTitles'}>{options.infoTitle + ': '}</div>
			<div id={moreInfoTextId} className="trapDetailedTextZoom"></div>
		  </div>
		  {options.useUrl && (
			<div className={isDark ? 'trapline-url_button_dark' : 'trapline-url_button'}>
			  <a id={moreInfoUrlId} target="_blank" style={{ color: '#fff' }} rel="nofollow noopener noreferrer">
				mas información ...
			  </a>
			</div>
		  )}
		  <button
			className={isDark ? 'trapline-close_button_dark' : 'trapline-close_button'}
			onClick={(e) => {
			  hideMoreInfo(e, id);
			}}
		  >
			cerrar
		  </button>
		</div>
	  </div>
	  <div id={confirmId} className="trapline-moreInfoContainer trapline-hide">
		<div id={confirmInfoId} className={isDark ? 'trapline-confirm_dark' : 'trapline-confirm'}>
		  <div className="trapline-moreDivs">
			<div id={confirmTitleId} className={isDark ? 'trapline-moreTitles_dark' : 'trapline-moreTitles'}>
			  Realmente desea marcar todos los mensajes como leídos?
			</div>
		  </div>
		  <button
			className={isDark ? 'trapline-ok_button_dark' : 'trapline-ok_button'}
			onClick={(e) => {
			  checkAllAsRead(generateChart.timeEvents, options.api);
			  hideConfirm(e, id);
			}}
		  >
			Aceptar
		  </button>
		  <button
			className={isDark ? 'trapline-cancel_button_dark' : 'trapline-cancel_button'}
			onClick={(e) => {
			  hideConfirm(e, id);
			}}
		  >
			Cancelar
		  </button>
		</div>
	  </div>
	</div>
  );
});

function changeSeverity(value, severityVariable) {
  if (severityVariable !== undefined && severityVariable !== null && severityVariable !== '') {
    const queryMap = {
      [`var-${severityVariable}`]: value,
    };
    changeUrl(queryMap);
  }
}

function iconFactory(
  icons: trapData[],
  circleDimension: number,
  severityOptionsEnable: boolean,
  options,
  setSelectedSeverityValue,
  severityOptions
) {
  const isDark = config.theme.isDark;
  const iconBackground = isDark ? 'icon_background_dark' : 'icon_background';

  let iconSize = 'xs';
  if (circleDimension > 18) {
    iconSize = 'sm';
  } else if (circleDimension > 20) {
    iconSize = 'md';
  } else if (circleDimension > 24) {
    iconSize = 'lg';
  } else if (circleDimension > 28) {
    iconSize = 'xl';
  }

  return icons.map((trapEvent) => (
    <div className={iconBackground} key={trapEvent.id}>
      <Icon
        className={'color_' + trapEvent.color}
        name={trapEvent.severity.toLowerCase()}
        size={iconSize}
        title={severityOptionsEnable ? 'Aplicar como filtro' : trapEvent.severity}
        onClick={(e) => {
          if (severityOptionsEnable) {
			const severityValueToSet = severityOptions.find(options => {
			  return options.value === trapEvent.severity;
			});
			if (severityValueToSet) {
			  setSelectedSeverityValue(severityValueToSet);
			}
            const queryMap = {
              [`var-${options.severityVariable}`]: trapEvent.severity,
            };
            changeUrl(queryMap);
          }
        }}
      />
    </div>
  ));
}

function save(trapEvent, api, type) {
  const userName = contextSrv.user.name;
  var apiUrl = api + '/insert_trap_toggle_read';
  var trap = '{"params" : "' + String(trapEvent.isRead) + '|' + String(trapEvent.idx);
  trap = trap + '|' + String(trapEvent.agentUrl) + '|' + String(trapEvent.timest) + '|' + userName + '"}';
  if (type === 'save') {
    trap = '{"params" : "' + String(trapEvent.isSaved) + '|' + String(trapEvent.idx);
    trap = trap + '|' + String(trapEvent.agentUrl) + '| ' + String(trapEvent.timest) + '"}';
    apiUrl = api + '/insert_trap_toggle_store';
  }

  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.post(apiUrl, trap).then(
    response => {
      if (response.statusText !== 'OK') {
        SystemJS.load('app/core/app_events').then((appEvents: any) => {
        });
      }
    },
    error => {
      SystemJS.load('app/core/app_events').then((appEvents: any) => {
        console.log('Se produjo un error');
      });
    }
  );
}

function checkAllAsRead(trapEvents, api) {
  const userName = contextSrv.user.name;
  const moment = require('moment');
  trapEvents.forEach((trapEvent, index) => {
    if (trapEvent.isRead === false) {
      trapEvent.isRead = true;
	  trapEvent.readUser = userName;
	  const alarmDate = new Date().getTime();
	  trapEvent.readDate = moment.unix(alarmDate).format('DD-MM-YYYY HH:mm:ss'); 
      let iconId = '#icon_uncheck_' + String(trapEvent.id);
      $(iconId).removeClass('show_icon');
      $(iconId).addClass('hide_icon');
      iconId = '#icon_check_' + String(trapEvent.id);
      $(iconId).removeClass('hide_icon');
      $(iconId).addClass('check_icon show_icon');
      save(trapEvent, api, 'check');
    }
  });
}

function showConfirm(title, id) {
  let divContainerId = '#trapline-confirm-' + String(id);
  let divTitleId = '#trapline-confirmTitle-' + String(id);

  $(divContainerId).removeClass('trapline-hide');
  $(divTitleId).text(title);
}

function showMoreInfo(e, trapEvent, id, options) {
  let divContainerId = '#trapline-moreInfoContainer-' + String(id);
  let divInfoId = '#trapline-moreInfo-' + String(id);
  let divTitleId = '#trapline-moreInfoTitle-' + String(id);
  let divCardTitleId = '#trapline-moreInfoCardTitle-' + String(id);
  let divSubTitleId = '#trapline-moreInfoSubTitle-' + String(id);
  let divInfoTextId = '#trapline-moreInfoText-' + String(id);
  let divAgentId = '#trapline-moreInfoAgent-' + String(id);
  let divFirstTrapId = '#trapline-moreInfoFirstTrap-' + String(id);
  let divQtyId = '#trapline-moreInfoQty-' + String(id);
  let divInfoSeverityId = '#trapline-moreInfoSeverity-' + String(id);
  let divInfoTypeId = '#trapline-moreInfoType-' + String(id);
  let divInfoUrlId = '#trapline-moreInfoUrl-' + String(id);
  let divReadStatus = '#trapline-moreReadStatus-' + String(id);
  let divUserStatus = '#trapline-moreUserStatus-' + String(id);
  let divDateStatus = '#trapline-moreDateStatus-' + String(id);

  $(divContainerId).removeClass('trapline-hide');
  $(divTitleId).text(trapEvent.title);
  $(divCardTitleId).text(trapEvent.trapTitle);
  $(divSubTitleId).text(trapEvent.trapSubtitle);
  $(divInfoTextId).text(trapEvent.trapDetailedText);
  $(divAgentId).text(trapEvent.agent);
  $(divFirstTrapId).text(trapEvent.firstTrap);
  $(divQtyId).text(trapEvent.counter);
  $(divInfoSeverityId).text(trapEvent.severity);
  $(divInfoUrlId).attr('href', trapEvent.url);
  const readValue = trapEvent.isRead ? 'Sí' : 'No'; 
  const userValue = trapEvent.readUser ? trapEvent.readUser : '-'; 
  const dateValue = trapEvent.readDate ? trapEvent.readDate : '-'; 
  $(divReadStatus).text(readValue);
  $(divUserStatus).text(userValue);
  $(divDateStatus).text(dateValue);
}

function handleUrl(url, target, update) {
  let urlTarget = '_blank';
  if (target === '_self' || update) {
    urlTarget = '_self';
  }
  if (url !== null && url !== '') {
    window.open(url, urlTarget);
  }
}

function hideMoreInfo(e, id) {
  let divID = '#trapline-moreInfoContainer-' + String(id);
  $(divID).addClass('trapline-hide');
}

function hideConfirm(e, id) {
  let divID = '#trapline-confirm-' + String(id);
  $(divID).addClass('trapline-hide');
}

function trapFactory(
  traps: trapData[],
  showMore,
  showCardInfo,
  id,
  options,
  agentOptionsEnable,
  severityOptionsEnable,
  trapOptionsEnable,
  setTrapFilter,
  setTrapFilterChange,
  setAgentFilter,
  setTrapAgentChange
) {
  const userName = contextSrv.user.name;
  const moment = require('moment');
  const api = options.api;
  const isDark = config.theme.isDark;
  const agent_link = options.drillDownLinkAgent !== '' ? options.drillDownLinkAgent : '/d/assets/activos';
  const trapTitle = isDark ? 'trapTitle_dark' : 'trapTitle';
  const trapSubtitle = isDark ? 'trapSubtitle_dark' : 'trapSubtitle';
  const trapDetailedText = isDark ? 'trapDetailedText_dark' : 'trapDetailedText';
  const agent = isDark ? 'trapline-agent_dark' : 'trapline-agent';
  const trapTheme = isDark ? '_dark' : '';

  return traps.map((trapEvent) => (
    <div key={trapEvent.id} className="trapContainerBasic">
      <div className="trapTitleContainer">
        <span 
          className={'trapline-trapTitle'}
          title={options.severityTitle + ': ' + trapEvent.severity}
        >
          <button
            className={trapTitle + ' trapline-' + trapEvent.severity + trapTheme + 'color_' + trapEvent.color}
            onClick={(e) => {
              if (trapOptionsEnable && options.showToolbar) {
                const queryMap = {
                  [`var-${options.trapVariable}`]: trapEvent.trapTitle,
                };
                changeUrl(queryMap);
				setTrapFilter(trapEvent.trapTitle);
				setTrapFilterChange(true);
              }
              if (agentOptionsEnable && options.showToolbar) {
                const queryMap = {
                  [`var-${options.agentVariable}`]: trapEvent.agent,
                };
                changeUrl(queryMap);
				setAgentFilter(trapEvent.agent);
				setTrapAgentChange(true);
              }
            }}
            title={options.showToolbar ? 'Filtrar por tipo de TRAP y agente' : 'Trap recibido'}
          >
            {trapEvent.trapTitle}
          </button>
        </span>
        <span>
          <button
            className={isDark ? 'trapline-indicator_dark' : 'trapline-indicator'}
            title={'Traps absorvidos por este mensaje'}
          >
            {trapEvent.counter}
          </button>
        </span>
        {options.agentTitle !== '' && (
		  <span>
            <button
              className={isDark ? 'trapline-url_button_dark' : 'trapline-url_button'}
              onClick={(e) => {
                handleUrl(agent_link + trapEvent.agent, '_blank', false);
              }}
              title={'Ir al ' + options.agentTitle.toLowerCase() + ': ' + trapEvent.agent}
            >
              {trapEvent.agent}
            </button>
          </span>
		)}
        {showMore && (
          <spam>
            <button
              className={isDark ? 'trapline-button_dark' : 'trapline-button'}
              onClick={(e) => {
                showMoreInfo(e, trapEvent, id, options);
              }}
              title="Más detalles ..."
            >
              <Icon
                name="ellipsis-h"
                size="md"
              />
            </button>
          </spam>
        )}
        <span
          className={trapEvent.isRead ? 'checkBox show_icon' : 'checkBox hide_icon'}
          id={'icon_check_' + String(trapEvent.id)}
        >
          <Icon
            className="show_icon check_icon"
            name={'check-square'}
            size="md"
            title={'Trap leido'}
          />
        </span>
        <span 
          className={trapEvent.isRead ? 'checkBox hide_icon' : 'checkBox show_icon'}
          id={'icon_uncheck_' + String(trapEvent.id)}
        >
          <Icon
            className="show_icon uncheck_icon"
            name={'square'}
            size="md"
            onClick={(e) => {
              if (trapEvent.isRead === false) {
                trapEvent.isRead = true;
			    trapEvent.readUser = userName;
			    const alarmDate = new Date().getTime();
			    trapEvent.readDate = moment.unix(alarmDate).format('DD-MM-YYYY HH:mm:ss'); 
                let iconId = '#icon_uncheck_' + String(trapEvent.id);
                $(iconId).removeClass('show_icon');
                $(iconId).addClass('hide_icon');
                iconId = '#icon_check_' + String(trapEvent.id);
                $(iconId).removeClass('hide_icon');
                $(iconId).addClass('check_icon show_icon');
                save(trapEvent, api, 'check');
              }
            }}
            title={'Marcar como leido'}
          />
        </span>
        <span
          className={trapEvent.isSaved ? 'checkBox show_icon' : 'checkBox hide_icon'}
          id={'icon_save_' + String(trapEvent.id)}
        >
          <Icon
            className="show_icon check_icon"
            name={'save'}
            size="md"
            onClick={(e) => {
              if (trapEvent.isSaved === true) {
                trapEvent.isSaved = false;
			    trapEvent.readUser = userName;
			    const alarmDate = new Date().getTime();
			    trapEvent.readDate = moment.unix(alarmDate).format('DD-MM-YYYY HH:mm:ss'); 
                let iconId = '#icon_save_' + String(trapEvent.id);
                $(iconId).removeClass('show_icon');
                $(iconId).addClass('hide_icon');
                iconId = '#icon_unsave_' + String(trapEvent.id);
                $(iconId).removeClass('hide_icon');
                $(iconId).addClass('show_icon');
                save(trapEvent, api, 'save');
              }
            }}
            title={'Trap marcado para guardar indefinidamente'}
          />
        </span>
        <span
          className={trapEvent.isSaved ? 'checkBox hide_icon' : 'checkBox show_icon'}
          id={'icon_unsave_' + String(trapEvent.id)}
        >
          <Icon
            className="show_icon unselect_icon"
            name={'save'}
            size="md"
            onClick={(e) => {
              if (trapEvent.isSaved === false) {
                trapEvent.isSaved = true;
                let iconId = '#icon_save_' + String(trapEvent.id);
                $(iconId).removeClass('hide_icon');
                $(iconId).addClass('show_icon');
                iconId = '#icon_unsave_' + String(trapEvent.id);
                $(iconId).removeClass('show_icon');
                $(iconId).addClass('hide_icon');
                save(trapEvent, api, 'save');
              }
            }}
            title={'Guardar Trap'}
          />
        </span>
      </div>
      {showCardInfo && (
        <div className="trapInfoContainer">
          <div id={'trapline-wrapper' + trapEvent.id} className={trapDetailedText}>
            {trapEvent.trapDetailedText}
          </div>
        </div>
      )}
    </div>
  ));
}
