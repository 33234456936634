import React from 'react';
import { config } from '@grafana/runtime';
import { NodeStatistics } from './NodeStatistics';
import { EdgeStatistics } from './EdgeStatistics';
import '../../css/netmonitor-topology-map.css';
import './Statistics.css';
import {
  IntSelectionStatistics,
  TableContent,
  Table2Content,
  Table3Content,
  TableHeader,
  Table2Header,
  Table3Header,
} from 'types';

interface StatisticsProps {
  show: boolean;
  showEdge: boolean;
  selectionId: string | number;
  currentDescription: Table3Content[];
  sysHeader: Table3Header[];
  resolvedDrillDownLink_asset: string;
  resolvedDrillDownLink_site: string;
  selectionStatistics: IntSelectionStatistics;
  currentType: string;
  nodeTitle: string;
  edgeTitle: string;
  showBaselines: boolean;
  showEdgeStatus: boolean;
  receiving: TableContent[];
  sending: TableContent[];
  nodeHeader: TableHeader[];
  links: Table2Content[];
  linksHeader: Table2Header[];
}

export const Statistics: React.FC<StatisticsProps> = ({
  show,
  showEdge,
  selectionId,
  currentDescription,
  sysHeader,
  resolvedDrillDownLink_asset,
  resolvedDrillDownLink_site,
  selectionStatistics,
  currentType,
  showBaselines,
  receiving,
  sending,
  nodeHeader,
  nodeTitle,
  links,
  linksHeader,
  edgeTitle,
  showEdgeStatus,
}) => {
  let isDark = config.theme.isDark;
  var statisticsClass = 'statistics';
  var statistics = <div></div>;
  const header_selection = isDark ? 'header--selection--dark' : 'header--selection';
  const hheader_selection = isDark ? 'hheader--selection--dark' : 'hheader--selection';
  const description_selection = isDark ? 'description--selection--dark' : 'description--selection';
  const vheader_selection = isDark ? 'vheader--selection--dark' : 'vheader--selection';
  const table_cell = isDark ? 'table_cell--dark' : 'table_cell';
  const secondHeader_selection = isDark ? 'secondHeader--selection--dark' : 'secondHeader--selection';

  if (show) {
    statisticsClass = isDark ? 'statistics show_dark ' : 'statistics show ';
    var drilldownLink_asset = <div></div>;
    if (resolvedDrillDownLink_asset && resolvedDrillDownLink_asset.length > 0) {
      drilldownLink_asset = <a href={resolvedDrillDownLink_asset + selectionId}>{selectionId}</a>;
      statistics = (
        <div className="statistics">
          <div className={header_selection}>{drilldownLink_asset}</div>
          <div className={hheader_selection}>
            {sysHeader.map((sys_header: Table3Header) => (
              <td key={sys_header.description_header} className={vheader_selection}>
                <tr className={table_cell}>{sys_header.description_header}</tr>
                <tr className={table_cell}>{sys_header.time_header}</tr>
                <tr className={table_cell}>{sys_header.location_header}</tr>
              </td>
            ))}
            {currentDescription.map((sys_description: Table3Content) => (
              <td key={description_selection} className={description_selection}>
                <tr className={table_cell}>{sys_description.sysDescription}</tr>
                <tr className={table_cell}>{sys_description.sysTime}</tr>
                <tr className={table_cell}>{sys_description.sysLocation}</tr>
              </td>
            ))}
          </div>
          <div className={secondHeader_selection}>{nodeTitle}</div>
          <NodeStatistics
            nodeList={receiving}
            nodeList2={sending}
            noDataText="No existen relaciones topológicas definidas."
            linkAsset={resolvedDrillDownLink_asset}
            linkSite={resolvedDrillDownLink_site}
            header={nodeHeader}
          />
        </div>
      );
    } else {
      statistics = (
        <div className="statistics">
          <div className={header_selection}>{selectionId}</div>
          <div className={secondHeader_selection}>{nodeTitle}</div>
          <NodeStatistics
            nodeList={receiving}
            nodeList2={sending}
            noDataText="No existen relaciones topológicas definidas."
            linkAsset={resolvedDrillDownLink_asset}
            linkSite={resolvedDrillDownLink_site}
            header={nodeHeader}
          />
        </div>
      );
    }
  } else if (showEdge) {
    statisticsClass = isDark ? 'statistics show_dark ' : 'statistics show ';
    statistics = (
      <div className="statistics">
        <div className={header_selection}>Detalles del enlace seleccionado</div>
        <div className={secondHeader_selection}>{edgeTitle}</div>
        <EdgeStatistics
          edgeList={links}
          noDataText="No existen conexiones definidas."
          linkAsset={resolvedDrillDownLink_asset}
          linkSite={resolvedDrillDownLink_site}
          header={linksHeader}
          edgeStatus={showEdgeStatus}
        />
      </div>
    );
  }
  return <div className={statisticsClass}>{statistics}</div>;
};
