// @ts-ignore
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { PanelProps, GraphSeriesValue, UrlQueryMap } from '@grafana/data';
import { Icon } from '@grafana/ui';
import { BarChartOptions, GenericBarData } from 'types';
import {
  BarChart,
  ComposedChart,
  Bar,
  Area,
  ReferenceArea,
  CartesianGrid,
  XAxis,
  YAxis,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  Brush,
  LabelList,
  Line,
} from 'recharts';
import { config, locationService, getLocationSrv, getTemplateSrv } from '@grafana/runtime';
import styled from 'styled-components';
import './css/BarChartPanel.css';

interface Props extends PanelProps<BarChartOptions> {}

export const BarChartPanel: React.FC<Props> = React.memo(({ options, data, width, height, replaceVariables, id }) => {
  const [initialCalculationsDone, setInitialCalculationsDone] = useState(false);
  const renderCount = useRef(0);
  const [error1, setError1 ] = useState('');
  const [error2, setError2 ] = useState('');
  const [error3, setError3 ] = useState('');
  const [error4, setError4 ] = useState('');

  const [auxButtonIcon1, setAuxButtonIcon1] = useState('');
  const [auxButtonVariable1, setAuxButtonVariable1] = useState('');
  const [auxButtonValue1, setAuxButtonValue1] = useState('');
  const [auxButtonIcon2, setAuxButtonIcon2] = useState('');
  const [auxButtonVariable2, setAuxButtonVariable2] = useState('');
  const [auxButtonValue2, setAuxButtonValue2] = useState('');
  const [auxButton1Enable, setAuxButton1Enable] = useState(false);
  const [auxButton2Enable, setAuxButton2Enable] = useState(false);
  const [chartTitle, setChartTitle] = useState('');
  const [chartSubTitle, setChartSubTitle] = useState('');
  const [chartIcon, setChartIcon] = useState('');
  const [absoluteThreshold, setAbsoluteThreshold] = useState('');
  const [syncID, setSyncID] = useState(String(id));
  const [clicEnable, setClicEnable] = useState(false);
  const [realTimeLink, setRealTimeLink] = useState('');
  const [analiticLink, setAnaliticLink] = useState('');
  const [barUrl_link, setBarUrl_link] = useState('');
  const [url_link, setUrl_link] = useState('');

  useEffect(() => {
    renderCount.current = renderCount.current + 1;
    if (!initialCalculationsDone) {
	  setError1(replaceVariables(options.error1));
      setError2(replaceVariables(options.error2));
      setError3(replaceVariables(options.error3));
      setError4(replaceVariables(options.error4));

      setAuxButtonIcon1(replaceVariables(options.toolbarAuxButtonIcon1));
      setAuxButtonVariable1(replaceVariables(options.toolbarAuxButtonVariable1));
      setAuxButtonValue1(replaceVariables(options.toolbarAuxButtonValue1));
      setAuxButtonIcon2(replaceVariables(options.toolbarAuxButtonIcon2));
      setAuxButtonVariable2(replaceVariables(options.toolbarAuxButtonVariable2));
      setAuxButtonValue2(replaceVariables(options.toolbarAuxButtonValue2));
      setAuxButton1Enable((auxButtonIcon1 !== '' && auxButtonVariable1 !== '' && auxButtonValue1 !== '') ? true : false);
      setAuxButton2Enable((auxButtonIcon2 !== '' && auxButtonVariable2 !== '' && auxButtonValue2 !== '') ? true : false);
      setChartTitle(options.showTitle ? replaceVariables(options.barTitle) : '');
      setChartSubTitle(options.showTitle ? replaceVariables(options.chartSubTitle) : '');
      setChartIcon(options.showTitle ? replaceVariables(options.chartIcon) : '');
      setAbsoluteThreshold(Number(replaceVariables(options.absoluteThreshold)));
      setSyncID(options.syncId !== '' ? replaceVariables(options.syncId) : String(id));
	  setRealTimeLink(replaceVariables(options.realTimeButtonLink));
	  setAnaliticLink(replaceVariables(options.analiticButtonLink));
	  const barUrlLink = options.useBarUrl ? replaceVariables(options.drillDownLinkBar) : '';
	  const urlLink = !options.timeSeriesChart ? replaceVariables(options.drillDownLink) : '';
	  if ((barUrlLink !== null && barUrlLink.length > 0 ) || (urlLink !== null && urlLink.length > 0)) {
	    setBarUrl_link(barUrlLink);
	    setUrl_link(urlLink);
		setClicEnable(true);
	  }
	  setInitialCalculationsDone(true);
	}
  }, [options, replaceVariables]);

  if (width < 150 || height < 75) {
    return (
	  <div className="barChartErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="barChartErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.series[0].length < 1) {
    return (
	  <div className="barChartErrorContainer" title={error2}>
	    <Icon name={'image-slash'} size="xxl" />
	  </div>
	);
  }

  const wrapperWidth = useMemo(() => (width));
  const timeFrom = useMemo(() => (getTemplateSrv().replace('${__from}')));
  const timeTo = useMemo(() => (getTemplateSrv().replace('${__to}')));
  const timeInterval = Number(timeTo) - Number(timeFrom);
  var timeWindow = Number(timeInterval) / 3600000;
  var actualWin = 6;
  if (timeWindow <= 24) {
    actualWin = 1;
  } else if (timeWindow <= 168) {
    actualWin = 2;
  } else if (timeWindow <= 720) {
    actualWin = 3;
  } else if (timeWindow <= 2160) {
    actualWin = 4;
  } else if (timeWindow <= 4320) {
    actualWin = 5;
  }

  const [actualZoom, setActualZoom] = useState(actualWin);
  const [refAreaLeft, setRefAreaLeft] = useState('');
  const [refAreaRight, setRefAreaRight] = useState('');
  const [refAreaLeftLabel, setRefAreaLeftLabel] = useState(0);
  const [refAreaRightLabel, setRefAreaRightLabel] = useState(0);
  const [startZoom, setStartZoom] = useState(false);

  const stackElements: [] = [];
  const groupedChartBars: [] = [];

  let chartIconSize = options.titleFontSize * 2 > 64 ? 64 : options.titleFontSize * 2;
  let axisLineWidth = options.axisLineWidth;

  const isDark = config.theme.isDark;
  const iconColor = isDark ? 'white' : 'black';
  const buttonColor = isDark ? 'black' : 'white';
  const buttonBorder = isDark ? '#2e2e2e' : '#e5e5e5';
  const boxBorder = isDark ? '#1b2733' : '#dce1e6';
  const tickFontColor = isDark ? '#D8DFD9' : '#23282E';
  const axisLineColor = isDark ? '#585A5E' : '#9DA5B8';
  const shadowColor = isDark ? '#5A6171' : '#646A73';
  var backgroundColor = options.backgroundColor;
  var borderColor = isDark ? '#44444C' : '#D8DFE9';
  if (backgroundColor === 'transparent') {
	borderColor = 'transparent';
  } else if (backgroundColor === 'text') {
	backgroundColor = isDark ? '#9DA5B8' : '#585A5E';	
  }

  const colors = isDark ? 
    ['#F17171','#71E200','#A2ADB8','#7100FF','#FFA071','#A20471','#FF71B7','#7EA9ff','#FF0071','#B380FF'] :
	['#D64545','#45C5B0','#7D8995','#45B0E5','#E57A45','#7DB545','#E54594','#5485E5','#E58045','#9065E5'];
  const getColor = (color: number) => {
    let colorNumber = color < 0 ? 0 : color;
    if (colorNumber > colors.length) {
      colorNumber = ((color - 1) % colors.length);
    }
    return colors[colorNumber];
  }

  var showDot = false;
  var showActiveDot = false;
  if (options.orientation === 'vertical') {
	if (options.scaleTypeVertical !== 'log' && options.scaleTypeVertical !== 'ordinal') {
	  if (options.showDots === true) {
		showDot = true;
	  }
	  showActiveDot = true;
	}
  } else {
	if (options.scaleType !== 'log' && options.scaleType !== 'ordinal') {
	  if (options.showDots === true) {
		showDot = true;
	  }
	  showActiveDot = true;
	}
  }
  
  const rightMargin = !options.timeSeriesChart && options.showAxisY ? 25 : 5;
  const topMargin = !options.timeSeriesChart && options.showAxisX ? 25 : 5;
  var chartMargin = { top: 5, right: 5, left: 5, bottom: 5 };

  if (!options.timeSeriesChart && options.orientation === 'horizontal') {
	chartMargin = { top: topMargin, right: 5, left: 5, bottom: 5};
  } else if (!options.timeSeriesChart) {
	chartMargin = { top: 5, right: rightMargin, left: 5, bottom: 5};
  }

  const useNormalTooltip = (syncID !== String(id) || (wrapperWidth > 350 && height > 150)) ? true : false;
  const BarChartToolboxContainer = styled.div`
    display: inline-flex;
    width: 100%;
	height: 100%
    overflow: hidden;
  `;

  const ButtonContainer = styled.div`
    position: absolute;
    top: 5px;
    width: 32px;
	border-left: 1px solid ${boxBorder};
  `;

  const ChartContainer = styled.div`
    display: inline-flex;
	position: absolute;
	width: -webkit-fill-available;
    height: -webkit-fill-available;
  `;
	
  const ButtonNormal = styled.button`
    display: inline-flex;
    height: 32px;
    width: 28px;
	margin-left: 2px;
    background-color: transparent;
    opacity: 0.75;
    justify-content: center;
	align-items: center;
    color: ${iconColor};
    border: 1px solid transparent;
    border-radius: 10px;

    &:hover {
      opacity: 1;
	  background-image: linear-gradient(to bottom right, #e5e5e529, #b7b7b76e);
	  border: 1px solid ${buttonBorder};
    }
  `;

  const ButtonText = styled.div`
    width: 28px;
  `;

  const renderColorfulLegendText = (value: string) => {
    if (value === 'Zoom') {
	  return null;
	}
	const textColor = isDark ? '#ffffff': '#000000';
    return <span style={{color: textColor, cursor: 'pointer' }}>{value}</span>;
  };

  var showTooltip = false;
  var tooltipBar = null;
  var tooltipVisible = null;
  var tooltipTimeout;

  var axisXEndIndex = 0;
  var axisXMaxIndex = 0;
  var axisXStartIndex = 0;
  var axisLYEndIndex = options.maxValue;
  var axisLYStartIndex = options.minValue;
  var axisRYEndIndex = options.maxValue;
  var axisRYStartIndex = options.minValue;

  var toolBoxWidth = options.showTitle ? wrapperWidth * 0.5 : 0;
  if ((options.showToolbar || options.showAuxToolbar) && !options.showTitle) {
	toolBoxWidth = 32;
  } else if (options.showTitle && chartTitle !== '' && wrapperWidth >= 250) {
	toolBoxWidth = (wrapperWidth * 0.5 < 125) ? 125 : wrapperWidth * 0.5;
  }
  if (options.showTitle && chartTitle !== '' && toolBoxWidth > 300) {
    toolBoxWidth = 300;
  }

  const generateChart = useMemo(() => {
	var chartBars: GenericBarData[] = [];
    if (!initialCalculationsDone) {
	  return chartBars;
	}
    renderCount.current = renderCount.current + 1;
	const d = Date.now();
    data.series.forEach((series) => {
	  const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
	  for (let i = 0; i < timeVals.length; i++) {
	    var chartBar: GenericBarData = {
		  id: i + 1,
		  name: series.fields.find((field) => field.name === options.serieField)?.values.get(i),
		  date: new Date(d).toUTCString(),
		  dateLocale: new Date(d).toLocaleString(),
		  value: 0,
		  auxarea: 0,
		  auxline: 0,
		  stackId: String(id),
		  label: series.fields.find((field) => field.name === options.labelField)?.values.get(i),
		  color: '',
		  info: '',
		  metric: 0,
		  threshold: 0,
		  onfire: false,
	    };
	    const color = series.fields.find((field) => field.name === options.colorField)?.values.get(i);
	    if (color !== undefined && color !== null && color !== '') {
	      chartBar.color = color;
	    } else {
	      chartBar.color = getColor(chartBar.id + id)
	    }
	    const stackID = series.fields.find((field) => field.name === options.stackField)?.values.get(i);
	    if (stackID !== undefined && stackID !== null && stackID !== '') {
		  chartBar.stackId = String(stackID);
	    }
	    if (options.timeSeriesChart && options.dateField !== undefined && options.dateField !== '') {
		  let unixDate = series.fields.find((field) => field.name === options.dateField)?.values.get(i);
		  chartBar.date = new Date(unixDate).toUTCString();
		  chartBar.dateLocale = new Date(unixDate).toLocaleString();
		  if (actualWin < 3) {
		    chartBar.name = new Date(unixDate).toLocaleTimeString(options.timeZone);
		  } else {
		    chartBar.name = new Date(unixDate).toLocaleDateString(options.timeZone).replace(/\//g, '-');
		  }
	    }
	    if (options.valueField !== undefined && options.valueField !== '') {
		  const value = series.fields.find((field) => field.name === options.valueField)?.values.get(i);
		  chartBar.value = isNaN(value) ? 0 : Number(value);
	    }
	    if (options.areaField !== undefined && options.areaField !== '') {
		  const auxarea = series.fields.find((field) => field.name === options.areaField)?.values.get(i);
		  chartBar.auxarea = isNaN(auxarea) ? 0 : Number(auxarea);
	    }
	    if (options.lineField !== undefined && options.lineField !== '') {
		  const auxline = series.fields.find((field) => field.name === options.lineField)?.values.get(i);
		  chartBar.auxline = isNaN(auxline) ? 0 : Number(auxline);
	    }
	    if (options.useInfoField && options.infoField !== undefined && options.infoField !== '') {
		  chartBar.info = series.fields.find((field) => field.name === options.infoField)?.values.get(i);
	    }
	    if (options.useMetricField) {
		  if (options.metricField !== undefined && options.metricField !== '') {
		    let metric = series.fields.find((field) => field.name === options.metricField)?.values.get(i);
		    chartBar.metric = isNaN(metric) ? 0 : Number(metric);
		  }
		  if (options.metricThresholdField !== undefined && options.metricThresholdField !== '') {
		    let relativeThreshold = series.fields
			  .find((field) => field.name === options.metricThresholdField)
			  ?.values.get(i);
		    chartBar.threshold = isNaN(relativeThreshold) ? 1 : Number(relativeThreshold) / 100;
		  }
	    }
	    if (chartBar.metric > 0 && chartBar.threshold > 0) {
		  if (chartBar.value > chartBar.metric * (1 + chartBar.threshold)) {
		    chartBar.onfire = true;
		  }
	    }
	    if (absoluteThreshold > 0 && chartBar.value > absoluteThreshold) {
		  chartBar.onfire = true;
	    }
	    if (options.invertThreshold) {
		  chartBar.onfire = !chartBar.onfire;
	    }
	    chartBars.push(chartBar);
	  }
    });
    return chartBars;
  }, [options, data, initialCalculationsDone]);

  if (generateChart.length > 0) {
    var idx = 0;
    var idxStack = 1;
    generateChart.forEach(chartBar => {
	  var existingItem = null;
	  if (options.timeSeriesChart) {
	    existingItem = groupedChartBars !== null ? groupedChartBars.find(item => item.dateLocale === chartBar.dateLocale) : null;
	  } else {
	    existingItem = groupedChartBars !== null ? groupedChartBars.find(item => item.name === chartBar.name) : null;
	  }
	  let hasValue = false;
      if (existingItem) {
		if (!existingItem[`${chartBar.stackId}`]) {
		  existingItem.barQty = existingItem.barQty + 1;
		}
		existingItem[`color_${chartBar.stackId}`] = chartBar.onfire ? options.warningColor : chartBar.color;
        existingItem[`${chartBar.stackId}`] = chartBar.value;
	    existingItem[`metric_${chartBar.stackId}`] = chartBar.metric;
	    existingItem[`onfire_${chartBar.stackId}`] = chartBar.onfire;
	    existingItem[`label_${chartBar.stackId}`] = chartBar.label;
        existingItem.maxmetric = existingItem.maxmetric < chartBar.metric ? chartBar.metric : existingItem.maxmetric;
	    if (options.stackValues) {
	      existingItem.totalvalue = existingItem.totalvalue + chartBar.value;
	    } else if (chartBar.value > existingItem.totalvalue) {
	      existingItem.totalvalue = chartBar.value;
	    }
	    if (options.stackValues && options.accumulate) {
	      existingItem.auxarea = existingItem.auxarea + chartBar.auxarea;
	      existingItem.auxline = existingItem.auxline + chartBar.auxline;
	    }
	    if (existingItem.totalvalue !== 0) {
	      hasValue = true;
	    }
      } else {
        const newItem = {
		  id: idx,
		  name: chartBar.name,
		  date: chartBar.date,
		  dateLocale: chartBar.dateLocale,
		  auxarea: chartBar.auxarea,
		  auxline: chartBar.auxline,
		  info: chartBar.info,
		  maxmetric: chartBar.metric,
		  threshold: chartBar.threshold,
		  totalvalue: chartBar.value,
		  barQty: 1,
          [`color_${chartBar.stackId}`]: chartBar.onfire ? options.warningColor : chartBar.color,
          [`${chartBar.stackId}`]: chartBar.value,
          [`metric_${chartBar.stackId}`]: chartBar.metric,
          [`onfire_${chartBar.stackId}`]: chartBar.onfire,
          [`label_${chartBar.stackId}`]: chartBar.label,
        };
	    if (chartBar.value !== 0) {
	      hasValue = true;
	    }
	    idx = idx + 1;
	    axisXMaxIndex = idx;
	    groupedChartBars.push(newItem); 
      }

	  const existingStackId = stackElements !== null ? stackElements.find(item => item.stackId === chartBar.stackId) : null;
	  if (existingStackId) {
		existingStackId.hasValue = hasValue;
	  } else {
	    const newStackId = {
		  id: idxStack,
		  name: chartBar.name,
		  stackId: chartBar.stackId,
		  color: chartBar.color,
	      lineWidth: options.timeSeriesChart ? 0 : options.lineWidth,
	      borderColor: options.timeSeriesChart ? 'transparent' : borderColor,
	      barGap: Number(options.barMargin),
		  barQty: 1,
		  barWidth: 1,
		  barRadius: [0,0,0,0],
		  hasValue: hasValue,
	    }
	    idxStack = idxStack + 1;
	    stackElements.push(newStackId); 
	  }
    });

    let minValueL = 0;
    let maxValueL = 0;
    let minValueR = 0;
    let maxValueR = 0;
    if (options.maxValue === 0 || options.maxValue === undefined || options.maxValue === null) {
	  groupedChartBars.forEach(groupedChartBar => {
	    const minLeft = groupedChartBar.totalvalue > groupedChartBar.maxmetric ? groupedChartBar.maxmetric : groupedChartBar.totalvalue;
	    const maxLeft = groupedChartBar.totalvalue > groupedChartBar.maxmetric ? groupedChartBar.totalvalue : groupedChartBar.maxmetric;
	    if (maxLeft > maxValueL) {
		  maxValueL = maxLeft;
	    }
	    if (minLeft < minValueL) {
		  minValueL = minLeft;
	    }
	    const minRight = groupedChartBar.auxline > groupedChartBar.auxarea ? groupedChartBar.auxarea : groupedChartBar.auxline;
	    const maxRight = groupedChartBar.auxline > groupedChartBar.auxarea ? groupedChartBar.auxline : groupedChartBar.auxarea;
	    if (maxRight > maxValueR) {
	      maxValueR = maxRight;
	    }
	    if (minRight < minValueR) {
	      minValueR = minRight;
	    }
	  });
	  axisLYEndIndex = options.showLabels && options.showTotals ? maxValueL * 1.2 : maxValueL * 1.1;
	  axisRYEndIndex = options.showLabels && options.showTotals ? maxValueR * 1.2 : maxValueR * 1.1;
    }

    if (options.minValue === 0 || options.minValue === undefined || options.minValue === null) {
      axisLYStartIndex = minValueL >= 0 ? 0 : minValueL * 1.1;
	  axisRYStartIndex = minValueR >= 0 ? 0 : minValueR * 1.1;
    }

    if (axisLYEndIndex === 0) {
      axisLYEndIndex = 1;
    }
    if (axisRYEndIndex === 0) {
      axisRYEndIndex = 1;
    }

    axisLYEndIndex = axisLYEndIndex > 1.2 ? Math.ceil(axisLYEndIndex) : Math.ceil(axisLYEndIndex * 100) / 100;
    axisRYEndIndex = axisRYEndIndex > 1.2 ? Math.ceil(axisRYEndIndex) : Math.ceil(axisRYEndIndex * 100) / 100;

    if (options.timeSeriesChart && options.endIndex > 0) {
	  axisXEndIndex = options.endIndex;
    } else {
	  axisXEndIndex = axisXMaxIndex;
    }
    if (options.timeSeriesChart && options.startIndex > 0) {
	  axisXStartIndex = axisXEndIndex - options.startIndex;
    }
  }

  const legendCage = options.showLegend ? 25 : 5;
  const chartBarWidth = options.showAxisY ? wrapperWidth - toolBoxWidth - 50 : wrapperWidth - toolBoxWidth;
  const chartBarHeight = options.showAxisX ? height - legendCage - 25 : height - legendCage;

  let barWidth = options.orientation === 'horizontal' ? 
	Math.floor(chartBarWidth / groupedChartBars.length) - options.barMargin : 
	Math.floor(chartBarHeight / groupedChartBars.length) - options.barMargin;
  barWidth = options.maxBarSize > 0 && (options.maxBarSize - options.barMargin) < barWidth ? 
    options.maxBarSize - options.barMargin :
	barWidth;
  const barGap = Number(options.barMargin) > (barWidth / 5) ? Math.floor(barWidth / 5) : Number(options.barMargin);

  var radius = [0, 0, 0, 0]; /* [top-left, top-right, bottom-right, bottom-left] */
  var barRadius = (barWidth - (barGap * 4)) > 20 ? 5 : (Math.floor((barWidth - (barGap * 4)) / 2)) / 2;
  if (barWidth > 40) {
	barRadius = barRadius * 2;
  }
  if (barRadius < 0) {
    barRadius = 0;
  }

  if (stackElements.length > 1 && stackElements.length !== groupedChartBars.length ) {  /*check si no es una serie unica */
    stackElements.forEach(stackElement => {
      radius = [0, 0, 0, 0];
	  if (options.stackValues) {
	    if (!options.timeSeriesChart && barWidth > 20) {
          if (options.orientation === 'horizontal') {
            if (stackElement.id === 1) {
	          if (axisLineWidth === 0) {
                radius = [0, 0, barRadius, barRadius];
              }
		    } else if (stackElement.id === stackElements.length) {
		      radius = [barRadius, barRadius, 0, 0];
		    }
		  } else {
            if (stackElement.id === 1) {
	          if (axisLineWidth === 0) {
                radius = [barRadius, 0, 0, barRadius];
              }
		    } else if (stackElement.id === stackElements.length) {
		      radius = [0, barRadius, barRadius, 0];
		    }
		  }
		}
	  } else {
	    if (!options.timeSeriesChart && barWidth > 20) {
	      radius = [barRadius, barRadius, barRadius, barRadius];
          if (options.orientation === 'horizontal') {
			if (axisLineWidth > 0) {
              radius = [barRadius, barRadius, 0, 0];
            }
		  } else if (axisLineWidth > 0) {
            radius = [0, barRadius, barRadius, 0];
		  }
		}
	  }
	  const existingBar = groupedChartBars.find(item => item.name === stackElement.name)
	  const barQty = existingBar ? existingBar.barQty : 1;
	  stackElement.barQty = barQty;
	  stackElement.barWidth = barWidth;
	  stackElement.barGap = barGap;
	  stackElement.barRadius = stackElement.hasValue ? radius : [0,0,0,0];
	});
  } else {
    stackElements.forEach(stackElement => {
      radius = [barRadius, barRadius, barRadius, barRadius];
	  if (!options.timeSeriesChart) {
	    if (options.orientation === 'horizontal' && barWidth > 20) {
	      if (axisLineWidth > 0) {
            radius = [barRadius, barRadius, 0, 0];
          }
	    } else if (options.orientation === 'vertical' && axisLineWidth > 0) {
          radius = [0, barRadius, barRadius, 0];
	    }
	  } else {
	    radius = [0, 0, 0, 0];
	  }
	  stackElement.barWidth = barWidth;
	  stackElement.barGap = barGap;
	  stackElement.barRadius = stackElement.hasValue ? radius : [0,0,0,0];
	});
  }

  const [barProps, setBarProps] = useState({ hover: null, selected: null });
  const selectBar = (e) => {
    setBarProps({
      ...barProps,
      hover: null,
      selected: barProps.selected !== e.dataKey ? e.dataKey : null
    });
  };

  const renderCustomizedLabel = (props) => {
	const { content, value, ...rest } = props;
	return (
	  <Label {...rest} 
		fontSize={String(options.fontSize)} 
		fill={isDark ? '#fff' : '#000'} 
		strokeWidth={0} 
		stroke={isDark ? '#fff' : '#000'} 
	  >
		{isNaN(value) ? value : Math.round(value) + ' ' + options.metricUnit}
	  </Label>
	);
  };

  const ChartBarShape = ({ fill, x, y, width, height, value, radius }) => {
	if (isNaN(x) || isNaN(y) || isNaN(width) || isNaN(height)) {
	  return null;
	}
	const barwidth = (options.orientation === 'horizontal' && width > 7) ? width - 2 : width;
	const barHeight = (options.orientation === 'vertical' && height > 7) ? height - 2 : height;
	var [tl, tr, br, bl] = radius;
	var top = barwidth - tl - tr;
	var right = barHeight - tr - br;
	var bottom = barwidth - br - bl;
	var left = barHeight - bl - tl;
	if (top <= 0 || right <= 0 || bottom <= 0 || left <= 0) {
	  tl = tl > 2 ? 2 : 0,
	  tr = tr > 2 ? 2 : 0,
	  br = br > 2 ? 2 : 0,
	  bl = bl > 2 ? 2 : 0,
	  top = barwidth - tl - tr;
	  right = barHeight - tr - br;
	  bottom = barwidth - br - bl;
	  left = barHeight - bl - tl;
	  if (top <= 0 || right <= 0 || bottom <= 0 || left <= 0) {
	    tl = 0,
	    tr = 0,
	    br = 0,
	    bl = 0,
	    top = barwidth - tl - tr;
	    right = barHeight - tr - br;
	    bottom = barwidth - br - bl;
	    left = barHeight - bl - tl;
	  }
	}
	const hasRadiusBottom = (options.orientation === 'horizontal' && br > 0 && bl) > 0 ? true : false;
	const hasRadiusLeft = (options.orientation === 'vertical' && tl > 0 && bl) > 0 ? true : false;
	const shadowColor = isDark ? '#5A6171' : '#646A73';
	const bottomShadow = hasRadiusLeft ? 0 : 1;
	const rightShadow = hasRadiusBottom ? 1 : 0;

	if (top >= 0 && right >= 0 && bottom >= 0 && left >= 0) {
	  const d = `
		M${x + tl},${y}
		h${top}
		a${tr},${tr} 0 0 1 ${tr},${tr}
		v${right}
		a${br},${br} 0 0 1 -${br},${br}
		h-${bottom}
		a${bl},${bl} 0 0 1 -${bl},-${bl}
		v-${left}
		a${tl},${tl} 0 0 1 ${tl},-${tl}
		z
	  `;
	  return (
	    <>
		  <path 
		    d={d}
			stroke={fill}
		    fill={fill}
			filter={`drop-shadow(${bottomShadow}px ${rightShadow}px 5px ${shadowColor})`}
		  />
	    </>
	  );
	} else {
	  return null;
	}
  };

  const ChartBarNormal = ({ fill, x, y, width, height, value, radius }) => {
	if (isNaN(x) || isNaN(y) || isNaN(width) || isNaN(height)) {
	  return null;
	}
	const [tl, tr, br, bl] = radius;
	const rectWidth = width > 0 ? width : 0;
	const rectRadius = Math.max(tl, tr, br, bl);
	
	return (
	  <>
		<g fill={fill} stroke={fill}>
		  <rect
			x={x || 0}
			y={y || 0}
			rx={rectRadius}
			width={rectWidth}
			height={height || 0}
			stroke={fill}
		  />
		</g>
	  </>
	);
  };

  const barFactory = (timeSeries: boolean, layout: string, clic: boolean, min: number) => {
	if (!stackElements) {
      return null;
    }
	if (stackElements && layout === 'horizontal') {
	  return stackElements.map((entry, i) => (
	    <Bar
		  yAxisId={'mainline'}
		  dataKey={stackElements[i].stackId}
		  name={stackElements[i].stackId}
		  unit={options.metricUnit}
		  fill={stackElements[i].color}
		  hide={barProps.selected && barProps.selected !== `${stackElements[i].stackId}`}
		  barSize={timeSeries ? 
		    stackElements[i].barWidth - stackElements[i].barGap : 
			(barWidth / stackElements[i].barQty) - stackElements[i].barGap
		  }
		  minPointSize={min}
		  background={
		    (!options.stackValues || (i === 0 && backgroundColor !== '' && backgroundColor !== 'transparent')) ? 
		      {fill: backgroundColor, stroke: "transparent", radius: (stackElements[i].barWidth > 20 && stackElements[i].barGap > 0 && !timeSeries) ? 10 : 0} :
			  null
		  }
		  shape={
		    ((barWidth / stackElements[i].barQty) > 20 && stackElements[i].barGap > 0 && !timeSeries) ? 
		    <ChartBarShape /> :
		    <ChartBarNormal />
		  }
		  stackId={options.stackValues ? id : stackElements[i].id}
		  isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
		  animationDuration={500}
		  onClick={(bar) => {
		    if (!timeSeries && clic) {
		      handleClick(barUrl_link + bar.name, '_self');
		      showTooltip = false;
		    }
		  }}
		  onMouseEnter={(bar) => {
		    if (options.showTooltip && !useNormalTooltip) {
		      showTooltip = true;
			  tooltipBar = bar.tooltipPayload;
		    }
		  }}
		  onMouseOut={(e) => {
		    if (options.showTooltip && !useNormalTooltip) {
		      showTooltip = false;
			  tooltipBar = null;
		    }
		  }}
	    >
		  {cellFactoryStacked(
		    groupedChartBars,
		    stackElements[i].barRadius,
		    stackElements[i].stackId,
		    stackElements[i].borderColor,
		    stackElements[i].lineWidth,
		    stackElements[i].barGap,
		    barWidth / stackElements[i].barQty,
		    timeSeries
		  )}
	      {options.stackValues && options.showLabels && !options.showTotals && (
		    <LabelList 
		      dataKey={stackElements[i].stackId} 
			  position={'inside'}
			  content={renderCustomizedLabel}
		    />
	      )}
	      {options.stackValues && options.showLabels && options.showTotals && stackElements[i].id === stackElements.length && (
		    <LabelList 
		      dataKey={'totalvalue'} 
			  position={'top'}
			  content={renderCustomizedLabel}
		    />
	      )}
	      {!options.stackValues && options.showLabels && (
		    <LabelList 
		      dataKey={stackElements[i].stackId} 
			  position={'top'}
			  content={renderCustomizedLabel}
		    />
	      )}
	    </Bar>
	  ));
    } else if (stackElements) {
	  return stackElements.map((entry, i) => (
	    <Bar
		  xAxisId={'mainline'}
		  dataKey={stackElements[i].stackId}
		  name={stackElements[i].stackId}
		  unit={options.metricUnit}
		  fill={stackElements[i].color}
		  hide={barProps.selected && barProps.selected !== `${stackElements[i].stackId}`}
		  barSize={timeSeries ? 
		    stackElements[i].barWidth - stackElements[i].barGap : 
			(barWidth / stackElements[i].barQty) - stackElements[i].barGap
		  }
		  minPointSize={min}
		  background={
		    (!options.stackValues || (i === 0 && backgroundColor !== '' && backgroundColor !== 'transparent')) ? 
		      {fill: backgroundColor, stroke: "transparent", radius: (stackElements[i].barWidth > 20 && stackElements[i].barGap > 0) ? 10 : 0} :
			  null
		  }
		  shape={
		    ((barWidth / stackElements[i].barQty) > 20 && stackElements[i].barGap > 0) ? 
		    <ChartBarShape /> :
		    <ChartBarNormal />
		  }
		  stackId={options.stackValues ? id : stackElements[i].id}
		  isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
		  animationDuration={500}
		  onClick={(bar) => {
		    if (clic) {
		      handleClick(barUrl_link + bar.name, '_self');
		      showTooltip = false;
		    }
		  }}
		  onMouseEnter={(bar) => {
		    if (options.showTooltip && !useNormalTooltip) {
		      showTooltip = true;
			  tooltipBar = bar.tooltipPayload;
		    }
		  }}
		  onMouseOut={(e) => {
		    if (options.showTooltip && !useNormalTooltip) {
		      showTooltip = false;
			  tooltipBar = null;
		    }
		  }}
	    >
		  {cellFactoryStacked(
		    groupedChartBars,
		    stackElements[i].barRadius,
		    stackElements[i].stackId,
		    stackElements[i].borderColor,
		    stackElements[i].lineWidth,
		    stackElements[i].barGap,
		    barWidth / stackElements[i].barQty,
		    timeSeries
		  )}
	      {options.stackValues && options.showLabels && !options.showTotals && (
		    <LabelList
		      dataKey={stackElements[i].stackId}
			  position={'inside'}
			  content={renderCustomizedLabel}
		    />
		  )}
	      {options.stackValues && options.showLabels && options.showTotals && stackElements[i].id === stackElements.length && (
		    <LabelList
		      dataKey={'totalvalue'}
			  position={'right'}
			  content={renderCustomizedLabel}
		    />
		  )}
	      {!options.stackValues && options.showLabels && (
		    <LabelList
		      dataKey={stackElements[i].stackId}
			  position={'right'}
			  content={renderCustomizedLabel}
		    />
		  )}
	    </Bar>
	  ));
    }
  };

  const NormalTooltip = ({ payload, label }: any) => {
	const toolTipLabel = CustomTooltip(label, showTooltip, payload, options, clicEnable);
	return toolTipLabel;
  };

  const showTooltipChart = (e, data) => {
	if (e && data) {
	  let x = data.pageX;
	  if (data.view.innerWidth !== undefined) {
	    x = data.pageX > data.view.innerWidth - 160 ? data.view.innerWidth - 160 : data.pageX;
	  }
      const y = data.pageY;
	  if (tooltipVisible && e.activeLabel === tooltipVisible) {
	    $('#panelTooltip').css({
          'left': x + 10 + 'px',
          'top': y + 'px',
          'visibility': 'visible',
        });
	  } else{
	    const toolTipLabel = renderToStaticMarkup(CustomTooltip(tooltipBar.name, showTooltip, tooltipBar, options, clicEnable));
        $('#panelTooltip').html(toolTipLabel);
        $('#panelTooltip').addClass('barChartTooltip');
	    $('#panelTooltip').css({
          'left': x + 10 + 'px',
          'top': y + 'px',
          'visibility': 'visible',
        });
	    if (e.activeLabel) {
	      tooltipVisible = e.activeLabel;
	    }
	  }
	  clearTimeout(tooltipTimeout);
	  tooltipTimeout = setTimeout(() => {
        hideTooltipChart();
      }, 2000);
	} else {
	  tooltipVisible = null;
	}
  };

  const hideTooltipChart = (e) => {
    clearTimeout(tooltipTimeout);
    $('#panelTooltip').html('');
    $('#panelTooltip').removeClass('barChartTooltip');
    $('#panelTooltip').css({
      'visibility': 'hidden' 
    });
	tooltipVisible = null;
  };

  const CustomCursor = props => {
    const { x, y, width, height, fill } = props;
	
	if (isNaN(x) || isNaN(y) || isNaN(width) || isNaN(height)) {
	  return null;
	}
	const rectWidth = width > 0 ? width : 0;
	const maxRadius = Math.max(width, height);
	const radius = (maxRadius / 4) > 10 ? 10 : maxRadius / 4;
	return (
	  <>
		<g fill={fill} opacity={0.15}>
		  <rect
			x={options.orientation === 'horizontal' ? (x + (barWidth / 2) - 1) : (x - 2)}
			y={options.orientation === 'vertical' ? (y + (barWidth / 2) - 1) : (y - 2)}
			rx={radius}
			width={rectWidth}
			height={height + 2}
			stroke={isDark ? '#646A73' : '#9DA5B8'}
		  />
		</g>
	  </>
	);
  };

  let titleIn = 'Zoom In';
  let titleOut = 'Zoom Out';
  if (actualZoom === 1) {
	titleIn = 'No es posible reducir ventana de tiempo';
	titleOut = 'Ampliar ventana de tiempo a ultimas 24hs';
  } else if (actualZoom === 2) {
	titleIn = 'Reducir ventana de tiempo a ultimas 3hs';
	titleOut = 'Ampliar ventana de tiempo a ultima semana';
  } else if (actualZoom === 3) {
	titleIn = 'Reducir ventana de tiempo a ultimas 24hs';
	titleOut = 'Ampliar ventana de tiempo a ultimo mes';
  } else if (actualZoom === 4) {
	titleIn = 'Reducir ventana de tiempo a ultima semana';
	titleOut = 'Ampliar ventana de tiempo a ultimo trimetre';
  } else if (actualZoom === 5) {
	titleIn = 'Reducir ventana de tiempo a ultimo mes';
	titleOut = 'Ampliar ventana de tiempo a ultimo semestre';
  } else if (actualZoom === 6) {
	titleIn = 'Reducir ventana de tiempo a ultimo trimetre';
	titleOut = 'Ampliar ventana de tiempo a ultimo año';
  } else if (actualZoom === 7) {
	titleIn = 'Reducir ventana de tiempo a ultimo semestre';
	titleOut = 'No es posible ampliar ventana de tiempo';
  }

  const chartHeight = !options.stackValues && options.maxBarSize > 0 ? (stackElements.length * (options.maxBarSize + barGap)) + 10 : height;

  if (!options.timeSeriesChart && options.orientation === 'vertical') {
	return (
	  <BarChartToolboxContainer id={id}>
		{options.showAuxToolbar && (auxButton1Enable || auxButton2Enable) && !options.showTitle && (
		  <ButtonContainer>
			{auxButton1Enable && (
			  <ButtonNormal
				title={options.toolbarAuxButtonTitle1}
				onClick={() => {
				  handleAuxClick(auxButtonVariable1, auxButtonValue1);
				}}
			  >
				<ButtonText><Icon name={auxButtonIcon1} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
			{auxButton2Enable && (
			  <ButtonNormal
				title={options.toolbarAuxButtonTitle2}
				onClick={() => {
				  handleAuxClick(auxButtonVariable2, auxButtonValue2);
				}}
			  >
				<ButtonText><Icon name={auxButtonIcon2} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
		  </ButtonContainer>
		)}
		{options.showTitle && chartTitle !== '' && wrapperWidth >= 250 && (
		  <button
            style={{
              fontSize: options.titleFontSize,
			  width: toolBoxWidth,
			  height: height - 10,
			  marginTop: 5,
            }}
            className={'barChart_button'}
			onClick={() => {
			  handleClick(url_link, '_self');
			}}
            title={chartTitle}
          >
		    <div 
			  className={'barChartIndicator'}
			  style={ wrapperWidth > 480 ? 
			    {
			      width: toolBoxWidth,
			      height: height - 10,
			    } : {
			      height: height - 10,
			    }
			  }
		    >
			  {chartIcon !== '' && (
			    <div 
				  className={'icon_barChart'}
			      style={{
				    color: options.iconColor,
					width: chartIconSize + 20,
			      }}
				>
				  <Icon name={chartIcon} size={chartIconSize + 10} />
			    </div>
			  )}
			  <div className={'barChart_label'}>
			    <b>{chartTitle}</b>
			    <div
			      className={'barChart_sublabel'}
			      style={{
				    fontColor: iconColor,
					fontSize: options.fontSize,
			      }}
			    >
			      {chartSubTitle}
			    </div>
			  </div>
		    </div>
          </button>
		)}
		<ChartContainer style={{ left: toolBoxWidth + 5, top: 0 }}>
		  <ResponsiveContainer width={wrapperWidth - toolBoxWidth - 5} height={chartHeight > height ? height : chartHeight}>
			<BarChart
		      style={{ width: '100%', height: '100%' }}
			  width={wrapperWidth - toolBoxWidth}
			  height={chartHeight > height ? height : chartHeight}
			  data={groupedChartBars}
			  layout={'vertical'}
			  syncId={syncID}
			  barCategoryGap={options.barMargin / 2}
			  barGap={barGap}
			  margin={chartMargin}
			  onClick={(e) => {
				if (clicEnable && !options.useBarUrl) {
				  handleClick(url_link, '_self');
				}
			  }}
			  onMouseMove={(event, data) => {
				if (showTooltip && !useNormalTooltip) {
				  showTooltipChart(event, data);
				}
			  }}
              onMouseLeave={(e) => {
			    if (showTooltip && !useNormalTooltip) {
				  hideTooltipChart(event);
				}
			  }}
			>
			  {stackElements.map(stack => (
			    <XAxis
				  xAxisId="mainline"
				  dataKey={stack.stackId}
				  type="number"
				  scale={options.scaleTypeVertical}
				  allowDecimals={axisLYEndIndex > 1 ? false : true}
				  tick={{ fontSize: options.fontSize, fill: tickFontColor }}
				  interval="preserveEnd"
				  tickMargin={5}
				  hide={!options.showAxisX}
				  domain={options.scaleTypeVertical === 'log' ? ['auto', 'auto'] : [axisLYStartIndex, axisLYEndIndex]}
				  axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				  tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(-2, 0)' }}
			    />
			  ))}
			  <YAxis
				dataKey="name"
				type="category"
				scale={'band'}
				tick={{ fontSize: options.fontSize, fill: tickFontColor, transform: `translate(-2, ${barWidth / 2})` }}
				interval={stackElements.length > 1 ? 'preserveStartEnd' : 0}
				tickMargin={5}
				tickSize={5}
				hide={!options.showAxisY}
				axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				tickLine={{ stroke: 'orange', strokeWidth: 1, transform: `translate(-2, ${barWidth / 2})` }}
			  />
			  {barFactory(false, 'vertical', clicEnable, axisLYEndIndex / 100)}
			  {options.showTooltip && useNormalTooltip && (
			    <Tooltip 
				  content={<NormalTooltip />}
				  isAnimationActive={false} 
				  wrapperStyle={{ visibility: 'visible' }}
				  cursor={<CustomCursor />}
				/>
			  )}
			  {options.useCartesianGrid && <CartesianGrid strokeDasharray="3 3" horizontal={false} />}
			  {!options.showTitle && options.showLegend && stackElements.length > 1 && (
				<Legend
				  wrapperStyle={{ fontSize: options.fontSize, left: 0, bottom: 5 }}
				  formatter={renderColorfulLegendText}
				  iconSize={options.fontSize + 5}
				  iconType={'circle'}
				  onClick={selectBar}
				/>
			  )}
			</BarChart>
		  </ResponsiveContainer>
		</ChartContainer>
	  </BarChartToolboxContainer>
	);
  } else if (!options.timeSeriesChart && options.orientation === 'horizontal') {
	return (
	  <BarChartToolboxContainer id={id}>
		{options.showAuxToolbar && (auxButton1Enable || auxButton2Enable) && !options.showTitle && (
		  <ButtonContainer>
			{auxButton1Enable && (
			  <ButtonNormal
				title={options.toolbarAuxButtonTitle1}
				onClick={() => {
				  handleAuxClick(auxButtonVariable1, auxButtonValue1);
				}}
			  >
				<ButtonText><Icon name={auxButtonIcon1} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
			{auxButton2Enable && (
			  <ButtonNormal
				title={options.toolbarAuxButtonTitle2}
				onClick={() => {
				  handleAuxClick(auxButtonVariable2, auxButtonValue2);
				}}
			  >
				<ButtonText><Icon name={auxButtonIcon2} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
		  </ButtonContainer>
		)}
		{options.showTitle && chartTitle !== '' && wrapperWidth >= 250 && (
		  <button
            style={{
              fontSize: options.titleFontSize,
			  width: toolBoxWidth,
			  height: height - 10,
			  marginTop: 5,
            }}
            className={'barChart_button'}
			onClick={() => {
			  handleClick(url_link, '_self');
			}}
            title={chartTitle}
          >
		    <div 
			  className={'barChartIndicator'}
			  style={{
			    width: toolBoxWidth,
			    height: height - 10,
			  }}
		    >
			  {chartIcon !== '' && (
			    <div 
				  className={'icon_barChart'}
			      style={{
				    color: options.iconColor,
					width: chartIconSize + 20,
			      }}
				>
				  <Icon name={chartIcon} size={chartIconSize + 10} />
			    </div>
			  )}
			  <div className={'barChart_label'}>
			    <b>{chartTitle}</b>
			    <div
			      className={'barChart_sublabel'}
			      style={{
				    fontColor: iconColor,
					fontSize: options.fontSize,
			      }}
			    >
			      {chartSubTitle}
			    </div>
			  </div>
		    </div>
          </button>
		)}
		<ChartContainer style={{ left: toolBoxWidth + 5, top: 0 }}>
		  <ResponsiveContainer width={wrapperWidth - toolBoxWidth - 5} height={height}>
			<ComposedChart
			  style={{ width: '100%', height: '100%' }}
			  width={wrapperWidth - toolBoxWidth}
			  height={height}
			  data={groupedChartBars}
			  layout={'horizontal'}
			  barCategoryGap={options.barMargin / 2}
			  barGap={barGap}
			  syncId={syncID}
			  margin={chartMargin}
			  onClick={(e) => {
				if (clicEnable && !options.useBarUrl) {
				  handleClick(url_link, '_self');
				}
			  }}
			  onMouseMove={(event, data) => {
				if (showTooltip && !useNormalTooltip) {
				  showTooltipChart(event, data);
				}
			  }}
              onMouseLeave={(event) => {
			    if (showTooltip && !useNormalTooltip) {
				  hideTooltipChart(event);
				}
			  }}
			>
			  <defs>
				<linearGradient id={'auxAreaColor_horizontal_' + id} x1="0" y1="0" x2="0" y2="1">
				  <stop offset="0%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityStart} />
				  <stop offset="95%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityEnd} />
				</linearGradient>
			  </defs>
			  <XAxis
				dataKey="name"
				type="category"
				scale={'band'}
				height={20}
				tick={{ fontSize: options.fontSize, fill: tickFontColor, transform: `translate(${barWidth / 2}, 2)` }}
				interval="preserveStartEnd"
				tickMargin={5}
				tickSize={5}
				hide={!options.showAxisX}
				axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				tickLine={{ stroke: 'orange', strokeWidth: 1, transform: `translate(${barWidth / 2}, 2)` }}
			  />
			  {stackElements.map(stack => (
			    <YAxis
				  yAxisId="mainline"
				  dataKey={stack.stackId}
				  type="number"
				  orientation="left"
				  allowDecimals={axisLYEndIndex > 1 ? false : true}
				  scale={options.scaleType}
				  tick={{ fontSize: options.fontSize, fill: tickFontColor }}
				  interval="preserveEnd"
				  hide={!options.showAxisY}
				  domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisLYStartIndex, axisLYEndIndex]}
				  axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				  tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
			    />
			  ))}
			  {options.showLineOnChart && (
				<YAxis
				  yAxisId="auxline"
				  dataKey="auxline"
				  type="number"
				  orientation="right"
				  allowDecimals={axisRYEndIndex > 1 ? false : true}
				  scale={options.scaleType}
				  tick={{ fontSize: options.fontSize, fill: tickFontColor }}
				  tickFormatter={DataFormater}
				  interval="preserveEnd"
				  hide={!options.showAxisY}
				  domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisRYStartIndex, axisRYEndIndex]}
				  axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				  tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
				/>
			  )}
			  {options.showAreaOnChart && !options.showLineOnChart && (
				<YAxis
				  yAxisId="auxline"
				  dataKey="auxarea"
				  type="number"
				  orientation="right"
				  allowDecimals={axisRYEndIndex > 1 ? false : true}
				  scale={options.scaleType}
				  tick={{ fontSize: options.fontSize, fill: tickFontColor }}
				  tickFormatter={DataFormater}
				  interval="preserveEnd"
				  hide={!options.showAxisY}
				  domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisRYStartIndex, axisRYEndIndex]}
				  axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				  tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
				/>
			  )}
			  {barFactory(false, 'horizontal', clicEnable, axisLYEndIndex / 100)}
			  {options.showAreaOnChart && (
				<Area
				  yAxisId={options.sameYAxis ? 'mainline' : 'auxline'}
				  type={options.lineType}
				  name={options.auxAreaTitle}
				  unit={options.auxAreaUnit}
				  dataKey="auxarea"
				  fill={'url(#auxAreaColor_horizontal_' + id + ')'}
				  stroke={options.auxAreaColor}
				  strokeWidth={options.lineWidth}
				  hide={barProps[options.auxAreaTitle] === true}
				  activeDot={showActiveDot === true ? true : false}
				  dot={showDot ? {
				    stroke: options.auxAreaColor, 
					strokeWidth: options.lineWidth,
					fill: backgroundColor
				  } : false}
				  isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
				  animationDuration={500}
				/>
			  )}
			  {options.showLineOnChart && (
				<Line
				  yAxisId={options.sameYAxis ? 'mainline' : 'auxline'}
				  type={options.lineType}
				  name={options.auxLineTitle}
				  unit={options.auxLineUnit}
				  dataKey="auxline"
				  stroke={options.auxLineColor}
				  strokeWidth={options.lineWidth}
				  hide={barProps[options.auxLineTitle] === true}
				  activeDot={showActiveDot === true ? true : false}
				  dot={showDot ? {
				    stroke: options.auxLineColor, 
					strokeWidth: options.lineWidth,
					fill: backgroundColor
				  } : false}
				  style={{ filter: `drop-shadow(0px 2px 3px ${options.auxLineColor})` }}
				  isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
				  animationDuration={500}
				/>
			  )}
			  {options.useCartesianGrid && <CartesianGrid strokeDasharray="3 3" vertical={false} />}
			  {options.showTooltip && useNormalTooltip && (
			    <Tooltip 
				  content={<NormalTooltip />}
				  isAnimationActive={false} 
				  wrapperStyle={{ visibility: 'visible' }}
				  cursor={<CustomCursor />}
				/>
			  )}
			  {!options.showTitle && options.showLegend && stackElements.length > 1 && (
				<Legend
				  wrapperStyle={{ fontSize: options.fontSize, left: 0, top: 10 }}
				  formatter={renderColorfulLegendText}
				  iconSize={options.fontSize + 5}
				  iconType={'circle'}
				  verticalAlign={'top'}
				  margin={{ top: 5, left: 0, right: 0, bottom: 10 }}
				  onClick={selectBar}
				/>
			  )}
			</ComposedChart>
		  </ResponsiveContainer>
		</ChartContainer>
	  </BarChartToolboxContainer>
	);
  } else if (options.timeSeriesChart) {
	return (
	  <BarChartToolboxContainer id={id}>
		{options.showToolbar && (
		  <ButtonContainer>
			{options.showRealTimeButton && (
			  <ButtonNormal
				title={options.realTimeTitle}
				onClick={() => {
				  handleClick(realTimeLink, '_blank');
				}}
			  >
				<ButtonText><Icon name={'waveform-circle-fill'} size="xl" /></ButtonText>
			  </ButtonNormal>
			)}
			{options.showAnaliticButton && (
			  <ButtonNormal
				title={options.analiticButtonTitle}
				onClick={() => {
				  handleClick(analiticLink, '_blank');
				}}
			  >
				<ButtonText><Icon name={'brain'} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
			{options.showTimeZoomButtons && (
			  <ButtonNormal
				title={titleIn}
				onClick={() => {
				  if (actualZoom > 1) {
					zoomTime(actualZoom - 1);
					setActualZoom(actualZoom - 1);
				  }
				}}
			  >
				<ButtonText><Icon name={'minus-circle-fill'} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
			{options.showTimeZoomButtons && (
			  <ButtonNormal
				title={titleOut}
				onClick={() => {
				  if (actualZoom < 7) {
					zoomTime(actualZoom + 1);
					setActualZoom(actualZoom + 1);
				  }
				}}
			  >
				<ButtonText><Icon name={'plus-circle-fill'} size="lg" /></ButtonText>
			  </ButtonNormal>
			)}
		  </ButtonContainer>
		)}
		{groupedChartBars.length > 0 && (
		  <ChartContainer style={{ left: toolBoxWidth + 5, top: 0 }}>
		    <ResponsiveContainer width={wrapperWidth - toolBoxWidth - 5} height="95%">
			  <ComposedChart
			    style={{ width: '100%', height: '100%' }}
			    width={wrapperWidth - toolBoxWidth}
			    height={height}
			    data={groupedChartBars}
			    layout={options.orientation}
			    barCategoryGap={options.barMargin}
			    barGap={barGap}
			    syncId={syncID}
			    margin={chartMargin}
			    onMouseDown={(e) => {
				  if (options.allowZoom && !startZoom) {
				    setRefAreaLeft(e.activePayload[0].payload.date);
				    setRefAreaLeftLabel(e.activeLabel);
				    setRefAreaRightLabel(e.activeLabel);
				    setStartZoom(true);
				  }
			    }}
			    onMouseMove={(e, data) => {
				  if (options.allowZoom && startZoom) {
				    if ((Math.abs(refAreaLeftLabel - e.activeLabel) / (axisXEndIndex - axisXStartIndex)) > 0.025) {
				      setRefAreaRightLabel(e.activeLabel);
				    }
				  }
				  if (showTooltip && !useNormalTooltip) {
				    showTooltipChart(e, data);
				  }
			    }}
			    onMouseUp={(e) => {
			  	  if (options.allowZoom && startZoom && refAreaLeftLabel > 0 && refAreaRightLabel > 0) {
				    zoom(refAreaLeft, e.activePayload[0].payload.date);
				    setRefAreaLeftLabel(0);
				    setStartZoom(false);
				  } else if (startZoom) {
				    setStartZoom(false);
				  }
			    }}
                onMouseLeave={(e) => {
			      if (showTooltip && !useNormalTooltip) {
				    hideTooltipChart(e);
				  }
			    }}
			  >
			    <defs>
				  <linearGradient id={'auxAreaColor_horizontal_' + id} x1="0" y1="0" x2="0" y2="1">
				    <stop offset="0%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityStart} />
				    <stop offset="95%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityEnd} />
				  </linearGradient>
			    </defs>
			    {!options.useZoom && (
				  <XAxis dataKey="id" type="number" hide={true} domain={[axisXStartIndex, axisXEndIndex - 1]} />
			    )}
			    {options.useZoom && (
				  <XAxis dataKey="id" type="number" hide={true} />
			    )}
			    {options.showLegend && stackElements.length > 1 && (
				  <Legend
				    wrapperStyle={{ fontSize: options.fontSize, left: 0, top: 5 }}
				    formatter={renderColorfulLegendText}
				    iconSize={options.fontSize + 5}
				    iconType={'circle'}
				    verticalAlign={'top'}
				    margin={{ top: 10, left: 0, right: 0, bottom: 5 }}
				    onClick={selectBar}
				  />
			    )}
			    <XAxis
				  xAxisId="date"
				  dataKey="name"
				  type="category"
				  scale={'band'}
				  tick={{ fontSize: options.fontSize, fill: tickFontColor, transform: 'translate(0, 2)' }}
				  interval="preserveStartEnd"
				  ticks={groupedChartBars.length > 40 ?
				    [groupedChartBars[axisXStartIndex + 10].name, groupedChartBars[axisXEndIndex - 10].name] :
				    [groupedChartBars[axisXStartIndex + 1].name, groupedChartBars[axisXEndIndex - 1].name]
				  }
				  hide={!options.showAxisX}
				  axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				  tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
			    />
			    {stackElements.map(stack => (
			      <YAxis
				    yAxisId="mainline"
				    dataKey={stack.stackId}
				    type="number"
				    orientation="left"
				    allowDecimals={axisLYEndIndex >= 1 ? false : true}
				     scale={options.scaleType}
				    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
				    tickFormatter={DataFormater}
				    interval="preserveEnd"
				    hide={!options.showAxisY}
				    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisLYStartIndex, axisLYEndIndex]}
				      axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				    tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
			      />
			    ))}
			    {options.showLineOnChart && (
				  <YAxis
				    yAxisId={options.sameYAxis ? 'mainline' : 'auxline'}
				    dataKey="auxline"
				    type="number"
				    orientation={options.sameYAxis ? 'left' : 'right'}
				    allowDecimals={axisRYEndIndex >= 1 ? false : true}
				    scale={options.scaleType}
				    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
				    tickFormatter={DataFormater}
				    interval="preserveEnd"
				    hide={!options.showAxisY}
				    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisRYStartIndex, axisRYEndIndex]}
				    axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				    tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
				  />
			    )}
			    {options.showAreaOnChart && !options.showLineOnChart && (
				  <YAxis
				    yAxisId={options.sameYAxis ? 'mainline' : 'auxline'}
				    dataKey="auxarea"
				    type="number"
				    orientation={options.sameYAxis ? 'left' : 'right'}
				    allowDecimals={axisRYEndIndex > 1 ? false : true}
				    scale={options.scaleType}
				    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
				    tickFormatter={DataFormater}
				    interval="preserveEnd"
				    hide={!options.showAxisY}
				    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisRYStartIndex, axisRYEndIndex]}
				    axisLine={{ stroke: axisLineColor, strokeWidth: axisLineWidth }}
				    tickLine={{ stroke: 'orange', strokeWidth: 1, transform: 'translate(0, 2)' }}
				  />
			    )}
			    {options.showAreaOnChart && (
				  <Area
				    yAxisId={options.sameYAxis ? 'mainline' : 'auxline'}
				    type={options.lineType}
				    name={options.auxAreaTitle}
				    unit={options.auxAreaUnit}
				    dataKey="auxarea"
				    fill={'url(#auxAreaColor_horizontal_' + id + ')'}
				    stroke={options.auxAreaColor}
				    strokeWidth={options.lineWidth}
				    hide={barProps[options.auxAreaTitle] === true}
				    activeDot={showActiveDot === true ? true : false}
				    dot={showDot ? {
				      stroke: options.auxAreaColor, 
					  strokeWidth: options.lineWidth,
					  fill: backgroundColor
				    } : false}
				    isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
					animationDuration={500}
				  />
			    )}
			    {barFactory(true, 'horizontal', clicEnable, axisLYEndIndex / 100)}
			    {options.showOverLine && (
				  <Line
				    yAxisId="mainline"
				    type={options.lineType}
				    dataKey="totalvalue"
				    name={options.barTitle}
				    unit={options.metricUnit}
				    style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
				    stroke={options.lineColor}
				    strokeWidth={options.lineWidth}
				    dot={showDot ? {
				      stroke: options.lineColor, 
					  strokeWidth: options.lineWidth,
					  fill: backgroundColor
				    } : false}
				    style={{ filter: `drop-shadow(0px 2px 3px ${options.lineColor})` }}
				    isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
					animationDuration={500}
				  />
			    )}
			    {options.showLineOnChart && (
				  <Line
				    yAxisId={options.sameYAxis ? 'mainline' : 'auxline'}
				    type={options.lineType}
				    name={options.auxLineTitle}
				    unit={options.auxLineUnit}
				    dataKey="auxline"
				    style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
				    stroke={options.auxLineColor}
				    strokeWidth={options.lineWidth}
				    hide={barProps[options.auxLineTitle] === true}
				    activeDot={showActiveDot === true ? true : false}
				    dot={showDot ? {
				      stroke: options.auxLineColor, 
					  strokeWidth: options.lineWidth,
					  fill: backgroundColor
				    } : false}
				    style={{ filter: `drop-shadow(0px 2px 3px ${options.auxLineColor})` }}
				    isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
					animationDuration={500}
				  />
			    )}
			    {options.showMetricOnChart && stackElements.length === 1 && stackElements.map(stack => (
				  <Line
				    yAxisId={'mainline'}
				    type={options.lineType}
				    name={options.metricTitle}
				    unit={options.metricUnit}
				    dataKey={'metric_' + stack.stackId}
				    style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
				    stroke={options.metricLineColor}
				    strokeWidth={options.lineWidth}
				    hide={barProps[options.metricTitle] === true}
				    strokeDasharray="5 5"
				    activeDot={showActiveDot === true ? true : false}
				    dot={showDot ? {
				      stroke: options.metricLineColor, 
					  strokeWidth: options.lineWidth,
					  fill: backgroundColor
				    } : false}
				    isAnimationActive={renderCount.current > 1 ? false : options.isAnimationActive}
					animationDuration={500}
			      />
			    ))}
			    {(startZoom && refAreaLeftLabel > 0 && refAreaRightLabel > 0) ? (
				  <ReferenceArea
				    yAxisId={'mainline'}
				    x1={refAreaLeftLabel}
				    y1={axisLYStartIndex}
				    x2={refAreaRightLabel}
				    y2={axisLYEndIndex}
				    stroke="#0d0deb"
				    strokeWidth={1}
				    fill="#0d0deb"
				    strokeOpacity={0.3}
				    label="Zoom"
				    ifOverflow="extendDomain"
				    isFront={true}
				  />
			    ) : null}
			    {options.useCartesianGrid && <CartesianGrid strokeDasharray="3 3" vertical={false} />}
			    {options.showTooltip && useNormalTooltip && (
			      <Tooltip
				    content={<NormalTooltip />}
				    cursor={{ stroke: isDark ? '#F4F9FF' : '#141618', strokeWidth: 1 }}
				    isAnimationActive={false}
				  />
			    )}
			    {options.useZoom && (
			      <Brush
				    dataKey="dateLocale"
				    height={15}
				    stroke={borderColor}
				    strokeWidth={1}
				    startIndex={axisXStartIndex}
				    endIndex={axisXEndIndex}
			      />
			    )}
			  </ComposedChart>
		    </ResponsiveContainer>
		  </ChartContainer>
		)}
	  </BarChartToolboxContainer>
	);
  }
});

function cellFactoryStacked(
  groupedChartBars: [],
  radius: [],
  stackId: string,
  borderColor: string,
  lineWidth: number,
  barGap: number,
  barwidth: number,
  timeSeries: boolean
) {
  if (!timeSeries) {
    return groupedChartBars.map((entry, i) => (
      <Cell
        key={i}
        fill={groupedChartBars[i][`color_${stackId}`]}
        radius={radius}
        stroke={borderColor}
        strokeWidth={lineWidth}
      ></Cell>
    ));
  } else if (timeSeries && barGap > 1 && barwidth > 8) {
    return groupedChartBars.map((entry, i) => (
      <Cell
        key={i}
		fill={groupedChartBars[i][`color_${stackId}`]}
        radius={radius}
        stroke={groupedChartBars[i][`color_${stackId}`]}
        strokeWidth={lineWidth}
      ></Cell>
    ));
  } else {
    return groupedChartBars.map((entry, i) => (
      <Cell
        key={i}
        fill={groupedChartBars[i][`color_${stackId}`]}
        radius={[0,0,0,0]}
        stroke={groupedChartBars[i][`color_${stackId}`]}
        strokeWidth={0}
      ></Cell>
    ));
  }
}

function handleAuxClick(variable: string, value: string) {
  if (variable !== '' && value !== '') {
	const queryMap = {
	  [`var-${variable}`]: value,
	};
	locationService.partial(queryMap, true);
  }
}

function handleClick(url: string, target: string) {
  if (url !== null && url !== '') {
    window.open(url, target);
  }
}

function CustomTooltip(label: string, showTooltip: boolean, payload: any, options: any, clicEnable: boolean) {
  const BarTooltip = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #333333;
    width: fit-content;
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 4px 2px 4px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
    z-index: 9999;
  `;
  const BarTooltipWarn = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    width: fit-content;
    padding: 5px;
    background-color: #e45858;
    border: 1px solid red;
    border-radius: 4px;
    box-shadow: 4px 2px 4px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
    z-index: 9999;
  `;
  const BarChartTooltipCenter = styled.p`
    text-align: center;
  `;

  const BarChartTooltipText = styled.p`
    margin-top: 0px;
    margin-bottom: 0px;
  `;

  const BarChartTooltipTitle = styled.b`
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
  `;
  if (payload.length > 0) {
	let isOnFire = false;
	payload.forEach(segment => {
	  if (segment.payload[`onfire_${segment.name}`]) {
	    isOnFire = true;
	  }
	});
	if (isOnFire) {
	  return (
		<BarTooltipWarn>
		  <span>
			<BarChartTooltipCenter>
			  <i className="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>
			  {options.timeSeriesChart || label === undefined || Label === null ? 
				<BarChartTooltipTitle>{'Alarma'}</BarChartTooltipTitle> :
				<BarChartTooltipTitle>{'Alarma en ' + label}</BarChartTooltipTitle>
			  }
			</BarChartTooltipCenter>
		  </span>
		  {options.showMetricOnTooltip && (
			<span>
			  {payload.length > 1 && payload.map(segment => (
				<div>
				  <span>
				    {!segment.payload[`onfire_${segment.name}`] && segment.payload[`label_${segment.name}`] && (
					  <BarChartTooltipText>
				        {segment.payload[`label_${segment.name}`] + options.resultSeparator + ' '}
				        <b>{DataFormater(segment.value) + segment.unit}</b>
						{' [' + options.metricTitle + options.resultSeparator + ' ' + DataFormater(segment.payload[`metric_${segment.name}`]) + segment.unit + ']'}
				      </BarChartTooltipText>
					)}
				    {segment.payload[`onfire_${segment.name}`] && segment.payload[`label_${segment.name}`] &&(
					  <BarChartTooltipText>
				        <b><u>
					      {segment.payload[`label_${segment.name}`] + options.resultSeparator + ' '}
				          {DataFormater(segment.value) + segment.unit}
						  {' [' + options.metricTitle + options.resultSeparator + ' ' + DataFormater(segment.payload[`metric_${segment.name}`]) + segment.unit + ']'}
						</u></b>
				      </BarChartTooltipText>
					)}
				  </span>
				</div>
			  ))}
			  {payload.length === 1 && payload.map(segment => (
				<div>
				  <span>
					{segment.payload[`label_${segment.name}`] && segment.payload[`label_${segment.name}`] !== '' && (
				      <BarChartTooltipText>
				        <b>
					      {segment.payload[`label_${segment.name}`] + options.resultSeparator + ' '}
				          {DataFormater(segment.value) + segment.unit}
					    </b>
					  </BarChartTooltipText>
					)}
					{segment.payload[`label_${segment.name}`] && segment.payload[`label_${segment.name}`] !== '' && (
					  <BarChartTooltipText>
					    <b>
						  {options.metricTitle + options.resultSeparator + ' '}
						  {DataFormater(segment.payload[`metric_${segment.name}`]) + segment.unit}
					    </b>
				      </BarChartTooltipText>
					)}
				  </span>
				</div>
			  ))}
			</span>
		  )}
		  {options.showAreaOnChart && options.auxAreaTitle !== '' && (
			<span>
			  <BarChartTooltipText>
				{options.auxAreaTitle + options.resultSeparator + ' '}
				<b>{DataFormater(payload[0].payload.auxarea)+ ' ' + options.auxAreaUnit}</b>
			  </BarChartTooltipText>
			</span>
		  )}
		  {options.showLineOnChart && options.auxLineTitle !== '' && (
			<span>
			  <BarChartTooltipText>
				{options.auxLineTitle + options.resultSeparator + ' '}
				<b>{DataFormater(payload[0].payload.auxline)+ ' ' + options.auxLineUnit}</b>
			  </BarChartTooltipText>
			</span>
		  )}
		  {!options.showMetricOnTooltip && !options.showLabels && !options.showAxisY && !options.showAxisX && (
			<span>
			  {payload.length > 1 && payload.map(segment => (
				<div>
				  <span>
				    {!segment.payload[`onfire_${segment.name}`] && segment.payload[`label_${segment.name}`] && (
				      <BarChartTooltipText>
				        {segment.payload[`label_${segment.name}`] + options.resultSeparator + ' '}
				        <b>{DataFormater(segment.value) + segment.unit}</b>
				      </BarChartTooltipText>
					)}
				    {segment.payload[`onfire_${segment.name}`] && segment.payload[`label_${segment.name}`] && (
					  <BarChartTooltipText>
				        <b><u>
					      {segment.payload[`label_${segment.name}`] + options.resultSeparator + ' '}
				          {DataFormater(segment.value) + segment.unit}
						  {' [' + options.metricTitle + options.resultSeparator + ' ' + DataFormater(segment.payload[`metric_${segment.name}`]) + segment.unit + ']'}
						</u></b>
				      </BarChartTooltipText>
					)}
				  </span>
				</div>
			  ))}
			  {payload.length === 1 && payload.map(segment => (
				<div>
				  <span>
					{segment.payload[`label_${segment.name}`] && segment.payload[`label_${segment.name}`] !== '' && (
				      <BarChartTooltipText>
				        <b>
					      {segment.payload[`label_${segment.name}`] + options.resultSeparator + ' '}
				          {DataFormater(segment.value) + segment.unit}
					    </b>
				      </BarChartTooltipText>
					)}
				  </span>
				</div>
			  ))}
			</span>
		  )}
		  {!options.showMetricOnTooltip && (options.showLabels || options.showAxisY || options.showAxisX) && (
			<span>
			  {payload.map(segment => (
				<BarChartTooltipText>
				  {segment.payload[`label_${segment.name}`] !== undefined && segment.payload[`label_${segment.name}`]}
				</BarChartTooltipText>
			  ))}
			</span>
		  )}
		  {options.timeSeriesChart && options.dateTitle !== '' && (
			<span>
			  <BarChartTooltipText>
				{options.dateTitle + options.resultSeparator + ' '}
				<b>{payload[0].payload.dateLocale}</b>
			  </BarChartTooltipText>
			</span>
		  )}
		  {options.useInfoField && options.infoTitle !== '' && (
			<span>
			  {options.infoTitle + options.resultSeparator + ' '}
			  <b>{payload[0].payload.info}</b>
			</span>
		  )}
		  {clicEnable && (
			<p>
			  <BarChartTooltipText>{options.clicTitle}</BarChartTooltipText>
			</p>
		  )}
		</BarTooltipWarn>
	  );
	} else {
	  return (
		<BarTooltip>
		  {options.showMetricOnTooltip && (
			<span>
			  {payload.length > 1 && payload.map(segment => (
				<div>
				  <span>
					{segment.payload[`label_${segment.name}`] && segment.payload[`label_${segment.name}`] !== '' && (
				      <BarChartTooltipText>
				        <b>{segment.payload[`label_${segment.name}`] + options.resultSeparator + ' '}</b>
				        {DataFormater(segment.value) + segment.unit}
					    {' [' + options.metricTitle + options.resultSeparator + ' ' + DataFormater(segment.payload[`metric_${segment.name}`]) + segment.unit + ']'}
				      </BarChartTooltipText>
					)}
				  </span>
				</div>
			  ))}
			  {payload.length === 1 && payload.map(segment => (
				<div>
				  <span>
					{segment.payload[`label_${segment.name}`] && segment.payload[`label_${segment.name}`] !== '' && (
				      <BarChartTooltipText>
				        <b>{segment.payload[`label_${segment.name}`] + options.resultSeparator + ' '}</b>
				        {DataFormater(segment.value) + segment.unit}
					  </BarChartTooltipText>
					)}
					{segment.payload[`label_${segment.name}`] && segment.payload[`label_${segment.name}`] !== '' && (					
					<BarChartTooltipText>
					    <b>{options.metricTitle + options.resultSeparator + ' '}</b>
					    {DataFormater(segment.payload[`metric_${segment.name}`]) + segment.unit}
				      </BarChartTooltipText>
					)}
				  </span>
				</div>
			  ))}
			</span>
		  )}
		  {!options.showMetricOnTooltip && !options.showLabels && !options.showAxisY && !options.showAxisX && (
			<span>
			  {payload.map(segment => (
				<div>
				  {segment.payload[`label_${segment.name}`] && segment.payload[`label_${segment.name}`] !== '' && (
				    <BarChartTooltipText>
				      <b>{segment.payload[`label_${segment.name}`] + options.resultSeparator + ' '}</b>
				      {DataFormater(segment.value) + ' ' + segment.unit}
				    </BarChartTooltipText>
				  )}
				</div>
			  ))}
			</span>
		  )}
		  {!options.showMetricOnTooltip && (options.showLabels || options.showAxisY || options.showAxisX) && (
			<span>
			  {payload.map(segment => (
				<BarChartTooltipText>
				  {segment.payload[`label_${segment.name}`] !== undefined && segment.payload[`label_${segment.name}`]}
				</BarChartTooltipText>
			  ))}
			</span>
		  )}
		  {options.showAreaOnChart && options.auxAreaTitle !== '' && (
			<span>
			  <BarChartTooltipText>
				<b>{options.auxAreaTitle + options.resultSeparator + ' '}</b>
				{DataFormater(payload[0].payload.auxarea)+ ' ' + options.auxAreaUnit}
			  </BarChartTooltipText>
			</span>
		  )}
		  {options.showLineOnChart && options.auxLineTitle !== '' && (
			<span>
			  <BarChartTooltipText>
				<b>{options.auxLineTitle + options.resultSeparator + ' '}</b>
				{DataFormater(payload[0].payload.auxline)+ ' ' + options.auxLineUnit}
			  </BarChartTooltipText>
			</span>
		  )}
		  {options.timeSeriesChart && options.dateTitle !== '' && (
			<span>
			  <BarChartTooltipText>
				<b>{options.dateTitle + options.resultSeparator + ' '}</b>
				{payload[0].payload.dateLocale}
			  </BarChartTooltipText>
			</span>
		  )}
		  {options.useInfoField && options.infoTitle !== '' && (
			<span>
			  <b>{options.infoTitle + options.resultSeparator + ' '}</b>
			  {payload[0].payload.info}
			</span>
		  )}
		  {clicEnable && options.clicTitle !== '' && (
			<p>
			  <BarChartTooltipText>{options.clicTitle}</BarChartTooltipText>
			</p>
		  )}
		</BarTooltip>
	  );
	}
  } else {
	return null;
  }
}

function DataFormater(value: any) {
  if (isNaN(value)) {
	return String(value) + ' ';
  } else if (value >= 1000000000) {
	return String(Math.round(value / 1000000000)) + ' G';
  } else if (value >= 1000000) {
	return String(Math.round(value / 1000000)) + ' M';
  } else if (value >= 1000) {
	return String(Math.round(value / 1000)) + ' K';
  } else {
	return String(Math.round(value)) + ' ';
  }
}

function zoomTime(actualZoom: number) {
  const d = Date.now();
  let to = new Date(d).toUTCString();
  let from = new Date(d - 10800000).toUTCString();
  switch (actualZoom) {
    case 2:
      from = new Date(d - 86400000).toUTCString();
      break;
    case 3:
      from = new Date(d - 86400000 * 7).toUTCString();
      break;
    case 4:
      from = new Date(d - 86400000 * 30).toUTCString();
      break;
    case 5:
      from = new Date(d - 86400000 * 90).toUTCString();
      break;
    case 6:
      from = new Date(d - 86400000 * 180).toUTCString();
      break;
    case 7:
      from = new Date(d - 86400000 * 360).toUTCString();
      break;
  }
  zoom(from, to);
}

function zoom(refRight: string, refLeft: string) {
  var time_from = new Date(refLeft).getTime();
  var time_to = new Date(refRight).getTime();
  if (time_from < time_to) {
    let queryMap: UrlQueryMap = { from: time_from, to: time_to };
    getLocationSrv().update({
      partial: true,
      replace: true,
      query: queryMap,
    });
  } else if (time_from > time_to) {
    let queryMap: UrlQueryMap = { from: time_to, to: time_from };
    getLocationSrv().update({
      partial: true,
      replace: true,
      query: queryMap,
    });
  }
}
