import React, { FC, useMemo } from 'react';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { config } from '@grafana/runtime';
import { ActionIconBig } from 'app/features/alerting/unified/components/rules/ActionIcon';
import { Alert, PromRuleWithLocation } from 'app/types/unified-alerting';

import { AlertInstances } from './AlertInstances';
import { GroupedRules, AlertListOptions } from './types';
import { filterAlerts } from './utils';

type GroupedModeProps = {
  rules: PromRuleWithLocation[];
  options: UnifiedAlertListOptions;
  isAdmin: boolean;
};

const GroupedModeView: FC<GroupedModeProps> = ({ rules, options, isAdmin }) => {
  const styles = useStyles2(getStyles);
  const groupBy = options.groupBy;
  const alerts = [];
  const alarmTitles = {
    alarma: 'Alarma',
    alerta: 'Alerta',
    trap: 'Trap',
    resolucion: 'Resolucion',
    reporte: 'Reporte',
  };

  const groupedRules = useMemo<GroupedRules>(() => {
    const groupedRules = new Map<string, Alert[]>();

    const hasInstancesWithMatchingLabels = (rule: PromRuleWithLocation) =>
      groupBy ? alertHasEveryLabel(rule, groupBy) : true;

    const matchingRules = rules.filter(hasInstancesWithMatchingLabels);
    matchingRules.forEach((rule: PromRuleWithLocation) => {
      (rule.rule.alerts ?? []).forEach((alert) => {
        const mapKey = createMapKey(groupBy, alert.labels);
        const existingAlerts = groupedRules.get(mapKey) ?? [];
        groupedRules.set(mapKey, [...existingAlerts, alert]);
      });
    });
    const filteredGroupedRules = Array.from(groupedRules.entries()).reduce((acc, [groupKey, groupAlerts]) => {
      const filteredAlerts = filterAlerts(options, groupAlerts);
      if (filteredAlerts.length > 0) {
        acc.set(groupKey, filteredAlerts);
      }

      return acc;
    }, new Map<string, Alert[]>());

    return filteredGroupedRules;
  }, [groupBy, rules, options]);

  const rulesGrouped = Array.from(groupedRules).map(([key, alerts]) => ([key, alerts]));

  rulesGrouped.forEach(([key, alarmsArray]) => {
	if (alarmsArray.length > 0) {
      alarmsArray.forEach((alarm, index) => {
		const alert = {
		  id: index,
		  activeAt: alarm.activeAt,
		  cardTitle: formatDateTime(alarm.activeAt),
		  cardSubtitle: alarm.annotations.summary,
		  cardDetailedText: alarm.annotations.description,
		  title: '',
		  type: alarm.labels.Tipo ?? '',
		  severity: alarm.labels.Severidad ?? '',
		  ruleName: alarm.labels.alertname ?? '',
		  category: alarm.labels.netmonitor_folder ?? '',
		  operationGroup: alarm.labels.Operacion ?? '',
		  manteinanceGroup: alarm.labels.Mantenimiento ?? '',
		};
		const alarmType = alert.type.toLowerCase();
		if (alarmTitles.hasOwnProperty(alarmType)) {
		  alert.title = alarm.labels[`${alarmTitles[alarmType]}`] ?? '';
		}
		alerts.push(alert);
      });
    }
  });

  return (
	<div className={styles.tableContainer}>
	  <table className={styles.table} data-testid="rules-table">
		<colgroup>
		  <col />
		  <col />
		  <col />
		  <col />
		  <col />
		  <col />
		  <col />
		  {isAdmin && <col />}
		</colgroup>
		<thead className={styles.thead}>
		  <tr>
			<th className={styles.thTable}>Nombre</th>
			<th className={styles.thTable}>Descripción</th>
			<th className={styles.thTable}>Categoria</th>
			<th className={styles.thTable}>Grupo Operación</th>
			<th className={styles.thTable}>Grupo Mantenimiento</th>
			<th className={styles.thTable}>Severidad</th>
			<th className={styles.thTable}>Activa desde</th>
			{isAdmin && <th className={styles.thTable}>Opciones</th>}
		  </tr>
		</thead>
		<tbody>
		  {!alerts.length && (
			<tr className={styles.evenRow}>
			  <td colSpan={8}>No existen Alarmas definidas</td>
			</tr>
		  )}
		  {alerts.map((alarm, idx) => (
			<tr key={alarm.name} className={idx % 2 === 0 ? styles.evenRow : styles.oddRow}>
			  <td className={styles.tdTable}>{alarm.ruleName}</td>
			  <td className={styles.tdTable}>{alarm.cardSubtitle}</td>
			  <td className={styles.tdTable}>{alarm.category}</td>
			  <td className={alarm.operationGroup !== '' ? styles.tdTable : styles.tdTableWeak}>
			    {alarm.operationGroup !== '' ? alarm.operationGroup : 'Ninguno'}
			  </td>
			  <td className={alarm.manteinanceGroup !== '' ? styles.tdTable : styles.tdTableWeak}>
			    {alarm.manteinanceGroup !== '' ? alarm.manteinanceGroup : 'Ninguno'}
			  </td>
			  <td className={styles.tdTable}>{alarm.severity}</td>
			  <td className={styles.tdTable}>{alarm.cardTitle}</td>
			  {isAdmin && (
			    <td className={styles.actionsCell}>
				  <ActionIconBig
				    data-testid="view"
				    onClick={() => enableEditChannel(!showEditChannel, idx)}
				    tooltip="Ver detalles de esta regla"
				    icon="detail-fill"
				  />
			    </td>
			  )}
			</tr>
		  ))}
		</tbody>
	  </table>
	</div>
  );
};

function formatDateTime(date) {
  const fecha = new Date(date);
  const dia = fecha.getDate().toString().padStart(2, '0');
  const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
  const anio = fecha.getFullYear();
  const horas = fecha.getHours().toString().padStart(2, '0');
  const minutos = fecha.getMinutes().toString().padStart(2, '0');
  const segundos = fecha.getSeconds().toString().padStart(2, '0');
  return `${dia}-${mes}-${anio} ${horas}:${minutos}:${segundos}`;
}

function createMapKey(groupBy: string[], labels: Record<string, string>): string {
  return new URLSearchParams(groupBy.map((key) => [key, labels[key]])).toString();
}

function parseMapKey(key: string): Array<[string, string]> {
  return [...new URLSearchParams(key)];
}

function alertHasEveryLabel(rule: PromRuleWithLocation, groupByKeys: string[]) {
  return groupByKeys.every((key) => {
    return (rule.rule.alerts ?? []).some((alert) => alert.labels[key]);
  });
}

const getStyles = (theme: NetMonitorTheme2) => {
  const isDark = config.theme.isDark;
  const textColor = isDark ? '#D8DFE9' : '#23282E';
  const textColorWeak = isDark ? '#646A73' : '#9DA5B8';
  return {
    section: css`
      margin: ${theme.spacing(1)};
    `,
    editWrapper: css`
      overflow-x: auto;
    `,
    header: css`
      font-size: 14px;
     display: flex;
    `,
    title: css`
      width: calc(100% - 80px);
      max-width: 665px;
      display: inline-flex;
    `,
    titleText: css`
      margin-right: ${theme.spacing(2)};
      font-size: 18px;
      font-weight: 500;
    `,
    tableContainer: css`
      width: 100%;
      padding: 1px;
    `,
    table: css`
      width: calc(100% - 32px);
      background-color: ${theme.v1.colors.bg2};
	  color: ${textColor};
      margin: 8px 16px 8px 16px;
    `,
    thead: css`
      height: 32px;
      font-size: 13px;
      font-weight: 600;
      background-color: ${theme.v1.colors.bg2};
      border-bottom: 1px solid ${theme.colors.border.weak};
    `,
    thTable: css`
      font-weight: 500;
      padding: 6px;
    `,
    thTableButton: css`
      font-weight: 500;
      padding-left: 6px;
    `,
    tdTable: css`
      font-weight: 400;
      padding: 6px;
	  color: ${textColor};
    `,
    tdTableWeak: css`
      font-weight: 400;
      padding: 6px;
	  color: ${textColorWeak};
    `,
    evenRow: css`
      background-color: ${theme.v1.colors.bg1};
      font-size: 12px;
	  height: 36px;
    `,
    oddRow: css`
      background-color: ${theme.v1.colors.bg1};
      font-size: 12px;
	  height: 36px;
    `,
    actionsCell: css`
      padding: 0px 8px;
      text-align: right;
      width: 1%;
      white-space: nowrap;
    `,
  };
};

export default GroupedModeView;
