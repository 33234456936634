// @ts-ignore
import React, { useCallback, useState, useMemo } from 'react';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { Icon } from '@grafana/ui';
import { PieChartOptions } from 'types';
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList
} from 'recharts';
import { config } from '@grafana/runtime';
import styled from 'styled-components';
import './css/PieChartPanel.css';

interface Props extends PanelProps<PieChartOptions> {}

export const PieChartPanel: React.FC<Props> = React.memo(({ options, data, width, height, replaceVariables, id }) => {
  const isDark = config.theme.isDark;
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const absoluteThreshold = Number(replaceVariables(options.absoluteThreshold));
  const threshold = Number(replaceVariables(options.metricThreshold)) / 100;

  const auxButtonIcon1 = replaceVariables(options.toolbarAuxButtonIcon1);
  const auxButtonVariable1 = replaceVariables(options.toolbarAuxButtonVariable1);
  const auxButtonValue1 = replaceVariables(options.toolbarAuxButtonValue1);
  const auxButtonIcon2 = replaceVariables(options.toolbarAuxButtonIcon2);
  const auxButtonVariable2 = replaceVariables(options.toolbarAuxButtonVariable2);
  const auxButtonValue2 = replaceVariables(options.toolbarAuxButtonValue2);
  const axisLineWidth = options.axisLineWidth;
  const chartTitle = options.showTitle ? replaceVariables(options.pieTitle) : '';
  const chartSubTitle = options.showTitle ? replaceVariables(options.chartSubTitle) : '';
  const chartIcon = options.showTitle ? replaceVariables(options.chartIcon) : '';
  const chartIconSize = options.titleFontSize * 2 > 64 ? 64 : options.titleFontSize * 2;

  const slice_link = replaceVariables(options.drillDownLink);
  const center_link = replaceVariables(options.drillDownLinkCenter);

  const memoizedOptions = useMemo(() => ({
	slice_link: replaceVariables(options.drillDownLink),
	center_link: replaceVariables(options.drillDownLinkCenter),
	auxButtonIcon1: replaceVariables(options.toolbarAuxButtonIcon1),
	auxButtonVariable1: replaceVariables(options.toolbarAuxButtonVariable1),
	auxButtonValue1: replaceVariables(options.toolbarAuxButtonValue1),
	auxButtonIcon2: replaceVariables(options.toolbarAuxButtonIcon2),
	auxButtonVariable2: replaceVariables(options.toolbarAuxButtonVariable2),
	auxButtonValue2: replaceVariables(options.toolbarAuxButtonValue2),
	chartTitle: options.showTitle ? replaceVariables(options.barTitle) : '',
	chartSubTitle: options.showTitle ? replaceVariables(options.chartSubTitle) : '',
	chartIcon: options.showTitle ? replaceVariables(options.chartIcon) : '',
  }), [options, replaceVariables]);

  if (height < 150 || width < 150) {
    return (
	  <div className="pieChartErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="pieChartErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.series[0].length < 1) {
    return (
	  <div className="pieChartErrorContainer" title={error2}>
	    <Icon name={'image-slash'} size="xxl" />
	  </div>
	);
  }

  var legendPosition = options.displayLegends && options.legendPosition === 'right' ? 'right' : 'bottom';
  var legendVisible = options.displayLegends;
  var labelsVisible = (width < 325 || height < 215) ? false : options.displaysliceLegends;
  if (width > 325 && height < 215) {
    legendPosition = 'right';
  } else if (width < 325 && height > 215) {
    legendPosition = 'bottom';
  }

  if (
    (legendPosition === 'right' && width < 325) ||
	(legendPosition === 'bottom' && height < 215) ||
	(width < 325 || height < 215)
  ) {
    legendVisible = false;
  }

  var toolBoxWidth = 0;
  if (options.showAuxToolbar && !options.showTitle) {
	toolBoxWidth = 32;
  } else if (options.showTitle && chartTitle !== '' && width >= 250) {
	toolBoxWidth = (width * 0.5 < 125) ? 125 : width * 0.5;
	legendVisible = false;
	labelsVisible = false;
  }
  if (options.showTitle && chartTitle !== '' && toolBoxWidth > 300) {
    toolBoxWidth = 300;
  }

  const innerRadius = labelsVisible ? options.innerRadius + 10 : options.innerRadius;
  const outerRadius = labelsVisible ? options.outerRadius + 10 : options.outerRadius;
  const textColor = isDark ? '#E6E9ED' : '#23282E';
  const innerRadiusColor = isDark ? '#23282E' : '#E6E9ED';
  const innerRadiusShadow = isDark ? '#9DA5B8' : '#9DA5B8';
  const borderColor = isDark ? '#44444C' : '#D8DFE9';
  const legendTextColor = isDark ? '#557FFF' : '#6C63FE';
  const backgroundColor = isDark ? '#44444C' : '#D8DFE9';
  const iconColor = isDark ? 'white' : 'black';
  const buttonColor = isDark ? 'black' : 'white';
  const buttonBorder = isDark ? '#23282E' : '#E6E9ED';
  const boxBorder = isDark ? '#1B2733' : '#EFF4FA';
  const innerRadiusFontColor = options.innerRadiusFontColor === 'text' ? iconColor : options.innerRadiusFontColor;

  const PieTooltip = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #333333;
    width: fit-content;
	max-width: 300px;
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 4px 2px 4px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
    z-index: 9999;
  `;
  const PieTooltipWarn = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    width: fit-content;
	max-width: 300px;
    padding: 5px;
    background-color: #e45858;
    border: 1px solid red;
    border-radius: 4px;
    box-shadow: 4px 2px 4px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
    z-index: 9999;
  `;
  const PieChartTooltipCenter = styled.p`
    text-align: center;
  `;

  const PieChartIconCenter = styled.p`
    justify-content: center;
    align-items: center;
  `;

  const PieChartTooltipText = styled.p`
    margin-top: 0px;
    margin-bottom: 0px;
  `;

  const PieChartTooltipTitle = styled.b`
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
  `;

  const PieChartToolboxContainer = styled.div`
    display: inline-flex;
    width: 100%;
  `;

  const ButtonContainer = styled.div`
    position: absolute;
    top: 5px;
    width: 32px;
	border-left: 1px solid ${boxBorder};
  `;

  const ChartContainer = styled.div`
    display: inline-flex;
	position: absolute;
	width: -webkit-fill-available;
    height: -webkit-fill-available;
  `;
	
  const ButtonNormal = styled.button`
    display: inline-flex;
    height: 32px;
    width: 28px;
	margin-left: 2px;
    background-color: transparent;
    opacity: 0.75;
    justify-content: center;
	align-items: center;
    color: ${iconColor};
    border: 1px solid transparent;
    border-radius: 10px;

    &:hover {
      opacity: 1;
	  background-image: linear-gradient(to bottom right, #e5e5e529, #b7b7b76e);
	  border: 1px solid ${buttonBorder};
    }
  `;

  const ButtonText = styled.div`
    width: 28px;
  `;

  var clicEnable = false;
  if (slice_link !== null && slice_link.length > 0 && options.useSegmentUrl) {
    clicEnable = true;
  }
  if (center_link !== null && center_link.length > 0) {
    clicEnable = true;
  }

  var chartPies: [] = [];
  var segmentColors: [] = [];

  data.series.forEach((series) => {
    const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
    for (let i = 0; i < timeVals.length; i++) {
	  var chartPie = {
		id: i,
		name: series.fields.find((field) => field.name === options.categoryField)?.values.get(i),
		value: 0,
		label: series.fields.find((field) => field.name === options.categoryField)?.values.get(i),
		color: '',
		info: '',
		metric: 0,
		threshold: 0,
		onfire: false,
	  };
	  const color = series.fields.find((field) => field.name === options.colorField)?.values.get(i);
	  if (color !== undefined && color !== null && color !== '') {
	    chartPie.color = color;
	  } else {
	    chartPie.color = getColor(chartPie.id + id, isDark)
	  }
	  var segmentColor = {
		id: i,
		color: chartPie.color,
	  };
	  if (options.valueField !== undefined && options.valueField !== '') {
		const value = series.fields.find((field) => field.name === options.valueField)?.values.get(i);
		chartPie.value = isNaN(value) ? 0 : Number(value);
	  }
	  const segmentLabel = series.fields.find((field) => field.name === options.labelField)?.values.get(i)
	  if (segmentLabel !== undefined && segmentLabel !== '') {
	    chartPie.label = segmentLabel;
	  }
	  if (options.useMetricField) {
		if (options.metricField !== undefined && options.metricField !== '') {
		  const metric = series.fields.find((field) => field.name === options.metricField)?.values.get(i);
		  chartPie.metric = isNaN(metric) ? 0 : Number(metric);
		}
		if (options.metricThresholdField !== undefined && options.metricThresholdField !== '') {
		  const relativeThreshold = series.fields.find((field) => field.name === options.metricThresholdField)?.values.get(i);
		  chartPie.threshold = isNaN(relativeThreshold) ? 1 : Number(relativeThreshold) / 100;
		}
	  }
	  if (chartPie.metric > 0 && chartPie.threshold > 0) {
		if (chartPie.value > chartPie.metric * (1 + chartPie.threshold)) {
		  chartPie.onfire = true;
		}
	  }
	  if (absoluteThreshold > 0 && chartPie.value > absoluteThreshold) {
		chartPie.onfire = true;
	  }
	  if (options.invertThreshold) {
		chartPie.onfire = !chartPie.onfire;
	  }
	  if (options.useInfoField && options.infoField !== undefined && options.infoField !== '') {
		chartPie.info = series.fields.find((field) => field.name === options.infoField)?.values.get(i);
	  }
	  chartPies.push(chartPie);
	  segmentColors.push(segmentColor);
	}
  });

  var totalString = '';
  var totalNumber = 0;
  var warnIndex = null;
  chartPies.forEach(chartPie => {
	totalNumber = totalNumber + chartPie.value;
	if (chartPie.onfire && warnIndex === null) {
	  warnIndex = chartPie.id;
	}
  });

  if (options.displayTotals && options.displayName) {
    totalString = options.pieTitle;
  } else {
	totalString = totalNumber >= 10 ? String(Math.round(totalNumber)) : totalNumber.toFixed(2);
  }

  const DataFormater = (value: any) => {
	if (isNaN(value)) {
	  return String(value) + ' ';
	} else if (value >= 1000000000) {
	  return String(Math.round(value / 1000000000)) + ' G';
	} else if (value >= 1000000) {
	  return String(Math.round(value / 1000000)) + ' M';
	} else if (value >= 1000) {
	  return String(Math.round(value / 1000)) + ' K';
	} else {
	  return String(Math.round(value)) + ' ';
	}
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
	if (active && payload && payload.length > 0) {
	  if (payload[0].payload.onfire) {
		return (
		  <PieTooltipWarn>
			<span>
			  <PieChartTooltipCenter>
				<i className="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>
				  <PieChartTooltipTitle>{'Alarma'}</PieChartTooltipTitle> :
			  </PieChartTooltipCenter>
			</span>
			{options.showMetricOnTooltip && (
			  <span>
			    <PieChartTooltipText>
				  <b>
				    {payload[0].payload.label + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.value) + options.metricUnit}
				  </b>
			    </PieChartTooltipText>
			    <PieChartTooltipText>
				  <b>
				    {options.metricTitle + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.metric) + options.metricUnit}
				  </b>
			    </PieChartTooltipText>
			  </span>
			)}
			{!options.showMetricOnTooltip && (
			  <span>
			    <PieChartTooltipText>
				  <b>
				    {payload[0].payload.label + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.value) + options.metricUnit}
				  </b>
			    </PieChartTooltipText>
			  </span>
			)}
			{options.useInfoField && (
			  <span>
				{options.infoTitle + options.resultSeparator + ' '}
				<b>{payload[0].payload.info}</b>
			  </span>
			)}
			{clicEnable && options.clicTitle !== '' && (
			  <p>
				<PieChartTooltipText>{options.clicTitle}</PieChartTooltipText>
			  </p>
			)}
		  </PieTooltipWarn>
		);
	  } else {
		return (
		  <PieTooltip>
			{options.showMetricOnTooltip && (
			  <span>
			    <PieChartTooltipText>
				  <b>
				    {payload[0].payload.label + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.value) + options.metricUnit}
				  </b>
			    </PieChartTooltipText>
			    <PieChartTooltipText>
				  <b>
				    {options.metricTitle + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.metric) + options.metricUnit}
				  </b>
			    </PieChartTooltipText>
			  </span>
			)}
			{!options.showMetricOnTooltip && (
			  <span>
			    <PieChartTooltipText>
				  <b>
				    {payload[0].payload.label + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.value) + options.metricUnit}
				  </b>
			    </PieChartTooltipText>
			  </span>
			)}
			{options.useInfoField && (
			  <span>
				{options.infoTitle + options.resultSeparator + ' '}
				<b>{payload[0].payload.info}</b>
			  </span>
			)}
			{clicEnable && (
			  <p>
				<PieChartTooltipText>{options.clicTitle}</PieChartTooltipText>
			  </p>
			)}
		  </PieTooltip>
		);
	  }
	} else {
	  return null;
	}
  };

  var chartMargin = { top: 5, right: 5, left: 5, bottom: 5 };

  const auxButton1Enable = (auxButtonIcon1 !== '' && auxButtonVariable1 !== '' && auxButtonValue1 !== '') ? true : false;
  const auxButton2Enable = (auxButtonIcon2 !== '' && auxButtonVariable2 !== '' && auxButtonValue2 !== '') ? true : false;

  const [activeIndex, setActiveIndex] = useState(warnIndex);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const renderActiveShape = (props: any) => {
    const {
      id,
	  cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value
    } = props;
	const RADIAN = Math.PI / 180;
	const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 16) * cos;
    const sy = cy + (outerRadius + 16) * sin;
    const mx = cx + (outerRadius + 20) * cos;
    const my = cy + (outerRadius + 20) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    if (activeIndex !== null && id === activeIndex) { 
	  if (labelsVisible) {
		return (
	      <g>
		    <Sector
              cx={cx}
              cy={cy}
              innerRadius={innerRadius + 4}
              outerRadius={outerRadius + 6}
              startAngle={startAngle}
              endAngle={endAngle}
			  stroke={borderColor}
			  strokeWidth={1}
              fill={fill}
			  style={payload.style}
		      cornerRadius={10}
            />
            <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle + 5}
              endAngle={endAngle - 5}
              innerRadius={outerRadius + 12}
              outerRadius={outerRadius + 16}
              fill={payload.onfire ? options.warningColor : fill}
			  className={payload.onfire ? 'filterPieChart' : null}
			  strokeWidth={0}
		      cornerRadius={2}
            />
            {options.showSliceText && (
			  <g>
			    <path
                  d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                  stroke={fill}
                  fill="none"
                />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text
                  x={ex + (cos >= 0 ? 1 : -1) * 12}
                  y={ey}
                  textAnchor={textAnchor}
                  fill={payload.onfire ? options.warningColor : legendTextColor}
		  	      font-size={`${options.fontSize / 16}em`}
			      font-weight={payload.onfire ? 500 : 400}
                >
			      {payload.label !== '' ? `${payload.label}` : `${payload.name}`}
			    </text>
                <text
                  x={ex + (cos >= 0 ? 1 : -1) * 12}
                  y={ey}
                  dy={18}
                  textAnchor={textAnchor}
                  fill={textColor}
			      font-size={`${options.legendFontSize / 16}em`}
               >
                  {`${value} - (${(percent * 100).toFixed(1)}%)`}
                </text>
			  </g>
			)}
          </g>
        );
	  } else if (payload.onfire) {
	    return (
	      <g>
		    <Sector
              cx={cx}
              cy={cy}
              innerRadius={innerRadius + 4}
              outerRadius={outerRadius + 6}
              startAngle={startAngle}
              endAngle={endAngle}
              fill={fill}
			  stroke={borderColor}
			  strokeWidth={1}
			  style={payload.style}
		      cornerRadius={10}
            />
            <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 12}
              outerRadius={outerRadius + 16}
              fill={options.warningColor}
			  className={'filterPieChart'}
			  strokeWidth={0}
		      cornerRadius={2}
            />
          </g>
        );
	  } else {
	    return (
	      <g>
		    <Sector
              cx={cx}
              cy={cy}
              innerRadius={innerRadius + 4}
              outerRadius={outerRadius + 6}
              startAngle={startAngle}
              endAngle={endAngle}
              fill={fill}
			  stroke={borderColor}
			  strokeWidth={1}
			  style={payload.style}
		      cornerRadius={10}
            />
          </g>
        );
	  }
	}
  };

  const renderCentralLabel = (props: any) => {
    const {
      id,
	  cx,
      cy,
      innerRadius,
      fill,
      payload
    } = props;
	if (id === 0) {
	  const circleClass = isDark ? 'pieChartInnerCircle_dark' : 'pieChartInnerCircle_light';
	  return (
	    <g>
		  <a href={clicEnable ? center_link : null} target="_self" className={clicEnable ? 'pieChartInnerText' : null}>
		    <circle
		      cx={cx}
		      cy={cy}
		      r={innerRadius - 15}
		      fill={innerRadiusColor}
		      stroke={'transparent'}
			  strokeWidth={1}
			  className={clicEnable ? circleClass : null}
		      style={{
			    filter: `drop-shadow(0px 0px 15px ${innerRadiusShadow})`
		      }}
		    />
		    <text 
		      x={cx}
		      y={cy}
		      dy={6}
		      textAnchor="middle"
		      fill={innerRadiusFontColor}
		      font-weight={500}
		      font-size={`${(options.fontSize + 10) / 16}em`}
		    >
		      {options.displayTotals ? totalString : null}
		    </text>
		  </a>
        </g>
      );
	}
  };

  const renderColorfulLegendText = (value: string, entry: any) => {
	return <span style={{ color: textColor, cursor: 'pointer' }}>{entry.payload.label}</span>;
  }
  let pieHeight = height;
  let pieWidth = width - toolBoxWidth; 
  if (!options.showTitle && legendVisible && !labelsVisible) {
	pieWidth = Math.min(height, width);
	pieHeight = pieWidth;
  }
  return (
	<PieChartToolboxContainer id={id}>
	  {options.showAuxToolbar && (auxButton1Enable || auxButton2Enable) && !options.showTitle && (
		<ButtonContainer>
		  {auxButton1Enable && (
			<ButtonNormal
			  title={options.toolbarAuxButtonTitle1}
			   onClick={() => {
				handleAuxClick(auxButtonVariable1, auxButtonValue1);
			  }}
			>
			  <ButtonText><Icon name={auxButtonIcon1} size="lg" /></ButtonText>
			</ButtonNormal>
		  )}
		  {auxButton2Enable && (
			<ButtonNormal
			  title={options.toolbarAuxButtonTitle2}
			  onClick={() => {
				handleAuxClick(auxButtonVariable2, auxButtonValue2);
			  }}
			>
			  <ButtonText><Icon name={auxButtonIcon2} size="lg" /></ButtonText>
			</ButtonNormal>
		  )}
		</ButtonContainer>
	  )}
	  {options.showTitle && chartTitle !== '' && width >= 250 && (
		<button
          style={{
            fontSize: options.titleFontSize,
			width: toolBoxWidth,
			height: height - 10,
          }}
          className={'pieChart_button'}
		  onClick={() => {
			handleClick(url_link, '_self');
		  }}
          title={chartTitle}
        >
		  <div 
		    className={'pieChartIndicator'}
			style={ width > 480 ? 
			  {
			    width: toolBoxWidth,
			    height: height - 10,
			  } : {
			    height: height - 10,
			  }
			}
		  >
			{chartIcon !== '' && (
			  <div 
				className={'icon_pieChart'}
			    style={{
				  color: options.iconColor,
				  width: chartIconSize + 20,
			    }}
			  >
				<Icon name={chartIcon} size={chartIconSize + 10} />
			  </div>
			)}
			<div className={'pieChart_label'}>
			  <b>{chartTitle}</b>
			  <div
			    className={'pieChart_sublabel'}
			    style={{
				  fontColor: iconColor,
				  fontSize: options.fontSize,
			    }}
			  >
			    {chartSubTitle}
			</div>
		  </div>
		</div>
      </button>
	  )}
	  <ChartContainer style={{ left: toolBoxWidth, bottom: 0 }}>
		<ResponsiveContainer width={width - toolBoxWidth + 15} height={height}>
		  <PieChart
			width={pieWidth}
			height={pieHeight}
			margin={chartMargin}
            cx={'50%'}
            cy={'50%'}
		  >
			<Pie
			  dataKey="value"
			  nameKey="label"
			  unit={options.metricUnit}
			  activeIndex={activeIndex}
			  activeShape={renderActiveShape}
			  label={renderCentralLabel}
			  labelLine={false}
			  data={chartPies}
			  isAnimationActive={options.isAnimationActive}
			  innerRadius={`${innerRadius}%`}
			  outerRadius={`${outerRadius}%`}
			  paddingAngle={options.segmentMargin}
			  onMouseEnter={options.showTooltip ? null : onPieEnter}
			  onClick={(e) => {
			    if (clicEnable && !options.useSegmentUrl) {
				  handleClick(slice_link + e.name, '_self');
			    }
			  }}
			>
			  {chartPies.map((entry, index) => (
			    <Cell 
				  key={`cell-${index}`}
				  fill={segmentColors[index % segmentColors.length].color}
				  stroke={borderColor}
				  strokeWidth={1}
				  cornerRadius={10}
				  style={{
                    filter: `drop-shadow(1px 1px 3px ${backgroundColor})`
                  }}
				/>
			  ))}
			</Pie>
			{options.showTooltip && (
			  <Tooltip 
			    content={CustomTooltip}
				cursor={false}
				isAnimationActive={false}
			  />
			)}
			{!options.showTitle && legendVisible && legendPosition === 'right' && (
			  <Legend
				wrapperStyle={{
				  display: 'flex',
				  alignItems: 'center',
				  fontSize: options.legendFontSize,
				  lineHeight: 2,
				  top: '10%',
				  right: 5,
				}}
			    formatter={renderColorfulLegendText}
			    iconSize={options.legendFontSize + 5}
			    iconType={'circle'}
			    onClick={onPieEnter}
				align={'right'}
				width={150}
				height={'80%'}
				layout={'vertical'}
			  />
		    )}
			{!options.showTitle && legendVisible && legendPosition === 'bottom' && (
			  <Legend
				wrapperStyle={{ fontSize: options.legendFontSize, lineHeight: 2, left: '5%', bottom: 0 }}
			    formatter={renderColorfulLegendText}
			    iconSize={options.legendFontSize + 5}
			    iconType={'circle'}
			    onClick={onPieEnter}
				width={'90%'}
				verticalAlign={'bottom'}
			  />
		    )}
		  </PieChart>
		</ResponsiveContainer>
	  </ChartContainer>
	</PieChartToolboxContainer>
  );
});

function handleClick(url: string, target: string) {
  if (url !== null && url !== '') {
    window.open(url, target);
  }
}

function getColor(color: number, isDark: boolean) {
  const colors_dark = [
    "#F17171",
    "#71E200",
    "#A2ADB8",
    "#7100FF",
    "#FFA071",
	"#A20471",
    "#FF71B7", 
    "#7EA9ff",
    "#FF0071",
    "#B380FF"
  ];
  const colors_light = [
    "#D64545",
    "#45C5B0",
    "#7D8995",
    "#45B0E5",
    "#E57A45",
	"#7DB545",
    "#E54594", 
    "#5485E5",
    "#E58045",
    "#9065E5"
  ];
  let colorNumber = color < 0 ? 0 : color;
  if (colorNumber > colors_dark.length) {
    colorNumber = ((color - 1) % colors_dark.length);
  }
  if (isDark) {
    return colors_dark[colorNumber];
  } else {
	return colors_light[colorNumber];
  }
}