import React, { useState, useRef, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { PanelProps, GraphSeriesValue, AppEvents } from '@grafana/data';
import { FullWidthButtonContainer, HorizontalGroup, VerticalGroup, Icon } from '@grafana/ui';

import { config } from '@grafana/runtime';
import { TimepickerData, FilterpickerData, GenericButtonData, optionsData } from 'types';
import { changeUrl, getEpochWithMillis, getNetMonitorVariableValue } from './utils';
import { SimpleOptions } from './types';
import Select, { components } from 'react-select';
import './css/TimepickerButton.css';
import './css/multiSelect.css';

import { TimepickerSelect } from './TimepickerSelect';
import { TimepickerButton, FilterpickerButton, GenericButton } from './TimepickerButton';

interface ButtonPanelProps extends PanelProps<SimpleOptions> {}

export const ButtonPanel: React.FC<ButtonPanelProps> = ({ options, data, width, height, replaceVariables, id }) => {
  const isDark = config.theme.isDark || false;
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const variableToTrackName = replaceVariables(options.variableToTrack);
  var variableToTrack = variableToTrackName !== '' ? getNetMonitorVariableValue(variableToTrackName, false) : '-';
  variableToTrack = variableToTrack !== '' ? variableToTrack : '-';
  const minWidthThreshold = replaceVariables(options.minWidth);
  const minWidth = isNaN(minWidthThreshold) ? 100 : Number(minWidthThreshold);
  const buttonModeEnable = (options.displayStyle === 'dropdown' || width < minWidth) ? false : true;

  if (!buttonModeEnable && width < 100) {
    return (
	  <div className="buttonsErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="buttonsErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }
  if (!data.series && options.displayStyle !== 'input' && options.displayStyle !== 'dropdown' ) {
    return (
	  <div className="buttonsErrorContainer" title={error2}>
	    <Icon name={'image-slash'} size="xxl" />
	  </div>
	);
  }

  var dropdownTitleText = replaceVariables(options.variableText);
  const buttonTitleText = replaceVariables(options.buttonText);
  const [newTimeValue, setNewTimeValue] = useState(false);
  const [cleanButtonValue, setCleanButtonValue] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [dateSelectedFromValue, setDateSelectedFromValue] = useState('');
  const [dateSelectedToValue, setDateSelectedToValue] = useState('');
  const [inputSelectedValue, setInputSelectedValue] = useState('');
  
  const allVariable = replaceVariables(options.allOptionVariable);
  const defaultPlaceHolder = replaceVariables(options.defaultPlaceholder);
  var allValue = options.allOption ? replaceVariables(options.allOptionText) : '';
  var allLabel = options.allOption ? replaceVariables(options.allOptionText) : defaultPlaceHolder;
  var allIcon = options.allOption && allValue !== '' ? 'all' : '';
  const allVariableValue = getNetMonitorVariableValue(allVariable, false);
  if (options.multiValue) {
    allValue = replaceVariables(options.defaultValueDropdown);
	allLabel = allValue;
	allIcon = 'all';
  }

  const cleanAllValue = replaceVariables(options.defaultValuetoClean);
  var defaultVariable = replaceVariables(options.defaultVariable);
  var mapVariable = replaceVariables(options.mapVariable);
  var heatVariable = replaceVariables(options.heatVariable);
  var multiVariableValue = [];
  if (options.displayStyle === 'dropdown' && options.multiValue) {
    defaultVariable = replaceVariables(options.multiValueVariable);
    multiVariableValue = getNetMonitorVariableValue(defaultVariable, true);
  }
  var multiVariableValueHasAll = false;
  var hasPrimary = false;
  var selectHasValue = false;
  const previousDefVariableWasAll = useRef(false);
  let findAllValue = multiVariableValue.indexOf(allValue);
  if (findAllValue >= 0) {
    multiVariableValueHasAll = true;
  }
  const renderCount = useRef(0);
  useEffect(() => {
    renderCount.current = renderCount.current + 1;
    previousDefVariableWasAll.current = multiVariableValueHasAll;
  });
  const allOption: optionsData = {
    value: allValue,
    label: allLabel,
    icon: allIcon,
  };
  var defaultOption: optionsData = allOption;
  var defaultOptions: optionsData[] = [];
  const buttons: TimepickerData[] = [];
  const buttons2: FilterpickerData[] = [];
  const buttons3: GenericButtonData[] = [];
  const buttons4: GenericButtonData[] = [];
  const buttons5: GenericButtonData[] = [];
  const buttons6: GenericButtonData[] = [];
  const dropDownOptions: optionsData[] = [];
  const hasAuxButtons = options.displayButtonsHorizontal && (options.zoomButton || options.mapButton || options.heatButton) ? 
    true :
	false;

  var optionsDisabled = false;
  let maxOptionsLimit = 10;
  if (isNaN(Number(options.maxOptionsLimit))) {
    maxOptionsLimit = 10;
  } else {
    maxOptionsLimit = Number(options.maxOptionsLimit);
  }

  var searchFilter = '';
  if (renderCount.current === 0) {
    if (options.searchVariable !== undefined && options.searchVariable !== null && options.searchVariable !== '') {
      searchFilter = getNetMonitorVariableValue(options.searchVariable, false);
    }
  }
  const [searchValue, setSearchValue] = useState(searchFilter);
  const onSearchChange = (event) => {
    if (searchValue !== event.target.value) {
	  setSearchValue(event.target.value);
	}
  };

  const changeSearch = (event) => {
    if (event.key === 'Enter') {
	  const queryMap = {
		[`var-${options.searchVariable}`]: searchValue,
	  };
	  changeUrl(queryMap);
    }
  };

  if (options.displayStyle !== 'input') {
    data.series.forEach(series => {
      const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
      let primaryButton = replaceVariables(options.primaryFieldButton) || '0';
      let actualFrom = getNetMonitorVariableValue(`__from`, false);
      actualFrom = actualFrom.toString().substring(0, 7);
      let actualTo = getNetMonitorVariableValue(`__to`, false);
      actualTo = actualTo.toString().substring(0, 7);
      if (isNaN(Number(primaryButton))) {
        primaryButton = '0';
      }
      let actualIndex = 0;
      if (options.TypePickerButton === true) {
        for (let i = 0; i < timeVals.length; i++) {
          let optionButton: TimepickerData = {
            text: series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i),
            time_from: series.fields.find(field => field.name === options.timeFromOption)?.values.get(i),
            time_to: series.fields.find(field => field.name === options.timeToOption)?.values.get(i),
            style: series.fields.find(field => field.name === options.buttonStyleOption)?.values.get(i),
            errors: [],
          };
          if (typeof optionButton.time_from === 'undefined' || optionButton.time_from === null) {
            optionButton.errors.push(`'${options.timeFromOption}' value is required`);
          } else {
            optionButton.time_from = Number(optionButton.time_from);
            if (isNaN(optionButton.time_from)) {
              optionButton.errors.push(`'${options.timeFromOption}' is not a valid UNIX timestamp`);
            } else if (typeof optionButton.text === 'undefined' || optionButton.text === null) {
              optionButton.text = new Date(getEpochWithMillis(optionButton.time_from)).toLocaleString();
            } else {
              optionButton.text = String(optionButton.text);
            }
          }
          if (typeof optionButton.time_to !== 'undefined' && optionButton.time_to !== null) {
            optionButton.time_to = Number(optionButton.time_to);
            if (isNaN(optionButton.time_to)) {
              optionButton.errors.push(`'${options.timeToOption}' is not a valid UNIX timestamp`);
            }
          }
          let timeFrom = getEpochWithMillis(optionButton.time_from);
          timeFrom = timeFrom.toString().substring(0, 7);
          let timeTo = getEpochWithMillis(optionButton.time_to);
          timeTo = timeTo.toString().substring(0, 7);
          if (Number(primaryButton) === i || (actualFrom === timeFrom && actualTo === timeTo)) {
            optionButton.primary = true;
          } else {
            optionButton.primary = false;
          }
          optionButton.id = i;
          optionButton.style = String(optionButton.style);
          buttons.push(optionButton);
        }
      } else {
        for (let i = -1; i < timeVals.length; i++) {
          if (options.displayStyle === 'dropdown' && options.allOption && i < 0) {
            let aux_button: FilterpickerData = {
              id: 0,
              text: allValue,
              variable: allVariable,
              value: allValue,
              result: '',
              style: 'default',
			  fullWidth: options.displayFullWidthButtons,
              icon: 'counterclockwise-fill',
              title: 'Ver todos los ' + dropdownTitleText.toLowerCase(),
              selectTitle: options.selectedTooltip || 'Filtro aplicado actualmente',
              errors: [],
            };
            buttons2.push(aux_button);
            dropDownOptions.push(allOption);
          } else if (i >= 0) {
            let optionButton: FilterpickerData = {
              text: series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i),
              variable: series.fields.find(field => field.name === options.buttonVariableOption)?.values.get(i),
              value: series.fields.find(field => field.name === options.buttonValueOption)?.values.get(i),
              result: series.fields.find(field => field.name === options.buttonResultOption)?.values.get(i),
              style: series.fields.find(field => field.name === options.buttonStyleOption)?.values.get(i),
			  fullWidth: options.displayFullWidthButtons,
              icon: series.fields.find(field => field.name === options.buttonIconOption)?.values.get(i),
              title: series.fields.find(field => field.name === options.buttonTitleOption)?.values.get(i),
              selectTitle: options.selectedTooltip,
              errors: [],
            };
            if (options.displayStyle === 'button' && Number(primaryButton) === i) {
              optionButton.primary = true;
            } else {
              optionButton.primary = false;
            }
            if (optionButton.result === undefined || optionButton.result === null) {
              optionButton.result = '';
            } else {
              optionButton.result = String(optionButton.result);
            }
            optionButton.id = i + 1;
            optionButton.text = String(optionButton.text);
            optionButton.variable = String(optionButton.variable);
            optionButton.value = String(optionButton.value);
            optionButton.style = String(optionButton.style);
            optionButton.icon = String(optionButton.icon);
            optionButton.title = String(optionButton.title);
            optionButton.selectTitle = String(optionButton.selectTitle);
            buttons2.push(optionButton);
            const dropDownOption: optionsData = {
              value: series.fields.find(field => field.name === options.buttonValueOption)?.values.get(i),
			  label: series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i),
              icon: series.fields.find(field => field.name === options.buttonIconOption)?.values.get(i),
            };
            dropDownOptions.push(dropDownOption);
			if (!options.multiValue) {
              const actualVariableValue = getNetMonitorVariableValue(optionButton.variable, false);
			  if (actualVariableValue === optionButton.value) {
			    hasPrimary = true;
			  }
			  if (actualVariableValue === dropDownOption.value || actualVariableValue === dropDownOption.label || optionButton.primary === true) {
                defaultOption = dropDownOption;
              } else if (actualVariableValue === selectedValue.value) {
				defaultOption = selectedValue;
				selectHasValue = true;
			  }
            } else {
              const index = multiVariableValue.indexOf(optionButton.value);
			  if (optionButton.variable === defaultVariable && index >= 0) {
                defaultOptions.push(dropDownOption);
              }
            }
          }
        }

		if (renderCount.current > 2 && ((!selectHasValue && options.displayStyle === 'dropdown' && !options.multiValue) || timeVals.length < 1)) {
		  setSelectedValue(defaultOption);
		  renderCount.current = 1;
		} else if (renderCount.current > 1 && options.selectFisrt && defaultOptions.length < 1 &&  dropDownOptions.length > 0) {
		  setSelectedValue(dropDownOptions[0]);
		  renderCount.current = 1;
		  const valueToSet = buttons2.find(options => {
			return options.text === dropDownOptions[0].label;
		  });
		  if (valueToSet) {
			let queryMap = {
			  [`var-${valueToSet.variable}`]: dropDownOptions[0].value,
			};
			changeUrl(queryMap);
		  }
		}
      }
	  if (hasPrimary && !cleanButtonValue) {
		setCleanButtonValue(true);
	  } else if (!hasPrimary && cleanButtonValue) {
		setCleanButtonValue(false);
	  }
      let clean_button: GenericButtonData = {
        text: 'clean',
        variable: defaultVariable,
        value: replaceVariables(options.defaultValue),
        icon: 'trash',
        url: '',
        title: 'Borrar selección',
      };
      clean_button.value = replaceVariables(clean_button.value) || '';
      buttons3.push(clean_button);
      let zoom_button: GenericButtonData = {
        text: 'zoom',
        variable: defaultVariable,
        value: replaceVariables(options.defaultValue),
        icon: 'expand-fill',
        url: replaceVariables(options.zoomButtonUrl) || '',
        title: 'Ampliar',
      };
      zoom_button.value = replaceVariables(zoom_button.value);
      zoom_button.url = replaceVariables(zoom_button.url);
      buttons4.push(zoom_button);
      let map_button: GenericButtonData = {
        text: 'map',
        variable: mapVariable,
        value: 'streets',
        icon: 'layers-fill',
        url: '',
        title: 'Cambiar tipo de mapa utilizado',
      };
      map_button.value = getNetMonitorVariableValue(map_button.variable, false);
      buttons5.push(map_button);
      let heat_button: GenericButtonData = {
        text: 'heatmap',
        variable: heatVariable,
        value: '0',
        icon: 'fire-fill',
        url: '',
        title: 'Activar/desactivar Heatmap',
      };
      heat_button.value = getNetMonitorVariableValue(heat_button.variable, false);
      buttons6.push(heat_button);
    });

    if ((allVariableValue === allValue && options.allOption) || (options.multiValue && defaultOptions.length < 1)) {
	  defaultOptions.push(allOption);
    }
  
	var buttonContainerClass = options.displayButtonsHorizontal ? 'button_container' : 'button_containerVertical' ;
	if (hasAuxButtons) {
	  buttonContainerClass = isDark ? buttonContainerClass + ' marginLeft_dark' : buttonContainerClass + ' marginLeft';
	}
	if (options.cleanButton && cleanButtonValue) {
	  buttonContainerClass = isDark ? buttonContainerClass + ' marginRight_dark' : buttonContainerClass + ' marginRight';
	}

    if (options.TypePickerButton === true) {
      return (
        <div>
          {options.displayStyle === 'dropdown' && <TimepickerSelect timepickerData={buttons} />}
          <div className="buttonPanel_container">
            <div className="pagination">
              {options.displayStyle === 'button' && !options.displayButtonsHorizontal && buttonTitleText !== '' && (
			    <div className="dropdown_text">
				  {buttonTitleText + ':'}
				</div>
			  )}
			  {options.displayStyle === 'button' && !options.displayButtonsHorizontal && (
                <FullWidthButtonContainer>
                  <VerticalGroup spacing={'sm'}>{buttonFactory(buttons)}</VerticalGroup>
                </FullWidthButtonContainer>
              )}
              {options.displayStyle === 'button' && options.displayButtonsHorizontal && (
                <div className={buttonContainerClass}>{buttonFactory(buttons)}</div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      let dropdownStyle = isDark ? 'buttonDropdown__container_dark' : 'buttonDropdown__container';
      let dropdownTextVisible = false;
      if (dropdownTitleText.length > 0 && width > 200) {
        dropdownTextVisible = true;
      }

	  if (options.multiValue) {
		dropdownStyle = isDark ? 'buttonDropMulti__container_dark' : 'buttonDropMulti__container';
		if (!previousDefVariableWasAll.current && multiVariableValueHasAll) {
		  if (renderCount.current > 2) {
		    renderCount.current = 1;
		  }
		  setSelectedValues([allOption]);
		  previousDefVariableWasAll.current = true;
        } else if (previousDefVariableWasAll.current && !multiVariableValueHasAll && renderCount.current > 1) {
		  previousDefVariableWasAll.current = false;
		  const currentValues = defaultOptions.filter(item => item.label !== allValue);
		  setSelectedValues(currentValues);
        } else if (!previousDefVariableWasAll.current && !multiVariableValueHasAll && renderCount.current > 1) {
		  setSelectedValues(defaultOptions);
		  renderCount.current = 1;
		} else if (previousDefVariableWasAll.current && multiVariableValueHasAll && renderCount.current > 2) {
		  previousDefVariableWasAll.current = false;
          setSelectedValues([allOption]);
		  renderCount.current = 1;
		}
	  }
      if ((renderCount.current === 0) || (renderCount.current === 2 && defaultOption.value === allValue)) {
        if (!options.multiValue) {
		  setSelectedValue(defaultOption);
        } else {
          setSelectedValues(defaultOptions);
        }
        renderCount.current = renderCount.current + 1;
      }

      let classSelect = 'button-select-box';
      var customStyles = {
        menu: provided => ({
          ...provided,
          zIndex: 9999,
          color: '#23282E',
          background: '#EFF4FA',
          border: 'none',
          borderRadius: '10px',
          marginTop: '5px',
          marginBottom: '0px',
        }),
        option: (base, state) => ({
          ...base,
		  height: '30px',
          marginTop: '0px',
          marginBottom: '0px',
		  fontSize: '12px',
		  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		  background: state.isSelected ? '#FFC530' : '#EFF4FA',
          '&:hover': {
            color: 'black',
            background: '#FF7F27',
          },
        }),
		singleValue: base => ({
		  ...base,
		  color: "#23282E"
		}),
		control: base => ({
		  ...base,
		  border: 0,
		  boxShadow: 'none',
		}),
        indicatorContainer: provided => ({
          ...provided,
          paddingLeft: '10px',
        }),
        menuPortal: provided => ({
          ...provided,
          zIndex: 9999,
        }),
      };

      if (isDark) {
        classSelect = 'button-select-box_dark';
		customStyles = {
          menu: provided => ({
            ...provided,
            zIndex: 9999,
			color: '#F4F9FF',
            background: '#1B2733',
            border: 'none',
            borderRadius: '10px',
            marginTop: '5px',
            marginBottom: '0px',
			paddingTop: '0px',
			paddingBottom: '0px',
          }),
          option: (base, state) => ({
            ...base,
		    height: '30px',
            marginTop: '0px',
            marginBottom: '0px',
		    fontSize: '12px',
		    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
			borderRadius: '10px',
			background: state.isSelected ? '#E5CD6B' : '#1B2733',
            '&:hover': {
              color: 'white',
              background: '#D9AF27',
            },
          }),
		  singleValue: base => ({
		    ...base,
			color: "#EFF4FA"
		  }),
		  control: base => ({
		    ...base,
		    border: 0,
		    boxShadow: 'none',
		  }),
          indicatorContainer: provided => ({
            ...provided,
            paddingLeft: '10px',
          }),
          menuPortal: provided => ({
            ...provided,
            zIndex: 9999,
          }),
        };
      }

      const DropdownIndicator = (props: ElementConfig<typeof components.DropdownIndicator>) => {
        return (
          <components.DropdownIndicator {...props}>
            <Icon name="angle-down" size="xl" />
          </components.DropdownIndicator>
        );
      };

      const ClearIndicator = (props) => {
        return (
          <components.ClearIndicator {...props}>
            <Icon name="times" size="lg" />
          </components.ClearIndicator>
        );
      };

      const NoOptionsMessage = props => {
        return (
          <components.NoOptionsMessage {...props}>
            <span className="custom-css-class">{error2}</span> 
          </components.NoOptionsMessage>
        );
      };

	  const inputOption = isDark ? 'buttonInput_option_dark' : 'buttonInput_option';

      return (
        <div className={options.displayButtonsHorizontal ? 'button_container' : 'button_containerVertical'}>
          {!buttonModeEnable && dropdownTextVisible && <div className="dropdown_text">{dropdownTitleText + ':'}</div>}
          {!buttonModeEnable && (
            <div 
              className={dropdownStyle} 
              title={options.multiValue 
                ? 'Puede seleccionar hasta ' + String(maxOptionsLimit) + ' ' + dropdownTitleText.toLowerCase() + '.'
                : 'Seleccione una opción.'
              }
            >
              {options.enableSearch && (
				<>
				  <Icon
                    className="searchIcon"
                    name='search'
                    size="lg"
                    title={options.searchTitle}
                  />
				  <input 
				    type="search"
					id="buttonSearch"
					className={inputOption}
				    onKeyPress={changeSearch}
				    onChange={onSearchChange}
				    value={searchValue}
				  />
				</>
			  )}
			  {options.multiValue === false && (
                <Select
                  instanceId={id}
                  className={classSelect}
                  classNamePrefix={classSelect}
                  components={{ DropdownIndicator, NoOptionsMessage }}
                  options={dropDownOptions}
                  value={selectedValue}
                  isSearchable={false}
                  placeholder={
                    <div className="timepicker-icon">
                      {defaultOption.icon !== '' && <Icon name={defaultOption.icon} size="lg" />}
                      {defaultOption.icon !== '' && defaultOption.label !== '' ? 
					    <div className="timepicker-label">{defaultOption.label}</div> :
						<div className="timepicker-label">{defaultPlaceHolder}</div> 
					  }
                    </div>
                  }
                  onChange={e => {
                    if (e !== selectedValue) {
					  setSelectedValue(e);
					  renderCount.current = 1;
                      const valueToSet = buttons2.find(options => {
                        return options.text === e.label;
                      });
                      if (valueToSet) {
                        let queryMap = {
                          [`var-${valueToSet.variable}`]: e.value,
                        };
                        changeUrl(queryMap);
                      }
                      if (options.cleanDefaultVariable) {
                        let cleanValue = replaceVariables(options.defaultValuetoClean);
						let queryMap = {
                          [`var-${options.variableToClean}`]: cleanValue,
                        };
                        if (options.cleanDefaultVariableIsMulti) {
                          let valueToClean = [];
                          valueToClean[0] = cleanValue;
                          queryMap = {
                            [`var-${options.variableToClean}`]: valueToClean,
                          };
                        }
                        changeUrl(queryMap);
                      }
                    }
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  styles={customStyles}
                  getOptionLabel={e => (
                    <div className="timepicker-icon">
                      {e.icon !== ''&& <Icon name={e.icon} size="lg" />}
                      <div className="timepicker-label">{e.label}</div>
                    </div>
                  )}
                />
              )}
              {options.multiValue === true && (
                <Select
                  instanceId={id}
                  className={classSelect}
                  classNamePrefix={classSelect}
                  components={{ DropdownIndicator, ClearIndicator, NoOptionsMessage }}
                  options={dropDownOptions}
                  value={selectedValues}
                  closeMenuOnSelect={false}
                  isSearchable={false}
                  isClearable={true}
                  isMulti={true}
                  isOptionDisabled={() => selectedValues.length >= maxOptionsLimit}
                  onChange={e => {
					let newSelect = e.filter(item => item.label !== allValue);
					if (newSelect.length === 0) {
					  newSelect = [allOption];
					  renderCount.current = 1;
                    }
                    let isAllValue = true;
                    let selectedDefaultValue = [];
                    let n = 0;
                    let selectQuery = {};
                    for (let i = 0; i < newSelect.length; i++) {
					  const valueToSet = buttons2.find(options => {
                        return options.text === newSelect[i].label;
                      });
                      if (valueToSet) {
                        if (valueToSet.variable === defaultVariable) {
                          selectedDefaultValue[n] = String(valueToSet.value);
                          if (valueToSet.value !== allValue) {
                            isAllValue = false;
                          }
                          n = n + 1;
                        } else {
                          let queryMap = {
                            [`var-${valueToSet.variable}`]: valueToSet.value,
                          };
                          changeUrl(queryMap);
                        }
                        if (options.cleanDefaultVariable) {
                          let queryMap = {
                            [`var-${options.variableToClean}`]: cleanAllValue,
                          };
                          if (options.cleanDefaultVariableIsMulti) {
                            let valueToClean = [];
                            valueToClean[0] = cleanAllValue;
                            queryMap = {
                              [`var-${options.variableToClean}`]: valueToClean,
                            };
                          }
                          changeUrl(queryMap);
                        }
                      }
                    }
                    if (n === 0){
					  selectedDefaultValue[0] = allValue;
					}
					const queryMapMulti = {
                      [`var-${defaultVariable}`]: selectedDefaultValue,
                    };
                    changeUrl(queryMapMulti);
                  }}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  styles={customStyles}
                  getOptionLabel={e => (
                    <div className="timepicker-icon">
                      {e.icon !== ''&& <Icon name={e.icon} size="md" />}
                      <div className="timepicker-label">{e.label}</div>
                    </div>
                  )}
                />
              )}
            </div>
          )}
          {buttonModeEnable && options.displayStyle === 'button' && (
            <div className="buttonPanel_container">
              <div className="pagination">
                {!options.displayButtonsHorizontal && (
                  <div className={buttonContainerClass}>
                    <VerticalGroup spacing={'sm'} style={{ height: '99%' }}>{buttonFactory2(buttons2)}</VerticalGroup>
                  </div>
                )}
				{hasAuxButtons && (
				  <div className="auxButtons_container">
                    {options.zoomButton && buttonFactory3(buttons4)}
					{options.mapButton && buttonFactory3(buttons5)}
                    {options.heatButton && buttonFactory3(buttons6)}
				  </div>
                )}
                {options.displayButtonsHorizontal && (
                  <div className={buttonContainerClass}>
					{buttonTitleText !== '' && (
					  <div className="button_text">
						{buttonTitleText + ':'}
					  </div>
					)}
					{buttonFactory2(buttons2)}
				  </div>
                )}
                {options.displayButtonsHorizontal && options.cleanButton && cleanButtonValue && (
                  <div className="auxButtons_container">{buttonFactory3(buttons3)}</div>
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
  } else {
	const TimePickerInput = options.TimePickerInput;
	const inputTitleText = replaceVariables(options.inputText) || '';
	const inputFromTitleText = replaceVariables(options.inputFromText) || '';
	const inputToTitleText = replaceVariables(options.inputToText) || '';
	const inputToVariable = replaceVariables(options.inputToVariable) || '';
	const inputFromVariable = replaceVariables(options.inputFromVariable) || '';
	let inputPlaceholder = replaceVariables(options.placeholderText);
	let inputTextDefaultValue = replaceVariables(options.inputDefaultValue);
	let inputTextVariableValue = '';
	let inputDateVariableValue = Date.now();
	const date = new Date();
	const offset = date.getTimezoneOffset() * 60000;
    var actualTo = inputToVariable === 'to' ? 
	  Number(getNetMonitorVariableValue(`__${inputToVariable}`, false)) - offset :
	  Number(getNetMonitorVariableValue(`${inputToVariable}`, false)) - offset;
	var actualFrom = inputFromVariable === 'from' ?
	  Number(getNetMonitorVariableValue(`__${inputFromVariable}`, false)) - offset :
	  Number(getNetMonitorVariableValue(`${inputFromVariable}`, false)) - offset;

	if (TimePickerInput) {
	  const now = Date.now();
	  if (actualTo > now) {
		actualTo = now;
	  }
	  if (actualFrom > actualTo) {
		actualFrom = actualTo - 3600000;
	  }
	} else {
	  if (options.inputVariable && options.inputVariable !== '') {
	    if (options.inputType === 'datetime-local') {
		  inputDateVariableValue = Number(getNetMonitorVariableValue(`${options.inputVariable}`, false)) - offset;
		} else {
		  inputTextVariableValue = getNetMonitorVariableValue(options.inputVariable, false);
		}
	  }
	  if (options.inputType !== 'datetime-local') {
		if (inputTextVariableValue === inputTextDefaultValue && inputTextVariableValue !== '' && inputTextDefaultValue !== '') {
	      inputPlaceholder = inputTextDefaultValue;
		}
	  } else {
		inputPlaceholder = (new Date(getEpochWithMillis(inputDateVariableValue)).toISOString()).slice(0, -8);
	  }
	}

	const inputFromValue = TimePickerInput ? (new Date(getEpochWithMillis(actualFrom)).toISOString()).slice(0, -8) : '';
	const inputToValue = TimePickerInput ? (new Date(getEpochWithMillis(actualTo)).toISOString()).slice(0, -8) : '';
	useEffect(() => {
	  setDateSelectedFromValue(inputFromValue);
	  setDateSelectedToValue(inputToValue);
	  setInputSelectedValue(inputTextVariableValue);
	}, []);

	const inputTextVisible = (inputTitleText.length > 0 && width > 200) ? true : false;
	const inputFromTextVisible = (inputFromTitleText.length > 0 && width > 530) ? true : false;
	const inputToTextVisible = (inputToTitleText.length > 0 && width > 530) ? true : false;
	const timeInputClass = options.inputType === 'datetime-local' ? 'smallInputDateTime' : 'smallInput';
	const inputClass = inputTextVisible ? timeInputClass : 'normalInput';

	let buttonClass = '';
	if (newTimeValue) {
	  buttonClass = isDark ? 'enable_clic_dark' : 'enable_clic_light';
	}
	const onSearchChange = (event) => {
	  setInputSelectedValue(event.target.value);
	};

	const changeSearch = (event) => {
	  if (event.key === 'Enter') {
		if (options.inputType === 'datetime-local') {
		  const timeValue = Math.round(new Date(e.target.value).getTime());
		  let queryMap = {
			[`var-${options.inputVariable}`]: timeValue,
		  };
		  changeUrl(queryMap);
		} else {
		  let queryMap = {
			[`var-${options.inputVariable}`]: inputSelectedValue,
		  };
		  changeUrl(queryMap);
		}
	  }
	};

	if (TimePickerInput) {
	  return (
        <div>
          <div className="buttonPanel_container width-100">
            <div 
			  className={isDark ? 'buttonImputContainerDark' : 'buttonImputContainer'} 
			  style={{ justifyContent: options.toLeft ? 'flex-start' : 'flex-end' }}
			>
			  <div className={isDark ? 'input-container timepickerInput_dark' : 'input-container timepickerInput'}>
				<div className="auxButtons_container">
				  {inputFromTextVisible === true && (
					<div className="timepickerText_left">{inputFromTitleText + ': '}</div>
				  )}
				  <input
					id={'timePickerFrom_' + id}
					className={isDark ? 'no-border-time-dark' : 'no-border-time'}
					type="datetime-local"
					value={dateSelectedFromValue}
					placeholder={dateSelectedFromValue}
					onChange={e => {
					  const timeValue = Math.round(new Date(e.target.value).getTime()) - offset;
					  actualFrom = timeValue;
					  setDateSelectedFromValue((new Date(getEpochWithMillis(actualFrom)).toISOString()).slice(0, -8));
					  setNewTimeValue(true);
					}}
				  />
				</div>
				<div className="input-container inputGap"></div>
			  </div>
			  <div className={isDark ? 'input-container timepickerInput_dark' : 'input-container timepickerInput'}>
				<div className="auxButtons_container">
				  {inputToTextVisible === true && (
				    <div className="timepickerText_center">{inputToTitleText + ': '}</div>
				  )}
				  <input
					id={'timePickerTo_' + id}
					className={isDark ? 'no-border-time-dark' : 'no-border-time'}
					type="datetime-local"
					value={dateSelectedToValue}
					placeholder={dateSelectedToValue}
					onChange={e => {
					  const timeValue = Math.round(new Date(e.target.value).getTime()) - offset;
					  actualTo = timeValue;
					  setDateSelectedToValue((new Date(getEpochWithMillis(actualTo)).toISOString()).slice(0, -8));
					  setNewTimeValue(true);
					}}
				  />
				</div>
			    <div className="input-container inputGap"></div>
			  </div>
			  <div
				className={isDark ? 'timepickerButton_dark ' + buttonClass : 'timepickerButton_light ' + buttonClass}
				title={newTimeValue ? 'Aplicar ventana de tiempo' : ''}
				onClick={e => {
				  if (newTimeValue) {
					const now = Date.now();
					if (actualTo > now) {
					  actualTo = now;
					}
					if (actualTo < actualFrom) {
					  actualFrom = actualTo - 3600000;
					  SystemJS.load('app/core/app_events').then((appEvents: any) => {
					   appEvents.emit(AppEvents.alertError, ['El final de la ventana de tiempo es anterior al inicio de la misma.\n Se establece una nueva ventana.']);
					  });
					}
					const timeToValue = Math.round(new Date(dateSelectedToValue).getTime());
					let queryMap = {
					  [`${options.inputToVariable}`]: timeToValue,
					};
					changeUrl(queryMap);
				  	const timeFromValue = Math.round(new Date(dateSelectedFromValue).getTime());
					queryMap = {
					  [`${options.inputFromVariable}`]: timeFromValue,
					};
					changeUrl(queryMap);
					setNewTimeValue(false);
				  }
				}}
			  >
				Aplicar
			  </div>
			</div>
          </div>
        </div>
	  );
	} else {
      const inputBorder = isDark ? 'no-border-input-dark' : 'no-border-input';
	  return (
		<div className="buttonPanel_container width-100">
		  {inputTextVisible === true && <div className="dropdown_text">{inputTitleText + ': '}</div>}
          <div
		    className={isDark ? 'buttonImputContainerDark' : 'buttonImputContainer'}
		    style={{ justifyContent: options.toLeft ? 'flex-start' : 'flex-end' }}
		  >
			<div className={isDark ? 'input-container ' + inputClass + '_dark' : 'input-container ' + inputClass}>
			  <input
				type={options.inputType}
				id={'button-input_' + id}
				className={options.inputType === 'datetime-local' ? 'no-border-time' : inputBorder}
				onKeyPress={changeSearch}
				onChange={onSearchChange}
				placeholder={inputPlaceholder}
				value={inputSelectedValue}
			  />
			</div>
          </div>
        </div>
      );
	}
  }
};

function buttonFactory(buttons: TimepickerData[]) {
  return buttons.map(optionButton => (
    <TimepickerButton
      id={optionButton.id}
      key={optionButton.text}
      text={optionButton.text}
      time_from={optionButton.time_from}
      time_to={optionButton.time_to}
      primary={optionButton.primary || false}
      errors={optionButton.errors}
      style={optionButton.style}
    />
  ));
}

function buttonFactory2(buttons2: FilterpickerData[]) {
  return buttons2.map(optionButton => (
    <FilterpickerButton
      id={optionButton.id}
      key={optionButton.text}
      text={optionButton.text}
      icon={optionButton.icon}
      variable={optionButton.variable}
      value={optionButton.value || ''}
      result={optionButton.result || ''}
      primary={optionButton.primary || false}
      style={optionButton.style}
      title={optionButton.title}
      selectTitle={optionButton.selectTitle}
	  fullWidth={optionButton.fullWidth}
    />
  ));
}

function buttonFactory3(buttons3: GenericButtonData[]) {
  return buttons3.map(optionButton => (
    <GenericButton
      key={optionButton.text}
      icon={optionButton.icon}
      variable={optionButton.variable}
      value={optionButton.value || ''}
      title={optionButton.title}
      selectTitle={optionButton.selectTitle}
      url={optionButton.url || ''}
    />
  ));
}
