import { DataFrame } from '@grafana/data';
import _ from 'lodash';
import { PanelController } from '../panel/PanelController';
import { GraphDataElement, CurrentData } from '../types';

class PreProcessor {
  controller: PanelController;

  constructor(controller: PanelController) {
    this.controller = controller;
  }

  _transformObjects(data: any[]): GraphDataElement[] {
    const {
      nodeID,
      xColumn,
      yColumn,
	  latColumn,
      lonColumn,
      labelColumn,
      siteColumn,
      sourceColumn,
      targetColumn,
      extOrigin: externalSource,
      extTarget: externalTarget,
      isRoot,
      nodeSla,
      nodeGroup,
      nodeIcon,
      nodeType,
      nodeVisible,
      nodeDescriptionColumn,
      userColumn,
    } = this.controller.getSettings(true).dataMapping;

    const result = _.map(data, dataObject => {
      var source = _.has(dataObject, sourceColumn) && dataObject[sourceColumn] !== '';
      var target = _.has(dataObject, targetColumn) && dataObject[targetColumn] !== '';
      const extSource = _.has(dataObject, externalSource) && dataObject[externalSource] !== '';
      const extTarget = _.has(dataObject, externalTarget) && dataObject[externalTarget] !== '';

      let trueCount = [source, target, extSource, extTarget].filter(e => e).length;

      if (trueCount > 1) {
        if (target && extTarget) {
          target = false;
        } else if (source && extSource) {
          source = false;
        } else {
          console.error('There are a conflict betwen source-target element', dataObject);
          return undefined;
        }
      }

      const result: GraphDataElement = {
        x: null,
        y: null,
		lat: null,
		lng: null,
        label: '',
        site: '',
        target: '',
        node_sla: -1,
		is_root: false,
        parent: '',
        node_icon: '',
        node_visible: false,
        node_description: '',
        data: dataObject,
        type: '',
        user: '',
      };

      if (trueCount === 0) {
        result.target = dataObject[nodeID];
      } else {
        if (source || target) {
          if (source) {
            result.source = dataObject[sourceColumn];
            result.target = dataObject[nodeID];
          } else {
            result.source = dataObject[nodeID];
            result.target = dataObject[targetColumn];
          }
        } else if (extSource || extTarget) {
          if (extSource) {
            result.source = dataObject[externalSource];
            result.target = dataObject[nodeID];
          } else {
            result.source = dataObject[nodeID];
            result.target = dataObject[externalTarget];
          }
        }
      }
      if (dataObject[xColumn] !== null && dataObject[xColumn] !== undefined) {
        if (isNaN(dataObject[xColumn])) {
		  result.x = null;
		} else {
		  result.x = Number(dataObject[xColumn]);
		}
      } else {
        result.x = null;
      }
      if (dataObject[yColumn] !== null && dataObject[yColumn] !== undefined) {
        if (isNaN(dataObject[yColumn])) {
		  result.y = null;
		} else {
		  result.y = Number(dataObject[yColumn]);
		}
      } else {
        result.y = null;
      }
      if (dataObject[latColumn] !== null && dataObject[latColumn] !== undefined) {
        if (isNaN(dataObject[latColumn])) {
		  result.lat = null;
		} else {
		  result.lat = Number(dataObject[latColumn]);
		}
      } else {
        result.lat = null;
      }
      if (dataObject[lonColumn] !== null && dataObject[lonColumn] !== undefined) {
        if (isNaN(dataObject[lonColumn])) {
		  result.lng = null;
		} else {
		  result.lng = Number(dataObject[lonColumn]);
		}
      } else {
        result.lat = null;
      }
	  result.label = dataObject[labelColumn];
      result.site = dataObject[siteColumn];
      result.is_root = dataObject[isRoot];
	  result.node_sla = dataObject[nodeSla];
      result.parent = dataObject[nodeGroup];
      result.node_description = dataObject[nodeDescriptionColumn];
      result.node_icon = dataObject[nodeIcon];
      result.user = dataObject[userColumn];
      result.type = dataObject[nodeType];
      result.node_visible = dataObject[nodeVisible];
	  return result;
    });

    const filteredResult: GraphDataElement[] = result.filter(
      (element): element is GraphDataElement => element !== null
    );
    return filteredResult;
  }

  _mergeGraphData(data: GraphDataElement[]): GraphDataElement[] {
    const groupedData = _.values(_.groupBy(data, element => element.source + '<--->' + element.target));

    const mergedData = _.map(groupedData, group => {
      return _.reduce(group, (result, next) => {
        return _.merge(result, next);
      });
    });

    return mergedData;
  }

  _extractColumnNames(data: GraphDataElement[]): string[] {
    const columnNames: string[] = _(data)
      .flatMap(dataElement => _.keys(dataElement.data))
      .uniq()
      .sort()
      .value();

    return columnNames;
  }

  _dataToRows(inputDataSets: any) {
    var rows: any[] = [];

    const {
      nodeID,
      xColumn,
      yColumn,
	  latColumn,
      lonColumn,
      labelColumn,
      siteColumn,
      sourceColumn,
      targetColumn,
      extOrigin,
      extTarget,
      nodeDescriptionColumn,
      labelOutColumn,
      siteOutColumn,
      metricOneColumn,
      metricOneActualColumn,
      metricOneThresholdColumn,
      metricTwoColumn,
      metricTwoActualColumn,
      metricTwoThresholdColumn,
      trafficColumn,
      trafficOutColumn,
	  isRoot,
      nodeSla,
      nodeGroup,
      nodeIcon,
      nodeType,
      nodeVisible,
      descriptionColumn,
      userColumn,
    } = this.controller.getSettings(true).dataMapping;

    for (const inputData of inputDataSets) {
      const { fields } = inputData;
      const xField = _.find(fields, ['name', xColumn]);
      const yField = _.find(fields, ['name', yColumn]);
      const latField = _.find(fields, ['name', latColumn]);
      const lonField = _.find(fields, ['name', lonColumn]);
      const labelField = _.find(fields, ['name', labelColumn]);
      const siteField = _.find(fields, ['name', siteColumn]);
      const externalSourceField = _.find(fields, ['name', extOrigin]);
      const externalTargetField = _.find(fields, ['name', extTarget]);
	  const aggregationSuffixField = _.find(fields, ['name', nodeID]);
      const sourceColumnField = _.find(fields, ['name', sourceColumn]);
      const targetColumnField = _.find(fields, ['name', targetColumn]);
      const nodeDescriptionColumnField = _.find(fields, ['name', nodeDescriptionColumn]);
      const labelOutField = _.find(fields, ['name', labelOutColumn]);
      const siteOutField = _.find(fields, ['name', siteOutColumn]);
      const trafficColumnField = _.find(fields, ['name', trafficColumn]);
      const trafficOutColumnField = _.find(fields, ['name', trafficOutColumn]);
      const metricOneColumnField = _.find(fields, ['name', metricOneColumn]);
      const metricOneActualColumnField = _.find(fields, ['name', metricOneActualColumn]);
      const metricOneThresholdColumnField = _.find(fields, ['name', metricOneThresholdColumn]);
      const metricTwoColumnField = _.find(fields, ['name', metricTwoColumn]);
      const metricTwoActualColumnField = _.find(fields, ['name', metricTwoActualColumn]);
      const metricTwoThresholdColumnField = _.find(fields, ['name', metricTwoThresholdColumn]);
      const isRootField = _.find(fields, ['name', isRoot]);
      const nodeSlaField = _.find(fields, ['name', nodeSla]);
      const nodeGroupField = _.find(fields, ['name', nodeGroup]);
      const nodeIconField = _.find(fields, ['name', nodeIcon]);
      const nodeTypeField = _.find(fields, ['name', nodeType]);
      const nodeVisibleField = _.find(fields, ['name', nodeVisible]);
      const descriptionField = _.find(fields, ['name', descriptionColumn]);
      const userField = _.find(fields, ['name', userColumn]);

      for (let i = 0; i < inputData.length; i++) {
        const row: any = {};
        row[xColumn] = xField?.values.get(i);
        row[yColumn] = yField?.values.get(i);
        row[latColumn] = latField?.values.get(i);
        row[lonColumn] = lonField?.values.get(i);
        row[labelColumn] = labelField?.values.get(i);
        row[siteColumn] = siteField?.values.get(i);
        row[extOrigin] = externalSourceField?.values.get(i);
        row[extTarget] = externalTargetField?.values.get(i);
        row[nodeDescriptionColumn] = nodeDescriptionColumnField?.values.get(i);
        row[nodeID] = aggregationSuffixField?.values.get(i);
        row[sourceColumn] = sourceColumnField?.values.get(i);
        row[targetColumn] = targetColumnField?.values.get(i);
        row[labelOutColumn] = labelOutField?.values.get(i);
        row[siteOutColumn] = siteOutField?.values.get(i);
		row[isRoot] = isRootField?.values.get(i);
        row[nodeSla] = nodeSlaField?.values.get(i);
        row[nodeGroup] = nodeGroupField?.values.get(i);
        row[nodeIcon] = nodeIconField?.values.get(i);
        row[nodeType] = nodeTypeField?.values.get(i);
        row[nodeVisible] = nodeVisibleField?.values.get(i);
        row[descriptionColumn] = descriptionField?.values.get(i);
        row[userColumn] = userField?.values.get(i);
        row['metricOne_max'] = metricOneColumnField?.values.get(i);
        row['metricOne_actual'] = metricOneActualColumnField?.values.get(i);
        row['metricOne_threshold'] = metricOneThresholdColumnField?.values.get(i);
        row['metricTwo_max'] = metricTwoColumnField?.values.get(i);
        row['metricTwo_actual'] = metricTwoActualColumnField?.values.get(i);
        row['metricTwo_threshold'] = metricTwoThresholdColumnField?.values.get(i);
        row['traffic_in'] = trafficColumnField?.values.get(i);
        row['traffic_out'] = trafficOutColumnField?.values.get(i);
		Object.keys(row).forEach(key => (row[key] === undefined || row[key] === '') && delete row[key]);
        rows.push(row);
      }
    }
    return rows;
  }

  _mergeObjects(rows: any[]) {
    var mergedObjects: any[] = [];

    for (const row of rows) {
      mergedObjects.push(row);
    }
    return mergedObjects;
  }

  processData(inputData: DataFrame[]): CurrentData {
    const rows = this._dataToRows(inputData);

    const flattenData = this._mergeObjects(rows);

    const graphElements = this._transformObjects(flattenData);

    const columnNames = this._extractColumnNames(graphElements);

    const mergedData = this._mergeGraphData(graphElements);

    return {
      graph: mergedData,
      raw: inputData,
      columnNames: columnNames,
    };
  }
}

export default PreProcessor;
