import React, { LegacyRef, Component, PureComponent } from 'react';
import {
  AbsoluteTimeRange,
  DataFrame,
  FieldConfigSource,
  InterpolateFunction,
  PanelProps,
  TimeRange,
} from '@grafana/data';

import { ServiceDependencyGraph } from './serviceDependencyGraph/ServiceDependencyGraph';
import _ from 'lodash';
import { CurrentData, IntGraph, PanelSettings } from '../types';
import cytoscape from 'cytoscape';
import '../css/netmonitor-topology-map.css';
import GraphGenerator from '../processing/graph_generator';
import PreProcessor from '../processing/pre_processor';
import { locationService, getTemplateSrv, TimeRangeUpdatedEvent } from '@grafana/runtime';

interface Props extends PanelProps<PanelSettings> {}

interface PanelState {
  id: string | number;
  fieldConfig: FieldConfigSource<any>;
  height: number;
  width: number;
  onChangeTimeRange: (timeRange: AbsoluteTimeRange) => void;
  onFieldConfigChange: (config: FieldConfigSource<any>) => void;
  onOptionsChange: (options: PanelSettings) => void;
  renderCounter: number;
  replaceVariables: InterpolateFunction;
  timeRange: TimeRange;
  timeZone: string;
  title: string;
  mustUpdate: boolean;
  transparent: boolean;
  options: PanelSettings;
}

export class PanelController extends Component<Props, PanelState> {
  private subscriber: any;
  cy: cytoscape.Core | undefined;
  ref: LegacyRef<HTMLDivElement>;
  validQueryTypes: boolean;
  isGeoMap: boolean;
  graphGenerator: GraphGenerator;
  preProcessor: PreProcessor;
  currentData: CurrentData;

  constructor(props: Props) {
    super(props);
    const { addMap, addMapVariable } = this.getSettings(false);
	const showMap = getTemplateSrv().replace(`$${addMapVariable}`);
	this.isGeoMap = addMap && showMap === '1' ? true : false;
	this.state = ({ 
	  ...props,
	  showGeoMap: this.isGeoMap,
	  panelName: this.isGeoMap ? 'geoMap' : 'topologyMap',
	});
    this.ref = React.createRef();
    this.graphGenerator = new GraphGenerator(this);
    this.preProcessor = new PreProcessor(this);
  }

  getSettings(resolveVariables: boolean): PanelSettings {
    if (resolveVariables) {
      return this.resolveVariables(this.props.options);
    }
    return this.props.options;
  }

  resolveVariables(element: any) {
    if (element instanceof Object) {
      const newObject: any = {};
      for (const key of Object.keys(element)) {
        newObject[key] = this.resolveVariables(element[key]);
      }
      return newObject;
    }

    if (element instanceof String || typeof element === 'string') {
      return getTemplateSrv().replace(element.toString());
    }
    return element;
  }

  resolveTemplateVars(input: any, copy: boolean) {
    var value = input;
    if (copy) {
      value = _.cloneDeep(value);
    }

    if (typeof value === 'string' || value instanceof String) {
      value = getTemplateSrv().replace(value.toString());
    }
    if (value instanceof Object) {
      for (const key of Object.keys(value)) {
        value[key] = this.resolveTemplateVars(value[key], false);
      }
    }
    return value;
  }

  shouldComponentUpdate(nextProps: Props, nextState: PanelState): boolean {
    const { addMap, addMapVariable } = this.getSettings(false);
	const showMap = getTemplateSrv().replace(`$${addMapVariable}`);
	this.isGeoMap = addMap && showMap === '1' ? true : false;
	if (this.isGeoMap !== this.state.showGeoMap) {
	  this.setState({ 
	    showGeoMap: this.isGeoMap,
	    panelName: this.isGeoMap ? 'geoMap' : 'topologyMap',
	  });
	  return true;
	}

	let equalNodes = true;
	if (this.props.mustUpdate) {
	  for (let x = 0; x < nextProps.data.series.length; x++) {
		for (let i = 0; i < nextProps.data.series[x].fields.length; i++) {
	      if (nextProps.data.series[x].fields[i].name === 'node' || nextProps.data.series[x].fields[i].name === 'sla') {
		    for (let z = 0; z < nextProps.data.series[x].fields[i].values.buffer.length; z++) {
		      if (nextProps.data.series[x].fields[i].values.buffer[z] !== this.props.data.series[x].fields[i].values.buffer[z]) {
				equalNodes = false;
		        break;
			  }
			}
	      } else if (!equalNodes) {
		    break;
		  }
		}
		if (!equalNodes) {
		  break;
		}
	  }

	  if (!equalNodes) {
	    this.processData();
	    this.props.mustUpdate = false;
	    return true;
	  } else {
	    return false;
	  }
    }

	return false;
  }

  componentDidMount() {
    const { addMap, addMapVariable } = this.getSettings(false);
	const showMap = getTemplateSrv().replace(`$${addMapVariable}`);
	this.isGeoMap = addMap && showMap === '1' ? true : false;
	this.setState({ 
	  showGeoMap: this.isGeoMap,
	  panelName: this.isGeoMap ? 'geoMap' : 'topologyMap',
	});

    const { eventBus } = this.props;
	if (eventBus) {
  	  this.subscriber = eventBus.getStream(TimeRangeUpdatedEvent).subscribe(event => {
		this.props.mustUpdate = true;
      })
	}
  }

  componentWillUnmount() {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  processQueryData(data: DataFrame[]) {
    this.validQueryTypes = this.hasOnlyTableQueries(data);
    const graphData = this.preProcessor.processData(data);

    this.currentData = graphData;
  }

  hasOnlyTableQueries(inputData: DataFrame[]) {
    var result = true;

    _.each(inputData, dataElement => {
      if (!_.has(dataElement, 'columns')) {
        result = false;
      }
    });

    return result;
  }

  processData() {
    var inputData: DataFrame[] = this.props.data.series;
    this.processQueryData(inputData);
    const graph: IntGraph = this.graphGenerator.generateGraph(this.currentData.graph);
    return graph;
  }

  getError(): string | null {
    if (!this.isDataAvailable()) {
      return 'Sin datos para mostrar.';
    }
    return null;
  }

  isDataAvailable() {
    const dataExist =
      !_.isUndefined(this.currentData) && !_.isUndefined(this.currentData.graph) && this.currentData.graph.length > 0;
    return dataExist;
  }

  render() {
    const data = this.processData();
    const error = this.getError();
	this.props.mustUpdate = false;
		
    if (error === null) {
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div
            className="netmonitor-topology-map-panel"
            ref={this.ref}
            id="cy"
			key={this.state.panelName}
          >
            <ServiceDependencyGraph
              data={data}
              controller={this}
              showStatistics={false}
              showEdgeStatistics={false}
              options={this.props.options}
              isLock={false}
              isCollapsed={false}
              initResize={true}
			  isGeoMap={this.state.showGeoMap}
            />
          </div>
        </div>
      );
    } else {
      return <div>{error}</div>;
    }
  }
}
