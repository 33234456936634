import { css } from '@emotion/css';
import React, { FC, useState } from 'react';
import { useDebounce } from 'react-use';

import { config } from '@grafana/runtime';
import { NetMonitorTheme2 } from '@grafana/data';
import { Button, Icon, Input, Label, useStyles2, CustomScrollbar } from '@grafana/ui';

import { Authorize } from 'app/features/alerting/unified/components/Authorize';
import { useURLSearchParams } from 'app/features/alerting/unified/hooks/useURLSearchParams';

import { getNotificationsPermissions } from 'app/features/alerting/unified/utils/access-control';
import { emptyArrayFieldMatcher, emptyRoute } from 'app/features/alerting/unified/utils/amroutes';
import { getNotificationPoliciesFilters } from 'app/features/alerting/unified/utils/misc';
import { EmptyArea } from 'app/features/alerting/unified/components/EmptyArea';
import { EmptyAreaWithCTA } from 'app/features/alerting/unified/components/EmptyAreaWithCTA';
import { AmRoutesTable } from './AmRoutesTable';
import { AmRouteReceiver, FullFormAmRoute } from './amroutes';

type Props = {
  alertManagerSourceName: string;
  onChange: (routes: FormAmRoute) => void;
  onRootRouteEdit: () => void;
  receivers: AmRouteReceiver[];
  routes: FullFormAmRoute;
  readOnly?: boolean;
  isAdmin: boolean;
  showTitle: boolean;
  pluginTitle: string;
  id: string;
  width: number;
}

interface Filters {
  queryString?: string;
  contactPoint?: string;
  alarmType?: string;
}

export const AlertGroup = ({
  id,
  showTitle,
  pluginTitle,
  alertManagerSourceName,
  isAdmin,
  onChange,
  onRootRouteEdit,
  receivers,
  routes,
  readOnly,
  width,
}: Props) => {
  const isDark = config.theme.isDark;
  const styles = useStyles2(getStyles(isDark, width));

  const [actualRoutes, setActualRoutes] = useState([...routes.routes]);
  const [isAddMode, setIsAddMode] = useState(false);
  const permissions = getNotificationsPermissions(alertManagerSourceName);
  const canCreateNotifications = isAdmin;
  const [searchParams, setSearchParams] = useURLSearchParams();
  const { queryString, contactPoint, alarmType } = getNotificationPoliciesFilters(searchParams);
  const [filters, setFilters] = useState<Filters>({ queryString, contactPoint, alarmType });

  useDebounce(
    () => {
      setSearchParams({ queryString: filters.queryString, contactPoint: filters.contactPoint, alarmType: filters.alarmType });
    },
    400,
    [filters]
  );

  const clearFilters = () => {
    setFilters({ queryString: undefined, contactPoint: undefined, alarmType: undefined });
    setSearchParams({ queryString: undefined, contactPoint: undefined, alarmType: undefined });
  };

  const addNewRoute = () => {
    clearFilters();
    setIsAddMode(true);
    setActualRoutes(() => [
      ...routes.routes,
      {
        ...emptyRoute,
        matchers: [emptyArrayFieldMatcher],
      },
    ]);
  };

  const onCancelAdd = () => {
    setIsAddMode(false);
    setActualRoutes([...routes.routes]);
  };

  const onTableRouteChange = (newRoutes: FullFormAmRoute[]): void => {
    onChange({
      ...routes,
      routes: newRoutes,
    });

    if (isAddMode) {
      setIsAddMode(false);
    }
  };

  return (
    <CustomScrollbar autoHeightMin="100%" autoHeightMax="100%" key={id}>
      {showTitle && (
        <div className={styles.title}>
          <div className={styles.titleText}>{pluginTitle}</div>
        </div>
      )}
      <div className={styles.container}>
        {!routes.receiver ? (
          readOnly ? (
            <EmptyArea>
              <p>No esiste una regla de notificación por defecto</p>
            </EmptyArea>
          ) : (
            <EmptyAreaWithCTA
              buttonIcon="plus-circle-fill"
              buttonLabel="Agregar"
              onButtonClick={onRootRouteEdit}
              text="No existe un canal de notificación predeterminado"
              showButton={canCreateNotifications}
            />
          )
        ) : actualRoutes.length > 0 ? (
          <>
            <div className={styles.channelContainer}>
              {!isAddMode && (
                <div className={styles.searchContainer}>
                  <div className={styles.filterInput}>
                    <Icon name="search" size="xs" />
					<input
					  className={styles.inputBox}
                      onChange={({ currentTarget }) =>
						setFilters((currentFilters) => ({ ...currentFilters, alarmType: currentTarget.value }))
                      }
                      value={filters.alarmType ?? ''}
                      placeholder="Buscar por tipo ..."
                    />
                  </div>
                  <div className={styles.filterInput}>
                    <Icon name="search" size="xs" />
					<input
					  className={styles.inputBox}
                      onChange={({ currentTarget }) =>
						setFilters((currentFilters) => ({ ...currentFilters, queryString: currentTarget.value }))
                      }
                      value={filters.queryString ?? ''}
                      placeholder="Buscar por nombre ..."
                    />
                  </div>
                  <div className={styles.filterInput}>
                    <Icon name="search" size="xs" />
					<input
					  className={styles.inputBox}
                      onChange={({ currentTarget }) =>
                        setFilters((currentFilters) => ({ ...currentFilters, contactPoint: currentTarget.value }))
                      }
                      value={filters.contactPoint ?? ''}
                      placeholder="Buscar canal ..."
                    />
                  </div>
                  {(queryString || contactPoint || alarmType) && (
                    <div className={styles.addMatcherBtnRow}>
					  <button 
					    type="button" 
					    className={styles.cancelButton} 
					    onClick={clearFilters}
					    title={'Limpiar filtros aplicados'}
					  >
					    <div className={styles.button_icon}>
						  <Icon name="times-fill" size="lg" />
					    </div>
					    Limpiar
					  </button>
                    </div>
                  )}
                  {!readOnly && isAdmin && (
                    <Authorize actions={[permissions.create]}>
                      <div className={styles.addMatcherBtnRow}>
					    <button 
						  type="button"
						  className={styles.okButton} 
						  onClick={addNewRoute}
						  title={'Agregar regla especifica de notificación'}
					    >
						  <div className={styles.button_icon}>
						    <Icon name="plus-circle-fill" size="lg" />
						  </div>
						  Agregar
					    </button>
                      </div>
                    </Authorize>
                  )}
                </div>
              )}
            </div>
            <div className={styles.channelContainer}>
              <AmRoutesTable
                id={id}
				isAddMode={isAddMode}
                isAdmin={isAdmin}
                readOnly={readOnly}
                onCancelAdd={onCancelAdd}
                onChange={onTableRouteChange}
                receivers={receivers}
                routes={actualRoutes}
                filters={{ queryString, contactPoint, alarmType }}
                alertManagerSourceName={alertManagerSourceName}
				isDark={isDark}
				width={width}
              />
            </div>
          </>
        ) : readOnly ? (
          <EmptyArea>
            <p>No existe ninguna regla de notificación configurada aún</p>
          </EmptyArea>
        ) : (
          <EmptyAreaWithCTA
            buttonIcon="plus"
            buttonLabel="Agregar"
            onButtonClick={addNewRoute}
            text="No existe ninguna regla de notificación configurada aún"
            showButton={canCreateNotifications}
          />
        )}
      </div>
    </CustomScrollbar>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const borderColor = isDark ? '#23282E' : '#D8DFE9';
  const background = isDark ? '#141618' : '#F4F9FF'
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  const cancelButtonBack = isDark ? '#F74545' : '#FB3333';
  const okButtonBack = isDark ? '#557FFF' : '#6C63FE';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    container: css`
      display: flex;
      flex-flow: column wrap;
      padding: 5px;
    `,
    channelContainer: css`
      width: 100%;
    `,
    searchContainer: css`
      display: flex;
      flex-flow: row nowrap;
      padding-bottom: ${theme.spacing(2)};
      width: 100%;
	  justify-content: space-between;
    `,
    clearFilterBtn: css`
      align-self: flex-end;
      margin-left: ${theme.spacing(1)};
    `,
    filterInput: css`
      max-width: 300px;
	  height: 22px;
	  border: 1px solid ${borderColor};
	  background: ${background};
	  color: ${fontColor};
	  border-radius: 10px;
	  padding: 0px 5px 0px 5px;
      & + & {
        margin-left: ${theme.spacing(1)};
      }
    `,
    inputBox: css`
      margin-left: 5px;
	  height: 18px;
	  font-size: 12px;
    `,
    addMatcherBtnRow: css`
      display: inline-flex;
      min-width: 100px;
	  margin-left: 15px;
      }
    `,
    addMatcherBtn: css`
      align-self: flex-end;
    `,
    title: css`
      width: calc(100% - 16px);
      display: inline-flex;
      margin: 12px 20px 0px 20px;
    `,
    titleText: css`
      margin-right: ${theme.spacing(2)};
      font-size: 16px;
      font-weight: 500;
    `,
    cancelButton: css`
      background-color: ${cancelButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    okButton: css`
      background-color: ${okButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};
