import { css } from '@emotion/css';
import React, { Component } from 'react';

import {
  DataFrame,
  FieldMatcherID,
  getDataSourceRef,
  getFrameDisplayName,
  PanelProps,
  SelectableValue,
} from '@grafana/data';
import { PanelDataErrorView } from '@grafana/runtime';
import { Select, Icon } from '@grafana/ui';
import { Table } from './Table/Table';
import { FilterItem, TableSortByFieldState } from './Table/types';
import { config } from 'app/core/config';
import { getDatasourceSrv } from 'app/features/plugins/datasource_srv';
import { getDashboardSrv } from 'app/features/dashboard/services/DashboardSrv';
import { applyFilterFromTable } from 'app/features/variables/adhoc/actions';
import { dispatch } from 'app/store/store';

import { getFooterCells } from './footer';
import { PanelOptions } from './models.gen';
import { contextSrv } from 'app/core/services/context_srv';

interface Props extends PanelProps<PanelOptions> {}

export class DataTablePanel extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  onColumnResize = (fieldDisplayName: string, width: number) => {
    const { fieldConfig } = this.props;
    const { overrides } = fieldConfig;

    const matcherId = FieldMatcherID.byName;
    const propId = 'custom.width';

    // look for existing override
    const override = overrides.find((o) => o.matcher.id === matcherId && o.matcher.options === fieldDisplayName);

    if (override) {
      // look for existing property
      const property = override.properties.find((prop) => prop.id === propId);
      if (property) {
        property.value = width;
      } else {
        override.properties.push({ id: propId, value: width });
      }
    } else {
      overrides.push({
        matcher: { id: matcherId, options: fieldDisplayName },
        properties: [{ id: propId, value: width }],
      });
    }

    this.props.onFieldConfigChange({
      ...fieldConfig,
      overrides,
    });
  };

  onSortByChange = (sortBy: TableSortByFieldState[]) => {
    this.props.onOptionsChange({
      ...this.props.options,
      sortBy,
    });
  };

  onChangeTableSelection = (val: SelectableValue<number>) => {
    this.props.onOptionsChange({
      ...this.props.options,
      frameIndex: val.value || 0,
    });
    this.forceUpdate();
  };

  onCellFilterAdded = (filter: FilterItem) => {
    const { key, value, operator } = filter;
    const panelModel = getDashboardSrv().getCurrent()?.getPanelById(this.props.id);
    if (!panelModel) {
      return;
    }

    const datasourceInstance = getDatasourceSrv().getInstanceSettings(panelModel.datasource);
    const datasourceRef = datasourceInstance && getDataSourceRef(datasourceInstance);
    if (!datasourceRef) {
      return;
    }

    dispatch(applyFilterFromTable({ datasource: datasourceRef, key, operator, value }));
  };

  renderTable(frame: DataFrame, width: number, height: number) {
    const { options, replaceVariables } = this.props;
	const reportTab = replaceVariables(options.reportName);
	const totalTitleText = replaceVariables(options.totalTitle);
	const file = replaceVariables(options.fileName);
    const footerValues = options.footer?.show ? getFooterCells(frame, options.footer) : undefined;

    return (
      <Table
        height={height}
        width={width}
        data={frame}
        noHeader={!options.showHeader}
        footerInfo={options.showFooterInfo}
        showToolbar={options.showToolbar}
		totalTitle={options.showTotal ? totalTitleText : 'none'}
		hideTable={contextSrv.isNetMonitorAdmin? false : options.hideTable}
        showTypeIcons={false}
        resizable={true}
        initialSortBy={options.sortBy}
        onSortByChange={this.onSortByChange}
        onColumnResize={this.onColumnResize}
        onCellFilterAdded={this.onCellFilterAdded}
        footerValues={footerValues}
        enablePagination={options.footer?.enablePagination}
        reportStyle={[reportTab, file, options.orientation]}
      />
    );
  }

  getCurrentFrameIndex(frames: DataFrame[], options: PanelOptions) {
    return options.frameIndex > 0 && options.frameIndex < frames.length ? options.frameIndex : 0;
  }

  render() {
    const { data, height, width, options, replaceVariables, fieldConfig, id } = this.props;
    const error1 = replaceVariables(options.error1);
    const error2 = replaceVariables(options.error2);
    const error3 = replaceVariables(options.error3);
    const error4 = replaceVariables(options.error4);
    const frames = data.series;
    const count = frames?.length;
    const hasFields = frames[0]?.fields.length;

    if (width < 100) {
      return (
	    <div className={tableStyles.dataTableErrorContainer} title={error4}>
	      <Icon name={'cloud-slash'} size="xxl" />
		  <div>{error4}</div>
	    </div>
	  );
    }
    if (data.state === 'Error' && !options.showToolbar) {
      return (
	    <div className={tableStyles.dataTableErrorContainer} title={error1}>
	      <Icon name={'sync-slash'} size="xxl" />
		  <div>{error1}</div>
	    </div>
	  );
    }
    if ((!count || !hasFields) && !options.showToolbar) {
      return (
	    <div className={tableStyles.dataTableErrorContainer} title={error2}>
	      <Icon name={'image-slash'} size="xxl" />
		  <div>{error2}</div>
	    </div>
	  );
    }

    if (count > 1) {
      const currentIndex = this.getCurrentFrameIndex(frames, options);
      const names = frames.map((frame, index) => {
        return {
          label: getFrameDisplayName(frame),
          value: index,
        };
      });

      return (
        <div className={tableStyles.wrapper}>
          {this.renderTable(data.series[currentIndex], width, height)}
          <div className={tableStyles.selectWrapper}>
            <Select options={names} value={names[currentIndex]} onChange={this.onChangeTableSelection} />
          </div>
        </div>
      );
    }

    return this.renderTable(data.series[0], width, height);
  }
}

const tableStyles = {
  dataTableErrorContainer: css`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: red;
  `,
  wrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  `,
  noData: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  `,
  selectWrapper: css`
    padding: 8px;
  `,
};
