import { css, cx } from '@emotion/css';
import React, { ReactNode, useEffect, useState } from 'react';

import { NetMonitorTheme2 } from '@grafana/data';
import { IconButton, Pagination, useStyles2, Icon } from '@grafana/ui';
import { usePagination } from 'app/features/alerting/unified/hooks/usePagination';
import { getPaginationStyles } from 'app/features/alerting/unified/styles/pagination';
import { NetMonitorReceiverForm } from './receivers/NetMonitorReceiverForm';
import { updatedRoute, cleanRoutes } from './AmRoutesTable';

interface DynamicTablePagination {
  itemsPerPage: number;
}

export interface DynamicTableColumnProps<T = unknown> {
  id: string | number;
  label: string;

  renderCell: (item: DynamicTableItemProps<T>, index: number) => ReactNode;
  size?: number | string;
}

export interface DynamicTableItemProps<T = unknown> {
  id: string | number;
  data: T;
  renderExpandedContent?: () => ReactNode;
}

export interface DynamicTableProps<T = unknown> {
  cols: Array<DynamicTableColumnProps<T>>;
  items: Array<DynamicTableItemProps<T>>;
  isExpandable?: boolean;
  pagination?: DynamicTablePagination;
  paginationStyles?: string;
  onCancelAdd: () => void;
  renderPrefixHeader?: () => ReactNode;
  renderPrefixCell?: (
    item: DynamicTableItemProps<T>,
    index: number,
    items: Array<DynamicTableItemProps<T>>
  ) => ReactNode;
  alertManagerSourceName: string;
  configuration: AlertManagerCortexConfig;
  isDark:boolean;
  isAdmin: boolean;
  isAddMode: boolean;
  footerRow?: JSX.Element;
  width: number;
}

export const DynamicTable = <T extends object>({
  cols,
  items,
  isExpandable,
  onCancelAdd,
  pagination,
  paginationStyles,
  renderPrefixCell,
  renderPrefixHeader,
  alertManagerSourceName,
  configuration,
  isDark,
  isAdmin,
  isAddMode,
  footerRow,
  width,
}: DynamicTableProps<T>) => {
  const defaultPaginationStyles = useStyles2(getPaginationStyles);
  const styles = useStyles2(getStyles(cols, isExpandable, !!renderPrefixHeader, isDark));
  const [expandedIds, setExpandedIds] = useState<Array<DynamicTableItemProps['id']>>([]);
  const [receiverToEdit, setReceiverToEdit] = useState<Receiver>();

  const toggleExpanded = (item: DynamicTableItemProps<T>) => {
	if (isAddMode && expandedIds.includes(item.id)) {
	  onCancelAdd();
	} else {
	  setReceiverToEdit(
	    expandedIds.includes(item.id) ? undefined : configuration.alertmanager_config.receivers[item.id]
	  ); 
      setExpandedIds(
        expandedIds.includes(item.id) ? expandedIds.filter((itemId) => itemId !== item.id) : [item.id]
      );
	}
  };

  useEffect(() => {
    if (isAddMode && items.length) {
      toggleExpanded(items[items.length - 1]);
    }
  }, [isAddMode, items]);

  const itemsPerPage = pagination?.itemsPerPage ?? items.length;
  const { page, numberOfPages, onPageChange, pageItems } = usePagination(items, 1, itemsPerPage);

  return (
    <>
      <div className={styles.container} data-testid="dynamic-table">
        <div className={styles.header} data-testid="header">
          {renderPrefixHeader && renderPrefixHeader()}
          {isExpandable && <div className={styles.cell} />}
          {cols.map((col) => (
            <div className={styles.cell} key={col.id}>
              {col.label}
            </div>
          ))}
        </div>
        {pageItems.map((item, index) => {
		  const isItemExpanded = expandedIds.includes(item.id);
          return (
            <div
              className={styles.row}
              key={`${item.id}-${index}`}
            >
              {renderPrefixCell && renderPrefixCell(item, index, items)}
              {(isExpandable && isAdmin) && (
                <div className={cx(styles.cell, styles.expandCell)}>
                  <IconButton
                    aria-label={`${isItemExpanded ? 'Collapse' : 'Expand'} row`}
                    size="xl"
                    className={styles.expandButton}
                    name={isItemExpanded ? 'angle-down' : 'angle-right'}
                    onClick={() => toggleExpanded(item)}
                    type="button"
                  />
                </div>
              )}
              {(!isExpandable || !isAdmin) && (
                <div className={cx(styles.cell, styles.expandCell)}>
                  <Icon
                    size="xs"
                    className={styles.expandIcon}
                    name={'minus'}
                  />
                </div>
              )}
              {cols.map((col) => (
				<div className={cx(styles.cell, styles.bodyCell)} data-column={col.label} key={`${item.id}-${col.id}`}>
                  {col.renderCell(item, index)}
                </div>
              ))}
              {isItemExpanded && (
                <div className={styles.expandedContentRow} data-testid="expanded-content">
                  <NetMonitorReceiverForm
					onCancel={(item) => {
					  if (isAddMode) {
						onCancelAdd();
					  }
					  toggleExpanded(item);
					}}
					existing={receiverToEdit}
					alertManagerSourceName={alertManagerSourceName}
					config={configuration}
					isAdmin={isAdmin}
					isDark={isDark}
					width={width}
				  />
                </div>
              )}
            </div>
          );
        })}
        {footerRow && <div className={cx(styles.row, styles.footerRow)}>{footerRow}</div>}
      </div>
      {pagination && (
        <Pagination
          className={cx(defaultPaginationStyles, paginationStyles)}
          currentPage={page}
          numberOfPages={numberOfPages}
          onNavigate={onPageChange}
          hideWhenSinglePage
        />
      )}
    </>
  );
};

const getStyles = <T extends unknown>(
  cols: Array<DynamicTableColumnProps<T>>,
  isExpandable: boolean,
  hasPrefixCell: boolean,
  isDark: boolean,
) => {
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  const sizes = cols.map((col) => {
    if (!col.size) {
      return 'auto';
    }

    if (typeof col.size === 'number') {
      return `${col.size}fr`;
    }

    return col.size;
  });

  if (isExpandable) {
    sizes.unshift('calc(1em + 16px)');
  }

  if (hasPrefixCell) {
    sizes.unshift('0');
  }

  return (theme: NetMonitorTheme2) => ({
    container: css`
      color: ${fontColor};
      font-size: 12px;
    `,
    header: css`
      display: grid;
      grid-template-columns: ${sizes.join(' ')};
      grid-template-rows: 1fr auto;
      border-bottom: 1px solid ${theme.v1.colors.formInputBorder};
      font-weight: 600;
    `,
    row: css`
      display: grid;
      grid-template-columns: ${sizes.join(' ')};
      grid-template-rows: 1fr auto;

      &:nth-child(2n + 1) {
        background-color: ${theme.colors.background.secondary};
      }

      &:nth-child(2n) {
        background-color: ${theme.colors.background.primary};
      }

      ${theme.breakpoints.down('sm')} {
        grid-template-columns: auto 1fr;
        grid-template-areas: 'left right';
        padding: 8px 0px 0px 0px;

        &:first-child {
          display: none;
        }

        ${hasPrefixCell
          ? `
            & > *:first-child {
              display: none;
            }
          `
          : ''}
      }
    `,
    footerRow: css`
      display: flex;
      padding: 12px 4px 0px 4px;
    `,
    cell: css`
      align-items: center;
      margin-left: -8px;
      padding:  13px 4px 0px 4px;

      ${theme.breakpoints.down('sm')} {
        padding:  13px 0px 0px 0px;
        grid-template-columns: 1fr;
      }
    `,
    bodyCell: css`
      overflow: hidden;

      ${theme.breakpoints.down('sm')} {
        grid-column-end: right;
        grid-column-start: right;

        &::before {
          content: attr(data-column);
          display: block;
          color: ${theme.colors.text.primary};
        }
      }
    `,
    expandCell: css`
      justify-content: center;

      ${theme.breakpoints.down('sm')} {
        align-items: start;
        grid-area: left;
      }
    `,
    expandedContentRow: css`
      grid-column-end: ${sizes.length + 1};
      grid-column-start: ${hasPrefixCell ? 3 : 2};
      grid-row: 2;
      padding: 0 ${theme.spacing(3)} 0 ${theme.spacing(1)};
      position: relative;

      ${theme.breakpoints.down('sm')} {
        grid-column-start: 2;
        border-top: 1px solid ${theme.colors.border.strong};
        grid-row: auto;
        padding: ${theme.spacing(1)} 0 0 0;
      }
    `,
    expandButton: css`
      margin-right: 0;
      display: block;
    `,
    expandIcon: css`
      margin-top: 5px;
      margin-right: 0;
      display: block;
    `,
  });
};
