import React from 'react';

import { PanelPlugin } from '@grafana/data';
import { config, DataSourcePicker } from '@grafana/runtime';
import { FolderPicker } from 'app/core/components/Select/FolderPicker';
import { PermissionLevelString } from 'app/types';
import { GroupBy } from './GroupByWithLoading';

import { AlertListPanel } from './AlertListPanel';
import { AlertListOptions, GroupMode, SortOrder } from './types';

export const plugin = new PanelPlugin<AlertListOptions>(AlertListPanel).setPanelOptions((builder) => {
  builder
    .addCustomEditor({
      path: 'datasource',
      name: 'Datasource',
      description: 'Filter alerts from selected datasource',
      id: 'datasource',
      defaultValue: null,
      editor: function RenderDatasourcePicker(props) {
        return (
          <DataSourcePicker
            {...props}
            type={['prometheus', 'loki', 'netmonitor']}
            noDefault
            current={props.value}
            onChange={(ds) => props.onChange(ds.name)}
            onClear={() => props.onChange('')}
          />
        );
      },
      category: ['Options'],
    })
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'show customizable title',
      description: 'Enable title inside plugin',
      category: ['Options'],
      defaultValue: true,
    })
    .addTextInput({
      name: 'Title',
      path: 'pluginTitle',
      defaultValue: 'Notificaciones - Reglas configuradas',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    })
    .addTextInput({
      name: 'No Alert massage',
      path: 'noAlertMessage',
      defaultValue: 'No existen reglas que coincidan con los filtros',
      category: ['Options'],
    })
    .addBooleanSwitch({
      path: 'editorCanAdmin',
      name: 'Editors can admin?',
      description: 'Enable editors to view some details about rules',
      defaultValue: false,
      category: ['Options'],
    })
    .addRadio({
      path: 'groupMode',
      name: 'Group mode',
      description: 'How alert instances should be grouped',
      defaultValue: GroupMode.Custom,
      settings: {
        options: [
          { value: GroupMode.Default, label: 'Default grouping' },
          { value: GroupMode.Custom, label: 'Custom grouping' },
        ],
      },
      category: ['Options'],
    })
    .addCustomEditor({
      path: 'groupBy',
      name: 'Group by',
      description: 'Filter alerts using label querying',
      id: 'groupBy',
      defaultValue: [],
      showIf: (options) => options.groupMode === GroupMode.Custom,
      category: ['Options'],
      editor: (props) => {
        return (
          <GroupBy
            id={props.id ?? 'groupBy'}
            defaultValue={props.value.map((value: string) => ({ label: value, value }))}
            onChange={props.onChange}
          />
        );
      },
    })
    .addNumberInput({
      name: 'Max items',
      path: 'maxItems',
      description: 'Maximum alerts to display',
      defaultValue: 20,
      category: ['Options'],
    })
    .addSelect({
      name: 'Sort order',
      path: 'sortOrder',
      description: 'Sort order of alerts and alert instances',
      settings: {
        options: [
          { label: 'Alphabetical (asc)', value: SortOrder.AlphaAsc },
          { label: 'Alphabetical (desc)', value: SortOrder.AlphaDesc },
          { label: 'Importance', value: SortOrder.Importance },
          { label: 'Time (asc)', value: SortOrder.TimeAsc },
          { label: 'Time (desc)', value: SortOrder.TimeDesc },
        ],
      },
      defaultValue: SortOrder.AlphaAsc,
      category: ['Options'],
    })
    .addBooleanSwitch({
      path: 'dashboardAlerts',
      name: 'Alerts from this dashboard',
      description: 'Show alerts from this dashboard',
      defaultValue: false,
      category: ['Options'],
    })

    .addTextInput({
      path: 'alertName',
      name: 'Alert name',
      description: 'Filter for alerts containing this text',
      defaultValue: '',
      category: ['Filter'],
    })
    .addTextInput({
      path: 'alertInstanceLabelFilter',
      name: 'Alert instance label',
      description: 'Filter alert instances using label querying, ex: {severity="critical", instance=~"cluster-us-.+"}',
      defaultValue: '',
      category: ['Filter'],
    })
    .addCustomEditor({
      path: 'folder',
      name: 'Folder',
      description: 'Filter for alerts in the selected folder',
      id: 'folder',
      defaultValue: null,
      editor: function RenderFolderPicker(props) {
        return (
          <FolderPicker
            enableReset={true}
            showRoot={false}
            allowEmpty={true}
            initialTitle={props.value?.title}
            initialFolderId={props.value?.id}
            permissionLevel={PermissionLevelString.View}
            onClear={() => props.onChange('')}
            {...props}
          />
        );
      },
      category: ['Filter'],
    })
});
