import { PanelPlugin } from '@grafana/data';
import { FieldSelectEditor } from './FieldSelectEditor';
import { PieChartOptions } from './types';
import { PieChartPanel } from './PieChartPanel';

export const plugin = new PanelPlugin<PieChartOptions>(PieChartPanel).setPanelOptions(builder => {
  return builder
    .addCustomEditor({
      path: 'categoryField',
      name: 'Category Field',
      category: ['Data Map Settings'],
      defaultValue: 'category',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      path: 'labelField',
      name: 'Label Field',
      category: ['Data Map Settings'],
      defaultValue: 'label',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      path: 'valueField',
      name: 'Value Field',
      category: ['Data Map Settings'],
      defaultValue: 'value',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      path: 'colorField',
      name: 'Color Field',
      category: ['Data Map Settings'],
      defaultValue: 'color',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'useMetricField',
      name: 'Use Threshold',
      description: 'Use a metric Threshold to highlight slices in PieChart',
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      path: 'metricField',
      name: 'Threshold Field',
      category: ['Data Map Settings'],
      showIf: config => config.useMetricField === true,
      category: ['Data Map Settings'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'metricThresholdField',
      path: 'metricThresholdField',
      name: 'Threshold Field',
      description: 'Threshold (expressed in % of average field) use to alarm a bar (ex: 20)',
      category: ['Data Map Settings'],
      showIf: (config) => config.useMetricField === true,
      defaultValue: 'threshold',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'useInfoField',
      name: 'Aditional info Scales',
      description: 'Allow to use aditional info in tooltip',
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      path: 'infoField',
      name: 'Aditional info Field',
      category: ['Data Map Settings'],
      showIf: config => config.useInfoField === true,
      defaultValue: 'info',
      editor: FieldSelectEditor,
    })

    .addTextInput({
      path: 'pieTitle',
      name: 'Pie Title',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addBooleanSwitch({
      path: 'showMetricOnTooltip',
      name: 'Show metric on Tooltip',
      category: ['General Settings'],
      showIf: (config) => config.useMetricField === true,
      defaultValue: false,
    })
    .addTextInput({
      path: 'metricTitle',
      name: 'Metric title',
      category: ['General Settings'],
      showIf: config => config.useMetricField === true,
      defaultValue: '',
    })
    .addTextInput({
      path: 'metricUnit',
      name: 'Metric Unit',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'resultSeparator',
      name: 'Result separator character',
      description: 'Character use to present the result next to the text',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'infoTitle',
      name: 'Aditional info title',
      category: ['General Settings'],
      showIf: config => config.useInfoField === true,
      defaultValue: '',
    })
    .addTextInput({
      path: 'clicTitle',
      name: 'Clic Title',
      category: ['General Settings'],
      defaultValue: 'Clic para más detalles ...',
    })
    .addNumberInput({
      path: 'innerRadius',
      name: 'Donut inner radius',
      description: 'Inner raius expressed as % of pie diameter (Ex: 40)',
      category: ['General Settings'],
      defaultValue: 60,
    })
    .addNumberInput({
      path: 'outerRadius',
      name: 'Donut inner radius',
      description: 'Outer raius expressed as % of pie diameter',
      category: ['General Settings'],
      defaultValue: 75,
    })

    .addNumberInput({
      path: 'segmentMargin',
      name: 'Segment separation',
      category: ['Axis'],
      defaultValue: 2,
    })

    .addBooleanSwitch({
      path: 'showTooltip',
      name: 'Show Tooltip on chart Chart',
      category: ['Chart Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'Show Title beside de Chart',
      category: ['Chart Settings'],
      defaultValue: false,
    })
    .addTextInput({
      path: 'chartSubTitle',
      name: 'Chart Subtitle',
      category: ['Chart Settings'],
      showIf: (config) => config.showTitle === true,
      defaultValue: '',
    })
    .addTextInput({
      path: 'chartIcon',
      name: 'Chart Icon',
      category: ['Chart Settings'],
      showIf: (config) => config.showTitle === true,
      defaultValue: '',
    })
    .addColorPicker({
      path: 'iconColor',
      name: 'Chart Icon',
      category: ['Chart Settings'],
      showIf: (config) => config.showTitle === true && config.chartIcon !== '',
      defaultValue: 'orange',
    })
    .addNumberInput({
      path: 'titleFontSize',
      name: 'Chart Title Font Size',
      category: ['Chart Settings'],
      showIf: (config) => config.showTitle === true,
      defaultValue: 16,
    })
    .addBooleanSwitch({
      path: 'isAnimationActive',
      name: 'Animation Active',
      category: ['Chart Settings'],
      defaultValue: false,
    })

    .addColorPicker({
      path: 'innerRadiusFontColor',
      name: 'Donut inner radius Font Color',
      category: ['Chart Colors'],
      defaultValue: '#0c36cf',
    })

    .addBooleanSwitch({
      path: 'showAuxToolbar',
      name: 'Show Aux Toolbar',
      category: ['Toolbar'],
      defaultValue: false,
	  showIf: (config) => config.showTitle === false,
    })
    .addTextInput({
      path: 'toolbarAuxButtonIcon1',
      name: '1st Aux Button Icon',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true,
    })
    .addTextInput({
      path: 'toolbarAuxButtonTitle1',
      name: '1st Aux Button Title',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true && config.toolbarAuxButtonIcon1 !== '',
    })
    .addTextInput({
      path: 'toolbarAuxButtonVariable1',
      name: '1st Aux Button variable',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true && config.toolbarAuxButtonIcon1 !== '',
    })
    .addTextInput({
      path: 'toolbarAuxButtonValue1',
      name: '1st Aux Button value',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true && config.toolbarAuxButtonVariable1 !== '',
    })
    .addTextInput({
      path: 'toolbarAuxButtonIcon2',
      name: '2nd Aux Button Icon',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true,
    })
    .addTextInput({
      path: 'toolbarAuxButtonTitle2',
      name: '1st Aux Button Title',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true && config.toolbarAuxButtonIcon2 !== '',
    })
    .addTextInput({
      path: 'toolbarAuxButtonVariable2',
      name: '2nd Aux Button variable',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true && config.toolbarAuxButtonIcon2 !== '',
    })
    .addTextInput({
      path: 'toolbarAuxButtonValue2',
      name: '2nd Aux Button value',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true && config.toolbarAuxButtonVariable2 !== '',
    })

    .addBooleanSwitch({
      path: 'displayTotals',
      name: 'Display Totals',
      category: ['Legend Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'displayName',
      name: 'Display Name or Total',
      category: ['Legend Settings'],
	  showIf: (config) => config.displayTotals === true,
      defaultValue: true,
    })
    .addNumberInput({
      path: 'fontSize',
      name: 'Name or Total Font Size',
      category: ['Legend Settings'],
	  showIf: (config) => config.displayTotals === true,
      defaultValue: 12,
    })
    .addBooleanSwitch({
      path: 'displaysliceLegends',
      name: 'Display slices legends',
      category: ['Legend Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showSliceText',
      name: 'Display slice indicator next to the slice with text',
	  showIf: (config) => config.displaysliceLegends === true,
      category: ['Legend Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'displayLegends',
      name: 'Display Legends',
      category: ['Legend Settings'],
      defaultValue: true,
    })
    .addRadio({
      path: 'legendPosition',
      defaultValue: 'auto',
      name: 'Legend Position',
      category: ['Legend Settings'],
      settings: {
        options: [
          {
            value: 'bottom',
            label: 'Bottom',
          },
          {
            value: 'right',
            label: 'Right',
          },
        ],
      },
	  showIf: (config) => config.displayLegends === true,
    })
    .addNumberInput({
      path: 'legendFontSize',
      name: 'Legend Font Size',
      category: ['Legend Settings'],
	  showIf: (config) => config.displayLegends === true || config.displaysliceLegends === true,
      defaultValue: 11,
    })

    .addTextInput({
      path: 'absoluteThreshold',
      name: 'Absolute Threshold',
      description: 'Absolute Threshold use to alarm a Bar (ex: 90)',
      category: ['Thresholds'],
      defaultValue: '90',
    })
    .addBooleanSwitch({
      path: 'invertThreshold',
      name: 'Invert Threshold',
      category: ['Thresholds'],
      defaultValue: false,
    })
    .addColorPicker({
      path: 'warningColor',
      name: 'Warning Color',
      description: 'Color use when value is above threshold',
      category: ['Thresholds'],
      defaultValue: '#FF0000',
    })

    .addTextInput({
      path: 'drillDownLink',
      name: 'PieChart slice base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addBooleanSwitch({
      path: 'useSegmentUrl',
      name: 'Use URL for each bar',
      category: ['Link to other features'],
      defaultValue: false,
    })
    .addTextInput({
      path: 'drillDownLinkCenter',
      name: 'PieChart center base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })

    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'Datos nulos',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});
