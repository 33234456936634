// @ts-ignore
import React, { useCallback, useState, useMemo } from 'react';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { Icon } from '@grafana/ui';
import { TreeChartOptions } from 'types';
import {
  Treemap, 
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import { config } from '@grafana/runtime';
import styled from 'styled-components';
import './css/TreeChartPanel.css';

interface Props extends PanelProps<TreeChartOptions> {}

export const TreeChartPanel: React.FC<Props> = React.memo(({ options, data, width, height, replaceVariables, id }) => {
  const isDark = config.theme.isDark;
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const absoluteThreshold = Number(replaceVariables(options.absoluteThreshold));
  const threshold = Number(replaceVariables(options.metricThreshold)) / 100;

  const auxButtonIcon1 = replaceVariables(options.toolbarAuxButtonIcon1);
  const auxButtonVariable1 = replaceVariables(options.toolbarAuxButtonVariable1);
  const auxButtonValue1 = replaceVariables(options.toolbarAuxButtonValue1);
  const auxButtonIcon2 = replaceVariables(options.toolbarAuxButtonIcon2);
  const auxButtonVariable2 = replaceVariables(options.toolbarAuxButtonVariable2);
  const auxButtonValue2 = replaceVariables(options.toolbarAuxButtonValue2);
  const axisLineWidth = options.axisLineWidth;
  const chartTitle = options.showTitle ? replaceVariables(options.radarTitle) : '';
  const chartSubTitle = options.showTitle ? replaceVariables(options.chartSubTitle) : '';
  const chartIcon = options.showTitle ? replaceVariables(options.chartIcon) : '';
  const chartIconSize = options.titleFontSize * 2 > 64 ? 64 : options.titleFontSize * 2;

  const slice_link = replaceVariables(options.drillDownLink);
  const center_link = replaceVariables(options.drillDownLinkCenter);

  const memoizedOptions = useMemo(() => ({
	slice_link: replaceVariables(options.drillDownLink),
	center_link: replaceVariables(options.drillDownLinkCenter),
	auxButtonIcon1: replaceVariables(options.toolbarAuxButtonIcon1),
	auxButtonVariable1: replaceVariables(options.toolbarAuxButtonVariable1),
	auxButtonValue1: replaceVariables(options.toolbarAuxButtonValue1),
	auxButtonIcon2: replaceVariables(options.toolbarAuxButtonIcon2),
	auxButtonVariable2: replaceVariables(options.toolbarAuxButtonVariable2),
	auxButtonValue2: replaceVariables(options.toolbarAuxButtonValue2),
	chartTitle: options.showTitle ? replaceVariables(options.barTitle) : '',
	chartSubTitle: options.showTitle ? replaceVariables(options.chartSubTitle) : '',
	chartIcon: options.showTitle ? replaceVariables(options.chartIcon) : '',
  }), [options, replaceVariables]);

  if (height < 150 || width < 150) {
    return (
	  <div className="treeChartErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="treeChartErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.series[0].length < 1) {
    return (
	  <div className="treeChartErrorContainer" title={error2}>
	    <Icon name={'image-slash'} size="xxl" />
	  </div>
	);
  }

  var legendPosition = options.displayLegends && options.legendPosition === 'right' ? 'right' : 'bottom';
  var legendVisible = options.displayLegends;
  var labelsVisible = (width < 325 || height < 215) ? false : true;
  if (width > 325 && height < 215) {
    legendPosition = 'right';
  } else if (width < 325 && height > 215) {
    legendPosition = 'bottom';
  }

  if (
    (legendPosition === 'right' && width < 325) ||
	(legendPosition === 'bottom' && height < 215) ||
	(width < 325 || height < 215)
  ) {
    legendVisible = false;
  }

  var toolBoxWidth = 0;
  if (options.showAuxToolbar && !options.showTitle) {
	toolBoxWidth = 32;
  } else if (options.showTitle && chartTitle !== '' && width >= 250) {
	toolBoxWidth = (width * 0.5 < 125) ? 125 : width * 0.5;
	legendVisible = false;
	labelsVisible = false;
  }
  if (options.showTitle && chartTitle !== '' && toolBoxWidth > 300) {
    toolBoxWidth = 300;
  }

  const tickFontColor = isDark ? '#D8DFD9' : '#23282E';
  const textColor = isDark ? '#E6E9ED' : '#23282E';
  const iconColor = isDark ? 'white' : 'black';
  const buttonColor = isDark ? 'black' : 'white';
  const buttonBorder = isDark ? '#23282E' : '#E6E9ED';
  const boxBorder = isDark ? '#1B2733' : '#EFF4FA';
  const borderColor = isDark ? '#44444C' : '#D8DFE9';
  const innerBoxShadow = isDark ? '#9DA5B8' : '#9DA5B8';

  const TreeChartBox = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #333333;
    width: fit-content;
	max-width: 300px;
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 4px 2px 4px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
    z-index: 9999;
  `;
  const TreeChartWarn = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    width: fit-content;
	max-width: 300px;
    padding: 5px;
    background-color: #e45858;
    border: 1px solid red;
    border-radius: 4px;
    box-shadow: 4px 2px 4px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
    z-index: 9999;
  `;
  const TreeChartTooltipCenter = styled.p`
    text-align: center;
  `;

  const TreeChartTooltipText = styled.p`
    margin-top: 0px;
    margin-bottom: 0px;
  `;

  const TreeChartTooltipTitle = styled.b`
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
  `;

  const TreeChartToolboxContainer = styled.div`
    display: inline-flex;
    width: 100%;
  `;

  const ButtonContainer = styled.div`
    position: absolute;
    top: 5px;
    width: 32px;
	border-left: 1px solid ${boxBorder};
  `;

  const ChartContainer = styled.div`
    display: inline-flex;
	position: absolute;
	width: -webkit-fill-available;
    height: -webkit-fill-available;
  `;
	
  const ButtonNormal = styled.button`
    display: inline-flex;
    height: 32px;
    width: 28px;
	margin-left: 2px;
    background-color: transparent;
    opacity: 0.75;
    justify-content: center;
	align-items: center;
    color: ${iconColor};
    border: 1px solid transparent;
    border-radius: 10px;

    &:hover {
      opacity: 1;
	  background-image: linear-gradient(to bottom right, #e5e5e529, #b7b7b76e);
	  border: 1px solid ${buttonBorder};
    }
  `;

  const ButtonText = styled.div`
    width: 28px;
  `;

  var clicEnable = false;
  if (slice_link !== null && slice_link.length > 0 && options.useSegmentUrl) {
    clicEnable = true;
  }
  if (center_link !== null && center_link.length > 0) {
    clicEnable = true;
  }

  var chartTrees: [] = [];
  data.series.forEach((series) => {
    const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
    for (let i = 0; i < timeVals.length; i++) {
	  var chartTree = {
		id: i,
		name: series.fields.find((field) => field.name === options.categoryField)?.values.get(i),
		value: 0,
		group: 0,
		label: series.fields.find((field) => field.name === options.labelField)?.values.get(i) || '',
		info: '',
		metric: 0,
		threshold: 0,
		onfire: false,
	  };
	  if (options.valueField !== undefined && options.valueField !== '') {
		const value = series.fields.find((field) => field.name === options.valueField)?.values.get(i);
		chartTree.value = isNaN(value) ? 0 : Number(value);
	  }
	  const segmentLabel = series.fields.find((field) => field.name === options.labelField)?.values.get(i)
	  if (segmentLabel !== undefined && segmentLabel !== '') {
	    chartTree.label = segmentLabel;
	  }
	  if (options.useMetricField) {
		if (options.metricField !== undefined && options.metricField !== '') {
		  const metric = series.fields.find((field) => field.name === options.metricField)?.values.get(i);
		  chartTree.metric = isNaN(metric) ? 0 : Number(metric);
		}
		if (options.metricThresholdField !== undefined && options.metricThresholdField !== '') {
		  const relativeThreshold = series.fields.find((field) => field.name === options.metricThresholdField)?.values.get(i);
		  chartTree.threshold = isNaN(relativeThreshold) ? 1 : Number(relativeThreshold) / 100;
		}
	  }
	  if (chartTree.metric > 0 && chartTree.threshold > 0) {
		if (chartTree.value > chartTree.metric * (1 + chartTree.threshold)) {
		  chartTree.onfire = true;
		}
	  }
	  if (absoluteThreshold > 0 && chartTree.value > absoluteThreshold) {
	    chartTree.onfire = true;
	  }
	  if (options.invertThreshold) {
		chartTree.onfire = !chartTree.onfire;
	  }
	  if (options.useInfoField && options.infoField !== undefined && options.infoField !== '') {
		chartTree.info = series.fields.find((field) => field.name === options.infoField)?.values.get(i);
	  }
	  chartTrees.push(chartTree);
	}
  });

  var dataWithChildrens: [] = [];
  var childrens: [] = [];
  var warnIndex = null;
  var idxStack = 1;
  chartTrees.forEach(chartTree => {
	if (chartTree.onfire && warnIndex === null) {
	  warnIndex = chartTree.id;
	}
	const existingStackId = childrens !== null ? childrens.find(item => item.label === chartTree.label) : null;
	if (!existingStackId) {
	  const newChildren = {
	  	id: idxStack,
		label: chartTree.label,
	  };
	  idxStack = idxStack + 1;
	  childrens.push(newChildren); 
	}
  });
  childrens.forEach(children => {
	const childrensComponents = chartTrees.filter(item => {
	  return item.label === children.label;
	})
	childrensComponents.sort((a, b) => {
	  return b.value - a.value;
    });
	if (childrensComponents) {
	  childrensComponents.forEach(childrensComponent => {
	    childrensComponent.group = children.id;
	  });
	  const newChildrenSection = {
	  	name: children.label,
		group: children.id,
		children: childrensComponents,
	  };
	  dataWithChildrens.push(newChildrenSection); 
	}
  });  

  const DataFormater = (value: any) => {
	if (isNaN(value)) {
	  return String(value) + ' ';
	} else if (value >= 1000000000) {
	  return String(Math.round(value / 1000000000)) + ' G';
	} else if (value >= 1000000) {
	  return String(Math.round(value / 1000000)) + ' M';
	} else if (value >= 1000) {
	  return String(Math.round(value / 1000)) + ' K';
	} else {
	  return String(Math.round(value)) + ' ';
	}
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
	if (active && payload && payload.length > 0) {
	  if (payload[0].payload.onfire) {
		return (
		  <TreeChartWarn>
			<span>
			  <TreeChartTooltipCenter>
				<i className="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>
				  <TreeChartTooltipTitle>{'Alarma'}</TreeChartTooltipTitle> :
			  </TreeChartTooltipCenter>
			</span>
			{options.showMetricOnTooltip && (
			  <span>
			    <TreeChartTooltipText>
				  <b>
				    {payload[0].payload.name + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.value) + options.metricUnit}
				  </b>
			    </TreeChartTooltipText>
			    <TreeChartTooltipText>
				  <b>
				    {options.metricTitle + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.metric) + options.metricUnit}
				  </b>
			    </TreeChartTooltipText>
			  </span>
			)}
			{!options.showMetricOnTooltip && (
			  <span>
			    <TreeChartTooltipText>
				  <b>
				    {payload[0].payload.name + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.value) + options.metricUnit}
				  </b>
			    </TreeChartTooltipText>
			  </span>
			)}
			{options.useInfoField && (
			  <span>
				{options.infoTitle + options.resultSeparator + ' '}
				<b>{payload[0].payload.info}</b>
			  </span>
			)}
			{clicEnable && (
			  <p>
				<TreeChartTooltipText>{options.clicTitle}</TreeChartTooltipText>
			  </p>
			)}
		  </TreeChartWarn>
		);
	  } else {
		return (
		  <TreeChartBox>
			{options.showMetricOnTooltip && (
			  <span>
			    <TreeChartTooltipText>
				  <b>
				    {payload[0].payload.name + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.value) + options.metricUnit}
				  </b>
			    </TreeChartTooltipText>
			    <TreeChartTooltipText>
				  <b>
				    {options.metricTitle + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.metric) + options.metricUnit}
				  </b>
			    </TreeChartTooltipText>
			  </span>
			)}
			{!options.showMetricOnTooltip && (
			  <span>
			    <TreeChartTooltipText>
				  <b>
				    {payload[0].payload.name + options.resultSeparator + ' '}
				    {DataFormater(payload[0].payload.value) + options.metricUnit}
				  </b>
			    </TreeChartTooltipText>
			  </span>
			)}
			{options.useInfoField && (
			  <span>
				{options.infoTitle + options.resultSeparator + ' '}
				<b>{payload[0].payload.info}</b>
			  </span>
			)}
			{clicEnable && (
			  <p>
				<TreeChartTooltipText>{options.clicTitle}</TreeChartTooltipText>
			  </p>
			)}
		  </TreeChartBox>
		);
	  }
	} else {
	  return null;
	}
  };

  var chartMargin = { top: 5, right: 5, left: 5, bottom: 5 };

  const auxButton1Enable = (auxButtonIcon1 !== '' && auxButtonVariable1 !== '' && auxButtonValue1 !== '') ? true : false;
  const auxButton2Enable = (auxButtonIcon2 !== '' && auxButtonVariable2 !== '' && auxButtonValue2 !== '') ? true : false;

  const [activeIndex, setActiveIndex] = useState(warnIndex);
  const onRadarEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const renderColorfulLegendText = (value: string, entry: any) => {
	return <span style={{ color: textColor, cursor: 'pointer' }}>{value}</span>;
  }

  const CustomizedContent = (props: any) => {
    const { root, depth, x, y, width, height, index, name, value } = props;
	const rectAspectRatio = width < 150 ? height / width : 0;
	let textContent = '';

	if (name) {
	  textContent = name;
	  if (depth > 1 && name.length > 15) {
		textContent = name.substring(0, 15) + '...';
	  }
	}

    if (depth === 1) {
	  return (
        <g>
          <rect
            x={x}
            y={y}
			rx={2}
            width={width}
            height={height}
            style={{
              fill: getColor(Math.floor((index / 5) * 5), isDark, true),
			  fillOpacity: 1,
              stroke: buttonBorder,
              strokeWidth: 2,
              strokeOpacity: 1,
			  filter: `drop-shadow(0px 0px 15px ${innerBoxShadow})`,
            }}
          />
          <text 
		    x={x + width / 2} 
			y={y + height / 2 + 7} 
			textAnchor={'middle'} 
			fill={options.groupFontColor}
			fontSize={options.titleFontSize}
		  >
            {name}
          </text>
        </g>
      );
	} else {
      const fontSize = index > 3 ? options.fontSize - index : options.fontSize
	  return (
	    <g>
          <rect
            x={x + 2}
            y={y + 2}
			rx={2}
            width={width - 2}
            height={height - 2}
            style={{
              fill: getColor(Math.floor((index / 10) * 10), isDark, false),
			  fillOpacity: options.treeOpacity,
			  strokeWidth: 0,
			  filter: `drop-shadow(0px 0px 15px ${innerBoxShadow})`,
            }}
          />
          <text 
		    x={x + width / 2} 
			y={y + height / 2 + 7} 
			textAnchor={'middle'}
			fill={tickFontColor} 
			fontSize={fontSize}
			transform={rectAspectRatio > 1 ? `rotate(-90, ${x + width / 2}, ${y + height / 2 + 7})` : ''}
		  >
            {index > 8 || (width < 50 && height < 50) ? null : textContent}
          </text>
        </g>
      );
	}
  }


  let treeHeight = height;
  let treeWidth = width - toolBoxWidth; 
  if (!options.showTitle && legendVisible && !labelsVisible) {
	treeWidth = Math.min(height, width);
	treeHeight = treeWidth;
  }

  return (
	<TreeChartToolboxContainer id={id}>
	  {options.showAuxToolbar && (auxButton1Enable || auxButton2Enable) && !options.showTitle && (
		<ButtonContainer>
		  {auxButton1Enable && (
			<ButtonNormal
			  title={options.toolbarAuxButtonTitle1}
			   onClick={() => {
				handleAuxClick(auxButtonVariable1, auxButtonValue1);
			  }}
			>
			  <ButtonText><Icon name={auxButtonIcon1} size="lg" /></ButtonText>
			</ButtonNormal>
		  )}
		  {auxButton2Enable && (
			<ButtonNormal
			  title={options.toolbarAuxButtonTitle2}
			  onClick={() => {
				handleAuxClick(auxButtonVariable2, auxButtonValue2);
			  }}
			>
			  <ButtonText><Icon name={auxButtonIcon2} size="lg" /></ButtonText>
			</ButtonNormal>
		  )}
		</ButtonContainer>
	  )}
	  {options.showTitle && chartTitle !== '' && width >= 250 && (
		<button
          style={{
            fontSize: options.titleFontSize,
			width: toolBoxWidth,
			height: height - 10,
          }}
          className={'treeChart_button'}
		  onClick={() => {
			handleClick(url_link, '_self');
		  }}
          title={chartTitle}
        >
		  <div 
		    className={'treeChartIndicator'}
			style={ width > 480 ? 
			  {
			    width: toolBoxWidth,
			    height: height - 10,
			  } : {
			    height: height - 10,
			  }
			}
		  >
			{chartIcon !== '' && (
			  <div 
				className={'icon_treeChart'}
			    style={{
				  color: options.iconColor,
				  width: chartIconSize + 20,
			    }}
			  >
				<Icon name={chartIcon} size={chartIconSize + 10} />
			  </div>
			)}
			<div className={'treeChart_label'}>
			  <b>{chartTitle}</b>
			  <div
			    className={'treeChart_sublabel'}
			    style={{
				  fontColor: iconColor,
				  fontSize: options.fontSize,
			    }}
			  >
			    {chartSubTitle}
			</div>
		  </div>
		</div>
      </button>
	  )}
	  <ChartContainer style={{ left: toolBoxWidth, bottom: 0 }}>
		<ResponsiveContainer width={width - toolBoxWidth + 15} height={height}>
		  <Treemap
			width={treeWidth}
			height={treeHeight}
			margin={chartMargin}
		    data={dataWithChildrens}
			aspectRatio={4 / 3}
            dataKey={'value'}
            stroke={buttonBorder}
            fill={buttonColor}
			animationEasing={'ease-in-out'}
			content={<CustomizedContent />}
		  >
			{options.showTooltip && (
			  <Tooltip 
			    content={CustomTooltip}
				cursor={false}
				isAnimationActive={false}
			  />
			)}
		  </Treemap>
		</ResponsiveContainer>
	  </ChartContainer>
	</TreeChartToolboxContainer>
  );
});

function handleClick(url: string, target: string) {
  if (url !== null && url !== '') {
    window.open(url, target);
  }
}

function getColor(color: number, isDark: boolean, background: boolean) {
  const colors_dark = [
    "#F17171",
    "#71E200",
    "#A2ADB8",
    "#7100FF",
    "#FFA071",
	"#A20471",
    "#FF71B7", 
    "#7EA9ff",
    "#FF0071",
    "#B380FF"
  ];
  const colors_light = [
    "#D64545",
    "#45C5B0",
    "#7D8995",
    "#45B0E5",
    "#E57A45",
	"#7DB545",
    "#E54594", 
    "#5485E5",
    "#E58045",
    "#9065E5"
  ];
  const background_dark = [
    "white",
	"blue",
	"green",
	"yellow",
	"Magenta"
  ];
  const background_light = [
    "black",
	"blue",
	"green",
	"yellow",
	"Magenta"
  ];
  let colorNumber = color < 0 ? 0 : color;
  if (background) {
    if (colorNumber > background_dark.length) {
      colorNumber = ((color - 1) % background_dark.length);
    }
    if (isDark) {
      return background_dark[colorNumber];
    } else {
	  return background_light[colorNumber];
	}
  } else {
    if (colorNumber > colors_dark.length) {
      colorNumber = ((color - 1) % colors_dark.length);
    }
    if (isDark) {
      return colors_dark[colorNumber];
    } else {
	  return colors_light[colorNumber];
	}
  }
}