import { Alert } from 'app/types/unified-alerting';

export enum SortOrder {
  AlphaAsc = 1,
  AlphaDesc,
  Importance,
  TimeAsc,
  TimeDesc,
}

export enum ShowOption {
  Current = 'current',
  RecentChanges = 'changes',
}

export enum GroupMode {
  Default = 'default',
  Custom = 'custom',
}

export type GroupedRules = Map<string, Alert[]>;

export interface AlertListOptions {
  editorCanAdmin: boolean;
  showTitle: boolean;
  pluginTitle: string;
  noAlertMessage: string;
  showOptions: ShowOption;
  maxItems: number;
  sortOrder: SortOrder;
  dashboardAlerts: boolean;
  alertName: string;
  dashboardTitle: string;
  tags: string[];
  folderId: number;
  cardTitleField: string;
  cardTitleUrlField: string;
  titleField: string;
  cardSubtitleField: string;
  agentField: string;
  agentUrlField: string;
  urlField: string;
  cardDetailedTextField: string;
  iconField: string;
  useIcon: boolean;
  usecardSubtitle: boolean;
  useUrl: boolean;
  useInfoField: boolean;
  displayOutline: boolean;
  displayName: boolean;
  usecardDetailedText: boolean;
  hideControls: boolean;
  useReadMore: boolean;
  showCardInfo: boolean;
  flipLayout: boolean;
  legendPosition: legendPositions;
  cardPositionHorizontal: cardPositionHorizontal;
  showAllCards: boolean;
  drillDownLink: string;
  drillDownLinkAgent: string;
  drillDownLinkTitle: string;
  infoTitle: string;
  subtitleTitle: string;
  agentTitle: string;
  typeTitle: string;
  severityTitle: string;
  cardFontSize: number;
  circleDimension: number;
  cardMinHeight: number;
  error1: string;
  error2: string;
  error3: string;
  error4: string;
}

export type alertlistItems = {
  id: number;
  title: string;
};

export type alertlistData = {
  id: number;
  activeAt: string;
  cardTitle: string;
  title: string;
  cardSubtitle: string;
  cardDetailedText: string;
  ruleName: string;
  category: string;
  operationGroup: string;
  manteinanceGroup: string;
  type: string;
  severity: string;
};