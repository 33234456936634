import { css } from '@emotion/css';
import React, { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { NetMonitorTheme2 } from '@grafana/data';
import { ConfirmModal, Modal, useStyles2, Icon, HorizontalGroup, IconButton  } from '@grafana/ui';
import { locationService, getTemplateSrv } from '@grafana/runtime';
import { AlertManagerCortexConfig, Receiver } from 'app/plugins/datasource/alertmanager/types';

import { Authorize } from 'app/features/alerting/unified/components/Authorize';
import { useUnifiedAlertingSelector } from 'app/features/alerting/unified/hooks/useUnifiedAlertingSelector';
import { deleteReceiverAction } from 'app/features/alerting/unified/state/actions';
import { getAlertTableStyles } from 'app/features/alerting/unified/styles/table';
import { getNotificationsPermissions } from 'app/features/alerting/unified/utils/access-control';
import { prepareItems } from 'app/features/alerting/unified/utils/dynamicTable';
import { isReceiverUsed } from 'app/features/alerting/unified/utils/alertmanager';
import { isVanillaPrometheusAlertManagerDataSource, NETMONITOR_RULES_SOURCE_NAME } from 'app/features/alerting/unified/utils/datasource';
import { makeAMLink } from 'app/features/alerting/unified/utils/misc';
import { extractNotifierTypeCounts } from 'app/features/alerting/unified/utils/receivers';
import { ProvisioningBadge } from 'app/features/alerting/unified/components/Provisioning';
import { ActionIconBig } from 'app/features/alerting/unified/components/rules/ActionIcon';
import { DynamicTable, ContactTableColumnProps, ContactTableItemProps } from '../DynamicTable';

import { NetMonitorReceiverForm } from './NetMonitorReceiverForm';
import { Text } from '../Tags';
import { getNetMonitorVariableValue, setNetMonitorVariableValue } from '../utils';
import { FormAmContact } from '../types';

interface ReceiversTableProps {
  id: string;
  isAddMode: boolean;
  isAdmin: boolean;
  readOnly?: boolean;
  onCancelAdd: () => void;
  rows: [];
  configuration: AlertManagerCortexConfig;
  filters?: { contactPoint?: string; contactType?: string };
  alertManagerSourceName: string;
  isDark: boolean;
  width: number;
}

type ContactTableColumnProps = ContactTableColumnProps<FormAmContact>;
type ContactTableItemProps = ContactTableItemProps<FormAmContact>;

export const getFilteredRows = (rows: [], contactPointQuery?: string, contactTypeQuery?: string) => {
  let filteredRows = rows;

  if (contactTypeQuery && contactTypeQuery.length > 0) {
    filteredRows = rows.filter((row) => {
	  for (let matcher of row.types) {
        if (matcher.toLowerCase() === contactTypeQuery.toLowerCase()) {
          return true;
        }
      }
	  return false;
	});
  }

  if (contactPointQuery && contactPointQuery.length > 0) {
    filteredRows = filteredRows.filter((row) =>
      row.name.toLowerCase().includes(contactPointQuery.toLowerCase())
    );
  }
  return filteredRows;
};

export const ReceiversTable: FC<ReceiversTableProps> = ({
  id,
  isAddMode,
  isAdmin,
  readOnly,
  onCancelAdd,
  rows,
  configuration,
  filters,
  alertManagerSourceName,
  isDark,
  width
}) => {
  const dispatch = useDispatch();
  const styles = useStyles2(getStyles);
  const tableStyles = useStyles2(getAlertTableStyles);

  const permissions = getNotificationsPermissions(alertManagerSourceName);
  const netmonitorNotifiers = useUnifiedAlertingSelector((state) => state.netmonitorNotifiers);
  const [expandedId, setExpandedId] = useState<string | number>();
  const expandItem = useCallback((item: ContactTableItemProps) => setExpandedId(item.id), []);
  const collapseItem = useCallback(() => setExpandedId(undefined), []);

  const [showNewChannel, setShowNewChannel] = useState<boolean>(false);
  const [receiverToDelete, setReceiverToDelete] = useState<string>();
  const [receiverToEdit, setReceiverToEdit] = useState<Receiver>();
  const [showCannotDeleteReceiverModal, setShowCannotDeleteReceiverModal] = useState<boolean>(false);
  const [showCannotDelete, setShowCannotDelete] = useState<boolean>(false);
  const [showEditChannel, setShowEditChannel] = useState<boolean>(false);

  const contactRows: [] = [];
  rows.forEach((row, index) => {
	const contactRow = {
	  name: row.name,
	  types: row.types.join(', '),
	  addresses: row.address.join(', '),
	  provisioned: row.provisioned,
	  addressByType: [],
	};
	if (row.types.length > 0) {
	  row.types.forEach((type, index) => {
	    if (row.address.length > index) {
		  if (type === 'Correo electrónico') {
		    contactRow.addressByType.push('email [' + row.address.join(', ') + ']');
		  } else {
		    contactRow.addressByType.push(type + ' [' + row.address.join(', ') + ']');
		  }
		}
	  });
	}
	contactRows.push(contactRow);
  });
   
  const onClickDeleteReceiver = (receiverName: string): void => {
    if (isReceiverUsed(receiverName, configuration)) {
      setShowCannotDeleteReceiverModal(true);
    } else {
      setReceiverToDelete(receiverName);
    }
  };

  const deleteReceiver = () => {
    if (receiverToDelete) {
      dispatch(deleteReceiverAction(receiverToDelete, alertManagerSourceName));
    } else {
      setShowCannotDelete(true);
    }
    setReceiverToDelete(undefined);
  };
  
  const enableEditChannel = (visible: boolean, idx: number): void => {
    if (visible) {
      setReceiverToEdit(configuration.alertmanager_config.receivers[idx]); 
      setShowEditChannel(true);
    } else {
      setReceiverToEdit(undefined);
      setShowEditChannel(false);
    }
  };

  const cols: ContactTableColumnProps[] = [
    {
      id: 'name',
      label: 'Nombre del Canal',
      renderCell: (item) => {
        return item.data.name? (
		  <Text text={item.data.name} />
		) : (
		  <Text text={'-'} />
		);
      },
      size: 8,
    },
    {
      id: 'types',
      label: 'Tipos de Medio',
      renderCell: (item) => {
        return item.data.types ? (
		  <Text text={item.data.types} />
		) : (
		  <Text text={'-'} />
		);
      },
      size: 8,
    },
    {
      id: 'detail',
      label: 'Detalle',
      renderCell: (item) => {
        return item.data.addressByType ? (
		  <Text text={item.data.addressByType} />
		) : (
		  <Text text={'-'} />
		);
      },
      size: 12,
    },
    ...(readOnly
      ? []
      : [
          {
            id: 'actions',
            label: '',
            // eslint-disable-next-line react/display-name
            renderCell: (item) => {
			  if (item.renderExpandedContent) {
                return null;
              }
              const expandWithCustomContent = () => {
				expandItem(item);
                setEditMode(true);
              };
              if (isAdmin) {
                return (
                  <>
                    <HorizontalGroup>
                      <IconButton
                        aria-label="Delete route"
                        name="trash-circle-fill"
                        size="xl"
                        tooltip="Borrar regla de notificación"
                        onClick={() => {
                          onClickDeleteReceiver(item.data.name)
                        }}
                        type="button"
                        variant="secondary"
                      />
                    </HorizontalGroup>
                  </>
                );
              } else {
                return null;
              }
            },
            size: '64px',
          } as RouteTableColumnProps,
        ]),
  ];

  const filteredRows = useMemo(
    () => getFilteredRows(contactRows, filters?.contactPoint, filters?.contactType),
    [contactRows, filters]
  );
  const dynamicTableRows = useMemo(
    () => prepareItems(isAddMode ? contactRows : filteredRows),
    [isAddMode, contactRows, filteredRows]
  );

  // expand the last item when adding or reset when the length changed
  useEffect(() => {
    if (isAddMode && dynamicTableRows.length) {
      setExpandedId(dynamicTableRows[dynamicTableRows.length - 1].id);
    }
    if (!isAddMode && dynamicTableRows.length) {
      setExpandedId(undefined);
    }
  }, [isAddMode, dynamicTableRows]);

  return (
    <>
      <DynamicTable
        cols={cols}
        isExpandable={true}
        items={dynamicTableRows}
		alertManagerSourceName={alertManagerSourceName}
		configuration={configuration}
		isDark={isDark}
		isAdmin={isAdmin}
		isAddMode={isAddMode}
		onCancelAdd={onCancelAdd}
		width={width}
      />
      {!!showCannotDeleteReceiverModal && (
        <Modal
          isOpen={true}
          title="Imposible eliminar canal"
          onDismiss={() => setShowCannotDeleteReceiverModal(false)}
        >
          <p>
            Ud no puede eliminar este canal debido a que el mismo esta siendo utilizado por al menos una politica.
          </p>
          <Modal.ButtonRow>
			<button 
			  type="button" 
			  className={styles.cancelButton} 
			  onClick={() => setShowCannotDeleteReceiverModal(false)}
			  title={'Cerrar'}
			>
			  <div className={styles.button_icon}>
				<Icon name="times-fill" size="lg" />
			  </div>
			  Cerrar
			</button>
          </Modal.ButtonRow>
        </Modal>
      )}
      {!!showCannotDelete && (
        <Modal
          isOpen={true}
          title="Imposible eliminar canal"
          onDismiss={() => setShowCannotDeleteReceiverModal(false)}
        >
          <p>
            No fue posible eliminar el canal. Por favor vuelva a intentarlo recagando esta pagina.
          </p>
          <Modal.ButtonRow>
			<button 
			  type="button" 
			  className={styles.cancelButton} 
			  onClick={() => setShowCannotDelete(false)}
			  title={'Cerrar'}
			>
			  <div className={styles.button_icon}>
				<Icon name="times-fill" size="lg" />
			  </div>
			  Cerrar
			</button>
          </Modal.ButtonRow>
        </Modal>
      )}
      {!!receiverToDelete && (
        <ConfirmModal
          isOpen={true}
          title="Eliminar canal de comunicación"
          body={`Esta seguro que desea eliminar el canal "${receiverToDelete}"?`}
          confirmText="Sí"
          dismissText="Cancelar"
          onConfirm={deleteReceiver}
          onDismiss={() => setReceiverToDelete(undefined)}
        />
      )}
    </>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const borderColor = isDark ? '#23282E' : '#D8DFE9';
  const background = isDark ? '#141618' : '#F4F9FF'
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  const fontColorWeak = isDark ? '#646A73' : '#9DA5B8';
  const cancelButtonBack = isDark ? '#F74545' : '#FB3333';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    cancelButton: css`
      background-color: ${cancelButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};
